<div id="patientsidposition">
</div>
<br><br><br><br>
<div class="container-fluid p-sm-5 bg-light">
  <div class="row">
    <div class="col-lg-5 col-md-6 col-sm-12">
      <h4>Liste des patients</h4>
    </div>
  </div>

  <div class="col-md-12">
    <div class="row justify-content-end">
      <div class="col-auto">
        <button [routerLink]="['/medecin/nouveauPatient']" title="Ajouter un patient" class="btn btn-primary">Ajouter un
          patient</button>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary" *ngIf="medecin" (click)="generateEXCELpatient()">Exporter</button>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-12 mb-3 mb-md-0">
      <div class="form-group">
        <div class="col row ">
          <div class="col">
            <input type="text" class="col form-control mr-2" placeholder="N° Fiche" [(ngModel)]="numFicheToFiltred" (input)="filter()" onkeypress="return /[a-zA-Z0-9]/.test(event.key)">
          </div>
          <div class="col">
            <input type="text" class="col form-control mr-2" placeholder="Nom" [(ngModel)]="nomToFiltred" (input)="filter()" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
          </div>

          <div class="col">
            <input type="text" class="col form-control mr-2" placeholder="Prénom" [(ngModel)]="prenomToFiltred" (input)="filter()" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
          </div>
          <div class="col">
            <input type="text" class="col form-control mr-2" placeholder="Profession" [(ngModel)]="professionToFiltred" (input)="filter()" onkeypress="return /[a-zA-ZàâäéèêëïîôöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
          </div>
          <div class="col">
            <input type="text" class="col form-control mr-2" placeholder="Âge" [(ngModel)]="ageToFiltred" (input)="filter()" onkeypress="return /[0-9]/.test(event.key)">
          </div>
          <div class="col">
            <button (click)="resetFilters()" class="btn btn-primary p-2 mr-1">
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <style>
                  svg {
                    fill: #ffffff
                  }
                </style>
                <path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="row mt-4">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr class="ligne-paire text-center">
              <th scope="col" style="color:#589590;"></th>
              <th scope="col" style="color:#589590;" *ngIf="medecin">Consultat°</th>
              <th scope="col" style="color:#589590;">N° de fiche</th>
              <th scope="col" style="color:#589590;">Nom</th>
              <th scope="col" style="color:#589590;">Prénom</th>
              <th scope="col" style="color:#589590;">Profession</th>
              <th scope="col" style="color:#589590;">Âge</th>
              <th scope="col" style="color:#589590;">Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let i of patients | paginate
                        : {
                            itemsPerPage: tableSize,
                            currentPage: page,
                            totalItems: count
                        }; let k = index">
              <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" class="text-center" style="cursor: pointer;">
                <td [routerLink]="['/medecin/consultation-liste', i._id]">
                  <div class="rounded-circle overflow-hidden mx-auto" style="width: 30px; height: 30px;">
                    <img *ngIf="!i.image" src="../../assets/user.png" alt="Image" class="img-fluid">
                    <img *ngIf="i.image" [src]="pathImage + i.image" alt="Image" class="img-fluid">
                  </div>
                </td>
                <td *ngIf="medecin">{{i.countExams}}
                  <a><i class="fa-solid fa-arrow-up mr-1" *ngIf="this['demandeUpdate' + k  ]" title="Cacher la liste des consultations" (click)="closeZoneConsultion(k)" title="Ajouter une consultation"></i></a>
                  <a><i class="fa-solid fa-arrow-down mr-1" *ngIf="!this['demandeUpdate' + k  ]" title="Afficher la liste des consultations" (click)="openZoneConsultion(k,i._id)"></i></a>
                </td>
                <td [routerLink]="['/medecin/consultation-liste', i._id]">{{i.numFiche}}</td>
                <td [routerLink]="['/medecin/consultation-liste', i._id]">{{i.nom}}</td>
                <td [routerLink]="['/medecin/consultation-liste', i._id]">{{i.prenom}}</td>
                <td [routerLink]="['/medecin/consultation-liste', i._id]">{{i.profession}}</td>
                <td [routerLink]="['/medecin/consultation-liste', i._id]">{{i.age}}</td>
                <td>
                  <div>
                    <a><i class="fa-solid fa-plus-circle mr-1" (click)="getPreviousConsultation(i._id)" data-toggle="modal" data-target="#myModal7" *ngIf="medecin" title="Ajouter une consultation"></i></a>
                    <a [routerLink]="['/medecin/consultation-liste', i._id]"><i class="fa-regular fa-eye mr-1" title="Détails patient"></i></a>
                    <a><i class="fa-solid fa-trash" (click)="setVariabletoDelet(i)" *ngIf="medecin" title="Supprimer un patient" data-toggle="modal" data-target="#myModal6"></i></a>
                  </div>
                </td>
                <!-- modal suppression patient -->
                <div class="modal fade" id="myModal6" tabindex="-1" role="dialog" aria-labelledby="myModal6" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content modal-content-scan">
                      <div class="modal-header d-flex">
                        <h4 class="modal-title">
                          Suppression d'un patient
                        </h4>
                        <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>
                      </div>
                      <div class="modal-body">
                        <div class="row" style="margin-left: 6%;">
                          <label>Voulez-vous vraiment supprimer ce patient?</label>
                        </div>
                        <div class="d-flex justify-content-center mt-4">
                          <!-- Ajout de la classe pour centrer -->
                          <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                            <button class="btn btn-secondary mr-2" data-dismiss="modal">Annuler</button>
                            <button class="btn btn-primary" data-dismiss="modal" (click)="removePatient(i)">Confirmer</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- modal suppression patient -->
                <!-- modal ajout motif de consultation -->
                <div class="modal fade" id="myModal7" tabindex="-1" role="dialog" aria-labelledby="myModal7" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content modal-content-scan">
                      <div class="modal-header d-flex">
                        <h4 class="modal-title" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">
                          Motif de consultation
                        </h4>
                        <h4 class="modal-title" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0">
                          Modifier la consultation
                        </h4>
                        <a class="exit" aria-label="Close" data-dismiss="modal" (click)="deleteSelectedMotif($event)"><i class=" fa-regular fa-circle-xmark"></i></a>
                      </div>
                      <div class="modal-body">
                        <div>
                          <form [formGroup]="formMotif">
                            <div class="form-row">
                              <label style="font-size: 14.5px;position: relative;top: 4px; " *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">Motif(s)</label>
                              <div class="form-group col">
                                <div class="col-10" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">
                                  <div class="col-12">
                                    <ng-select [(ngModel)]="selectedMotif" [items]="motifList" [virtualScroll]="true" [clearOnBackspace]="true" [clearable]="true" addTagText="Ajouter :" clearAllText="Effacer le texte" formControlName="motif" [addTag]="true" (change)="addInputMotif($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)"></ng-select>
                                    <!-- <input type="text" name="type" list="typename" style="border: 1px solid #bdbdbd;" class="form-control input-sm inputMotif" [(ngModel)]="selectedMotif" (change)="addInputMotif($event)" formControlName="motif" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)">
                                    <datalist id="typename">
                                      <option *ngFor="let motif of motifList" [value]="motif">
                                    </datalist> -->
                                  </div>
                                  <div *ngIf="formMotif.controls.motif?.invalid&& (formMotif.controls.motif?.dirty || formMotif.controls.motif?.touched)&& formMotif.controls.motif?.errors  ">
                                    <div *ngIf="formMotif.controls.motif?.errors.required && selectedMotifs.length === 0" style="color: red;font-size: 15px;">Ce champs est obligatoire</div>
                                    <div *ngIf="formMotif.controls.motif?.errors.minlength" style="color: red;font-size: 15px;">{{motifMinLength}}</div>
                                    <div *ngIf="formMotif.controls.motif?.errors.maxlength" style="color: red;font-size: 15px;">{{motifMaxLength}}</div>
                                    <div *ngIf="formMotif.controls.motif?.errors.invalidMotif" style="color: red;font-size: 15px;">{{champSpecialCaracters}}</div>
                                  </div>
                                  <div style="text-align: center;"><span *ngFor="let mot of selectedMotifs">{{mot}} <div class="deleted-btn" title="Supprimer" style="color: red;display: contents;cursor: pointer;" (click)="removeMotif(mot)">X
                                      </div> <br></span></div>
                                </div>
                                <div class="col-10" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0">
                                  <p id="consultationencours" style="font-size: 14.6px;text-align: center;">Vous avez déjà
                                    enregistré une consultation pour ce patient
                                    aujourd'hui. <br> Voulez-vous la modifier ?
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div class="d-flex justify-content-center mt-4">
                              <!-- Ajout de la classe pour centrer -->
                              <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                                <button class="btn btn-secondary mr-2" (click)="deleteSelectedMotif($event)">Annuler</button>
                                <button class="btn btn-primary" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1" (click)="checkFormAndSave(patient)">Sauvegarder</button>
                                <button class="btn btn-primary" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0" (click)="geToUpdateConsultation()">Modifier</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end modal ajout motif de consultation -->
              </tr>

              <tr *ngIf="this['demandeUpdate' + k  ]">
                <td colspan="8">
                  <!-- Your details table goes here -->
                  <table id="under-table">
                    <tr class="text-center">
                      <th scope="col" style="width: 20%;color:#ff5e00;">Date</th>
                      <th scope="col" style="width: 20%;color:#ff5e00;">Motif</th>
                      <th scope="col" style="width: 20%;color:#ff5e00">Durée</th>
                      <th scope="col" style="width: 30%;color:#ff5e00;">CAT</th>
                      <th scope="col" style="width: 10%;color:#ff5e00">Actions</th>
                    </tr>
                    <tr *ngFor="let examen of allExamnes;let z=index" [class.ligne-paire]="z % 2 !== 0" [class.ligne-impaire]="z % 2 === 0" class="text-center" style="cursor: pointer;">
                      <td [routerLink]="['/medecin/ajout/interrogatoire',i._id, examen.id]">{{ examen.start | date:"dd/MM/yyyy , à HH:mm"}}</td>
                      <td [routerLink]="['/medecin/ajout/interrogatoire',i._id, examen.id]"><span *ngFor="let mot of examen.motif">{{mot}} <br> </span></td>
                      <td [routerLink]="['/medecin/ajout/interrogatoire',i._id, examen.id]">{{ examen.duration }}</td>
                      <td [routerLink]="['/medecin/ajout/interrogatoire',i._id, examen.id]">
                        <ul id="ul-pos">
                          <li *ngFor="let tag of examen.tags" style="width: 40%;">
                            <div *ngIf="tag === 'Prescrire une chirurgie'">Prescrire une chirurgie pour <div *ngIf="examen.cat && examen.cat.chirurgie && examen.cat.chirurgie.length >0">{{examen.cat.chirurgie[0].oeil}}</div>
                            </div>
                            <div *ngIf="tag === 'Prescrire un laser'">
                              Prescrire un laser pour
                              <div *ngIf="examen.cat && examen.cat.prescrireLaser && examen.cat.prescrireLaser.length >0">{{examen.cat.prescrireLaser[0].oeil}}</div>
                            </div>
                            <div *ngIf="tag != 'Prescrire une chirurgie' && tag != 'Prescrire un laser'">{{tag}}</div>
                          </li>
                        </ul>
                      </td>
                      <td style="width: 3%;">
                        <a><i class="fa-solid fa-eye" title="Afficher la conclusion" data-toggle="modal" data-target="#myModalconclusion" (click)="selectExamen(examen)"></i></a>
                        <a style="position: relative;left: 5px;"><i class="fa-solid fa-file-text" title="Ajouter un document" (click)="goToExamenDetails(i._id, examen.id)"></i></a>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </ng-container>

          </tbody>
        </table>
        <div *ngIf="patients && patients.length > 10 " class=" border-0 mt-4">
          <div class="d-flex justify-content-center">
            <pagination-controls class="my-pagination" autoHide="true" previousLabel="Précédent" nextLabel="Suivant" (pageChange)="onTableDataChange($event)">
            </pagination-controls>
          </div>
        </div>
        <div *ngIf="patients && patients.length == 0" class="table-responsive" style="text-align: center">
          <p style="padding-bottom: 9rem; padding-top: 9rem ">
            Aucun patient n'est trouvé
          </p>
        </div>

      </div>
    </div>
  </div>
</div>

<!-- modal consultion -->
<div class="modal fade" id="myModalconclusion" tabindex="-1" role="dialog" aria-labelledby="myModalconclusion" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content modal-content-scan p-r-r-80">
      <div class="modal-header d-flex">
        <h4 class="modal-title">
          Conclusion  <i class="fa-solid fa-print" (click)="redirectionNouvelleConclusion()" title="Imprimer la conclusion"></i>
        </h4>
        <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>

      </div>
      <div class="modal-body width-700-px">
        <div class="row">
          <div class="col c-p" [ngClass]="{'p-cat': isActiveAnomalies}" (click)="showAnomalies()">Anomalies</div>
          <div class="col c-p" [ngClass]="{'p-cat': isActiveRefraction}" (click)="showRefraction()">Réfraction</div>
          <div class="col c-p" [ngClass]="{'p-cat': isActiveToCat}" (click)="showToCat()">TO & CAT</div>
          <div class="col c-p" [ngClass]="{'p-cat': isActiveRdv}" (click)="showRdv()">RDV</div>
        </div>
        <!-- isActiveAnomalies -->
        <br>
        <div class="row" *ngIf="isActiveAnomalies && myExamen">
          <app-interrogatoire-patient [myExamen]="myExamen"></app-interrogatoire-patient>
        </div>
        <!-- isActiveAnomalies -->
        <!-- isActiveRefraction -->
        <div class="row" *ngIf="isActiveRefraction && myExamen">
          <app-refraction-patient [myExamen]="myExamen"></app-refraction-patient>
        </div>
        <!-- isActiveRefraction -->
        <!-- isActiveToCat -->
        <div class="row" *ngIf="isActiveToCat && myExamen">
          <app-cat-lispatient [myExamen]="myExamen"></app-cat-lispatient>
        </div>
        <!-- isActiveToCat -->
        <!-- isActiveRdv -->
        <div class="row" *ngIf="isActiveRdv && myExamen">
          <app-program-lispatient [myExamen]="myExamen"></app-program-lispatient>
        </div>
        <!-- isActiveRdv -->
      </div>
    </div>
  </div>
</div>
<!-- modal conclusion  -->