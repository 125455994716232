<div class="col-md-12 m-t-20" *ngIf="myExamen">
    <!-- Anomalie à l'interrogatoire  -->
    <div>
        <span class="badge" *ngIf="!checkExistingAnomalieInterrogatoire()">Aucun</span>
        <h6 id="color-red" *ngIf="checkExistingAnomalieInterrogatoire()">Anomalie à l'interrogatoire</h6>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsophtalmologiquepersonnels && myExamen?.interrogation.antecedentsophtalmologiquepersonnels.medicaux.length>0 ">
            <li>Médicaux</li>
            <ul>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsophtalmologiquepersonnels.medicaux">
                    <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                        {{value.name}} (OD)
                    </ng-container>
                </li>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsophtalmologiquepersonnels.medicaux">
                    <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                        {{value.name}} (OG)
                    </ng-container>
                </li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsophtalmologiquepersonnels && myExamen?.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours.length>0">
            <li>Traitement En Cours</li>
            <ul>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours">
                    <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                        {{value.name}} (OD)
                    </ng-container>
                </li>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours">
                    <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                        {{value.name}} (OG)
                    </ng-container>
                </li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsophtalmologiquepersonnels && myExamen?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name">
            <li>Antécédents de traitement par laser</li>
            <ul>
                <li>
                    <ng-container *ngIf="myExamen?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'oeilDroit'
                     || myExamen?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'deuxYeux'">
                        {{myExamen?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name}} (OD)
                    </ng-container>
                </li>
                <li>
                    <ng-container *ngIf="myExamen?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'oeilGauche' 
                    || myExamen?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'deuxYeux'">
                        {{myExamen?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name}} (OG)
                    </ng-container>
                </li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsophtalmologiquepersonnels && myExamen?.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux.length>0 ">
            <li>Chirurgicaux</li>
            <ul>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux">
                    <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                        {{value.name}} (OD)
                    </ng-container>
                </li>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux">
                    <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                        {{value.name}} (OG)
                    </ng-container>
                </li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsophtalmologiquepersonnels && myExamen?.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length>0 || myExamen?.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length>0">
            <li>Correction optique</li>
            <ul>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique">{{value.name}} (OD)</li>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique">{{value.name}} (OG)</li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsGeneraux.oielDroit && myExamen?.interrogation.antecedentsGeneraux.oielDroit.grossesseenCours && myExamen?.interrogation.antecedentsGeneraux.oielDroit.grossesseenCours.present">
            <li>Grossesse en cours</li>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsGeneraux.oielDroit && myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainatopique && myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainatopique.present">
            <li>Terrain atopique</li>
            <ul>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainatopique.valeurs">{{value.name}}</li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsGeneraux.oielDroit && myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainvasculaireparticulier && myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainvasculaireparticulier.present">
            <li>Terrain vasculaire particulier</li>
            <ul>
                <li *ngIf="myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainvasculaireparticulier.diabete.present">Diabète</li>
                <li *ngIf="myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainvasculaireparticulier.hta.present">HTA</li>
                <li *ngIf="myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainvasculaireparticulier.terrainvasculaireparticulierautre.present">Autre</li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsGeneraux.oielDroit && myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainimmunodepression && myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainimmunodepression.present">
            <li>Terrain d'immunodepression</li>
            <ul>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainimmunodepression.valeurs">{{value.name}}</li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsGeneraux.oielDroit && myExamen?.interrogation.antecedentsGeneraux.oielDroit.maladiedysimunitaire && myExamen?.interrogation.antecedentsGeneraux.oielDroit.maladiedysimunitaire.present">
            <li>Maladie dysimmunitaire</li>
            <ul>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsGeneraux.oielDroit.maladiedysimunitaire.valeurs">{{value.name}}</li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsGeneraux.oielDroit && myExamen?.interrogation.antecedentsGeneraux.oielDroit.maladieneurologique && myExamen?.interrogation.antecedentsGeneraux.oielDroit.maladieneurologique.present">
            <li>Maladie neurologique</li>
            <ul>
                <li *ngFor="let value of myExamen?.interrogation.antecedentsGeneraux.oielDroit.maladieneurologique.valeurs">{{value.name}}</li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsGeneraux.oielDroit && myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionvaccinationrecente && myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionvaccinationrecente.present">
            <li>Notion de vaccination récente</li>
            <ul>
                <li>Type : {{myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionvaccinationrecente.type}}</li>
                <li>Date : {{myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionvaccinationrecente.date}}</li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsGeneraux.oielDroit && myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionanesthesierecente && myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionanesthesierecente.present">
            <li>Notion d'anesthésie récente</li>
            <ul>
                <li>Type : {{myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionanesthesierecente.type}}</li>
                <li>Date : {{myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionanesthesierecente.date}}</li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsGeneraux.oielDroit && myExamen?.interrogation.antecedentsGeneraux.oielDroit.traitementencours && myExamen?.interrogation.antecedentsGeneraux.oielDroit.traitementencours.present">
            <li>Traitement en cours</li>
            <ul>
                <li>Type : {{myExamen?.interrogation.antecedentsGeneraux.oielDroit.traitementencours.type}}</li>
                <li>Dose : {{myExamen?.interrogation.antecedentsGeneraux.oielDroit.traitementencours.dose}}</li>
            </ul>
        </ul>
        <ul *ngIf="myExamen?.interrogation && myExamen?.interrogation.antecedentsFamiliauxOphtalmologique && myExamen?.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht">
            <li *ngFor="let antecedent of myExamen?.interrogation && myExamen?.interrogation.antecedentsFamiliauxOphtalmologique && myExamen?.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht">{{antecedent.name}}</li>
        </ul>
    </div>
</div>