import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackofficeRoutingModule } from './backoffice-routing.module';
import { BackofficeComponent } from './backoffice.component';
import { AccueilComponent } from './accueil/accueil.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControleMessagesModule } from '../shared/controle-messages/controle-messages.module';
import { FlashMessagesModule } from 'src/module';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { GlobalSearchPipe } from 'src/app/dashboard/search/globalSearch.pipe'
import { SearchOrdonnancePipe } from 'src/app/dashboard/search/searchOrdonnance.pipe'
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { NgSelect2Module } from 'ng-select2';
import { FileUploadModule } from 'ng2-file-upload';
import { CalendarComponentt } from './calendar/calendar/calendar.component';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { FullCalendarModule } from 'ng-fullcalendar';
import { TrouverMedecinsComponent } from './trouverMedecins/trouver-medecins/trouver-medecins.component';
import { PatientComponent } from './patient/patient/patient.component';
import { ListPatientsComponent } from './patient/list-patients/list-patients.component';
import { UpdatePatientComponent } from './patient/update-patient/update-patient.component';
import { SearchPatientPipe } from '../search/searchPatient.pipe';
import { RecherchePatientsComponent } from './patient/recherche-patients/recherche-patients.component';
import { ListconsultationComponent } from './consultation/listconsultation/listconsultation.component';
import { UpdateConsultationComponent } from './consultation/update-consultation/update-consultation.component';
import { SearchCertificatsPipe } from '../search/searchCertificats.pipe';
import { SearchPipe } from '../search/search.pipe';
import { SearchMedecinsPipe } from '../search/searchMedecins.pipe';
import { SearchDocumentPipe } from '../search/serachDocument.pipe';
import { AjoutMedecinTraitantComponent } from './patient/ajout-medecin-traitant/ajout-medecin-traitant.component';
import { AjoutDocumentConsultationComponent } from './consultation/document/ajout-document-consultation/ajout-document-consultation.component';
import { AjoutDocumentPatientComponent } from './consultation/document/ajout-document-patient/ajout-document-patient.component';
import { ListdocumentconsultationComponent } from './consultation/document/listdocumentconsultation/listdocumentconsultation.component';
import { UpdateDocumentPatientComponent } from './consultation/document/update-document-patient/update-document-patient.component';

import { WebcamModule } from 'ngx-webcam';
import { ExamenComponent } from './examen/examen/examen.component';
import { ListExamensComponent } from './examen/list-examens/list-examens.component';
import { InterrogatoireComponent } from './examen/interrogatoire/interrogatoire.component';
import { DocumentExamenComponent } from './examen/document-examen/document-examen.component';
import { ConclusionComponent } from './examen/conclusion/conclusion.component';
import { Ng2IziToastModule } from 'ng2-izitoast';//<-- this line
import { LafComponent } from './examen/examen/laf/laf.component';
import { RefractionComponent } from './examen/examen/refraction/refraction.component';
import { AnnexeComponent } from './examen/examen/annexe/annexe.component';
import { SclereComponent } from './examen/examen/laf/sclere/sclere.component';
import { ConjComponent } from './examen/examen/laf/conj/conj.component';
import { ChambreAnterieureComponent } from './examen/examen/laf/chambre-anterieure/chambre-anterieure.component';
import { CorneeComponent } from './examen/examen/laf/cornee/cornee.component';
import { IrisComponent } from './examen/examen/laf/iris/iris.component';
import { RpmComponent } from './examen/examen/laf/rpm/rpm.component';
import { DilatationComponent } from './examen/examen/laf/dilatation/dilatation.component';
import { CristallinComponent } from './examen/examen/laf/cristallin/cristallin.component';
import { AngleIridoComponent } from './examen/examen/laf/angle-irido/angle-irido.component';
import { VitreComponent } from './examen/examen/laf/vitre/vitre.component';
import { PapilleComponent } from './examen/examen/laf/papille/papille.component';
import { MaculaComponent } from './examen/examen/laf/macula/macula.component';
import { AnomalieVasculaireComponent } from './examen/examen/laf/anomalie-vasculaire/anomalie-vasculaire.component';
import { RetineComponent } from './examen/examen/laf/retine/retine.component';
import { ChartsModule } from 'ng2-charts';
import { SideBarDroitComponent } from './examen/examen/side-bar-droit/side-bar-droit.component';
import { SideBarGaucheComponent } from './examen/examen/side-bar-gauche/side-bar-gauche.component';
import { ProfileComponent } from './profile/profile.component';
import { ImpressionCatComponent } from './examen/conclusion/impression-cat/impression-cat.component';
import { ImpressionConclusionComponent } from './examen/conclusion/impression-conclusion/impression-conclusion.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LettreConfrereComponent } from './examen/conclusion/impression-cat/lettreConfrere/lettreConfrere.component';
import { BilanOrthopediqueComponent } from './examen/conclusion/impression-cat/bilan-orthopedique/bilan-orthopedique.component';
import { LaserComponent } from './examen/conclusion/impression-cat/laser/laser.component';
import { ExamenComplementaireComponent } from './examen/conclusion/impression-cat/examen-complementaire/examen-complementaire.component';
import { ChirugieComponent } from './examen/conclusion/impression-cat/chirugie/chirugie.component';
import { OrdonnanceComponent } from './examen/conclusion/impression-cat/ordonnance/ordonnance.component';
import { CertificatMedicalComponent } from './examen/conclusion/impression-cat/certificat-medical/certificat-medical.component';
import { ConduiteComponent } from './examen/conclusion/impression-cat/conduite/conduite.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HeaderTemplateComponent } from './examen/conclusion/shared/header-template/header-template.component';
import { FooterTemplateComponent } from './examen/conclusion/shared/footer-template/footer-template.component';
import { LunetteComponent } from './examen/conclusion/impression-cat/lunette/lunette.component';
import { LentilleComponent } from './examen/conclusion/impression-cat/lentille/lentille.component';
import { InterrogatoirePatientComponent } from './patient/list-patients/interrogatoire-patient/interrogatoire-patient.component';
import { RefractionPatientComponent } from './patient/list-patients/refraction-patient/refraction-patient.component';
import { CatLispatientComponent } from './patient/list-patients/cat-lispatient/cat-lispatient.component';
import { ProgramLispatientComponent } from './patient/list-patients/program-lispatient/program-lispatient.component';
import { MessagerieComponent } from './messagerie/messagerie.component';
import { OrderByPipe } from '../search/order-by.pipe';
import { SecretaireComponent } from './secretaire/secretaire.component';
import { NumericPipe } from 'src/app/services/numericPipe/numeric.pipe';
import { SalleAttComponent } from './salle-att/salle-att.component';
import { RdvsComponent } from './rdvs/rdvs.component';
import { AddRdvComponent } from './rdvs/add-rdv/add-rdv.component';
import { UpdateRdvComponent } from './rdvs/update-rdv/update-rdv.component';
import { ConfirmationComponent } from './popups/confirmation/confirmation.component';
import { AnnulationComponent } from './popups/annulation/annulation.component';


@NgModule({
  declarations: [NumericPipe, OrderByPipe, SearchDocumentPipe, SearchMedecinsPipe, SearchPipe, SearchCertificatsPipe, SearchPatientPipe, BackofficeComponent, AccueilComponent, GlobalSearchPipe, SearchOrdonnancePipe, CalendarComponentt, TrouverMedecinsComponent, PatientComponent, ListPatientsComponent, UpdatePatientComponent, RecherchePatientsComponent, ListconsultationComponent, UpdateConsultationComponent, AjoutMedecinTraitantComponent, AjoutDocumentConsultationComponent, AjoutDocumentPatientComponent, ListdocumentconsultationComponent, UpdateDocumentPatientComponent, ExamenComponent, ListExamensComponent, InterrogatoireComponent, DocumentExamenComponent, ConclusionComponent, LafComponent, RefractionComponent, AnnexeComponent, SclereComponent, ConjComponent, ChambreAnterieureComponent, CorneeComponent, IrisComponent, RpmComponent, DilatationComponent, CristallinComponent, AngleIridoComponent, VitreComponent, PapilleComponent, MaculaComponent, AnomalieVasculaireComponent, RetineComponent, SideBarDroitComponent, SideBarGaucheComponent, ProfileComponent, ImpressionCatComponent, ImpressionConclusionComponent, LettreConfrereComponent, BilanOrthopediqueComponent, LaserComponent, ExamenComplementaireComponent, ChirugieComponent, OrdonnanceComponent, CertificatMedicalComponent, ConduiteComponent, HeaderTemplateComponent, FooterTemplateComponent, LunetteComponent, LentilleComponent, InterrogatoirePatientComponent, RefractionPatientComponent, CatLispatientComponent, ProgramLispatientComponent, MessagerieComponent, SalleAttComponent, SecretaireComponent
    , RdvsComponent,
    AddRdvComponent,
    UpdateRdvComponent,
    ConfirmationComponent,
    AnnulationComponent],
  imports: [
    CommonModule,
    WebcamModule,
    ChartsModule,
    NgxIntlTelInputModule,
    NgSelectModule,
    BackofficeRoutingModule, NgxPaginationModule, FormsModule, ReactiveFormsModule,
    ControleMessagesModule, InternationalPhoneNumberModule,
    NguiAutoCompleteModule, FlashMessagesModule, NgSelect2Module, FileUploadModule, NgbModalModule, FullCalendarModule, Ng2IziToastModule

  ]
})
export class BackofficeModule { }
