import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';


@Injectable(
    { providedIn: 'root' }
)
export class TraitementEnCoursService {


    constructor(private http: HttpClient) { }

    ajoutTraitementEnCours(traitementEnCours) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.post(_getURL(API_CONFIG.traitementEnCours), traitementEnCours, { headers: headers })
    }
    getTraitementEnCoursByDoctor() {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get<any>(_getURL(API_CONFIG.traitementEnCours) + "/get-traitementEnCours-by-doctor", { headers: headers });
    }
    deleteTraitementEnCours(id) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.delete<any>(_getURL(API_CONFIG.traitementEnCours) + "/delete-traitementEnCours/" + id, { headers: headers });
    }
}