import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';


@Injectable(
    { providedIn: 'root' }
)
export class ChirugieService {


    constructor(private http: HttpClient) { }

    ajoutChirugie(chirugie) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.post(_getURL(API_CONFIG.chirugie), chirugie, { headers: headers })
    }
    getChirugieByDoctor() {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get<any>(_getURL(API_CONFIG.chirugie) + "/get-chirugies-by-doctor", { headers: headers });
    }
    deleteChirugie(id) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.delete<any>(_getURL(API_CONFIG.chirugie) + "/delete-chirugie/" + id, { headers: headers });
    }
}