import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numeric'
})
export class NumericPipe implements PipeTransform {
    transform(value: string | number): number {
        const numericValue = Number(value);

        // Return 'Plan' if it's not a valid number, otherwise return the numeric value
        return isNaN(numericValue) ? 0 : numericValue;
    }
}