import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';


@Injectable(
    { providedIn: 'root' }
)
export class TypeAntecedentFamiliauxService {


    constructor(private http: HttpClient) { }

    ajoutTypeAntecedentFamiliaux(typeAntecedentFamiliaux) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.post(_getURL(API_CONFIG.typeAntecedentFamiliaux), typeAntecedentFamiliaux, { headers: headers })
    }
    getTypeAntecedentFamiliauxByDoctor() {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get<any>(_getURL(API_CONFIG.typeAntecedentFamiliaux) + "/get-typeAntecedentFamiliauxs-by-doctor", { headers: headers });
    }
    deleteTypeAntecedentFamiliaux(id) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.delete<any>(_getURL(API_CONFIG.typeAntecedentFamiliaux) + "/delete-typeAntecedentFamiliaux/" + id, { headers: headers });
    }
}