<div class="responsive" style="max-width: 100% !important; overflow-x: hidden !important;;margin-bottom: -31px;" *ngIf="patient">
  <section class="fond2">
    <section class=" container3 ">
      <div class="row" style="margin-bottom: -18px;">
        <div class="col-12">
          <div class="row pat">
            <div class="col-4">
              <b>{{patient.nom}} {{patient.prenom}}</b>
            </div>
            <div class="col-1 point">
              <img src="assets/images/patsDetails/point2.png" class="pointImg">
            </div>
            <div class="col-4 mediv">
              <b> {{patient.identifiantMedivisto}}
              </b>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row butts">
            <div class="col-1 button">
              <div class=" buttoncadre" *ngIf="patient.createdByDoctor">
                <a [routerLink]="['/medecin/patient', patient._id]" title="Modifier patient">
                  <img class="iconButt " src="assets/images/patsDetails/icon6.png"></a>
              </div>
            </div>
            <div class="col-1 button">
              <div class=" buttoncadre" *ngIf="medecin">
                <a title="Historique des consultations" (click)="scrollHistorique(historiqueExamen)">
                  <img class="iconButt " src="assets/images/patsDetails/icon2.png">
                </a>
              </div>
            </div>
            <div class="col-1 button">
              <div class=" buttoncadre" *ngIf="medecin">
                <a title="Ajouter une consultation" (click)="getPreviousConsultation(patient._id)" data-toggle="modal" data-target="#myModalExamen">
                  <img class="iconButt " src="assets/images/add-icon-green.png"></a>
              </div>
            </div>
            <!-- modal ajout motif de consultation -->
            <div class="modal fade" id="myModalExamen" tabindex="-1" role="dialog" aria-labelledby="myModalExamen" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content modal-content-scan">
                  <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
                    <h4 class="modal-title" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">
                      Motif de consultation
                    </h4>
                    <h4 class="modal-title" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0">
                      Modifier la consultation
                    </h4>
                    <a class="exit" aria-label="Close" data-dismiss="modal" (click)="deleteSelectedMotif($event)"><i class="fa-regular fa-circle-xmark"></i></a>
                  </div>
                  <div class="modal-body">
                    <div>
                      <form [formGroup]="formMotif">
                        <div class="form-row">
                          <label style="font-size: 14.5px;position: relative;top: 4px; " *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">Motif(s)</label>
                          <div class="form-group col">
                            <div class="col-10" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1">
                              <div class="col-12">
                                <ng-select [(ngModel)]="selectedMotif" [items]="motifList" [virtualScroll]="true" [clearable]="true" clearAllText="Effacer le texte" addTagText="Ajouter :" formControlName="motif" [addTag]="true" (change)="addInputMotif($event)" onkeypress="return /[a-zA-ZàâäéèêëïîôœöùûüÿçÀÂÄÉÈÊËÏÎÔÖÙÛÜŸÇ ]/i.test(event.key)"></ng-select>
                              </div>
                              <div *ngIf="formMotif.controls.motif?.invalid&& (formMotif.controls.motif?.dirty || formMotif.controls.motif?.touched)&& formMotif.controls.motif?.errors  " class="text-center">
                                <div *ngIf=" formMotif.controls.motif?.errors.required && selectedMotifs.length===0" style="color: red;font-size: 15px;">Le motif est obligatoire</div>
                                <div *ngIf="formMotif.controls.motif?.errors.minlength" style="color: red;font-size: 15px;">{{motifMinLength}}</div>
                                <div *ngIf="formMotif.controls.motif?.errors.maxlength" style="color: red;font-size: 15px;">{{motifMaxLength}}</div>
                                <div *ngIf="formMotif.controls.motif?.errors.invalidMotif" style="color: red;font-size: 15px;">{{champSpecialCaracters}}</div>
                              </div>
                              <div style="text-align: center;"><span *ngFor="let mot of selectedMotifs">{{mot}} <div class="deleted-btn" title="Supprimer" style="color: red;display: contents;cursor: pointer;" (click)="removeMotif(mot)">X
                                  </div> <br></span></div>
                            </div>
                            <div class="col-10" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0">
                              <p id="consultationencours" style="font-size: 14.6px;text-align: center;">Vous avez déjà
                                enregistré une consultation pour ce patient
                                aujourd'hui. <br> Voulez-vous la modifier ?
                              </p>
                            </div>
                          </div>
                        </div>

                        <div class="d-flex justify-content-center mt-4">
                          <!-- Ajout de la classe pour centrer -->
                          <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                            <button class="btn btn-secondary mr-2" (click)="deleteSelectedMotif($event)">Annuler</button>
                            <button class="btn btn-primary" *ngIf="listpreviousconsultations && listpreviousconsultations.length <1" (click)="checkFormAndSave(patient)">Sauvegarder</button>
                            <button class="btn btn-primary" *ngIf="listpreviousconsultations && listpreviousconsultations.length >0" (click)="geToUpdateConsultation()">Modifier</button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- modal ajout motif de consultation  -->
          </div>
        </div>
      </div>
      <div class="informationPat2 image-container">
        <img src="assets/images/patsDetails/pat1.png" *ngIf="!pathImage" style="cursor: pointer;" title="Prendre une photo" data-toggle="modal" data-target="#fileUploadModal" class="imgMed">
        <img src="{{pathImage}}" *ngIf="pathImage" style="cursor: pointer;" title="Prendre une photo" data-toggle="modal" data-target="#fileUploadModal" class="imgMed" style="border-radius: 36px;height: 80px;">
        <div class="upload-indicator">
            <img src="assets/images/img-camera.png" data-toggle="modal" data-target="#fileUploadModal" style="cursor: pointer;width: 30px;" title="Prendre une photo">
         </div>

      </div>
      <div class="informationPat">
        <div class="fiche">
          <b class="ficheNum">N° Fiche : </b> <b>{{patient.numFiche}}</b>
        </div>
        <div class="row informationsPat">
          <div class="col-4">
            <span><b>Date de naissance : </b></span>
            <span *ngIf="patient.dateAnniversaire">{{patient.dateAnniversaire | date: 'dd/MM/yyyy'}}</span>
            <span *ngIf="!patient.dateAnniversaire" class="badge">Aucun</span>
            <br>
            <span><b>Age : </b></span>
            <span *ngIf="patient.age">{{patient.age}} ans</span>
            <span *ngIf="!patient.age" class="badge">Aucun</span> <br>
            <span><b>Sexe : </b></span>
            <span *ngIf="patient.sexe">{{patient.sexe}}</span>
            <span *ngIf="!patient.sexe" class="badge">Aucun</span> <br>
            <span><b>Groupe Sanguin : </b> </span>
            <span *ngIf="patient.groupeSanguin">{{patient.groupeSanguin}}</span>
            <span *ngIf="!patient.groupeSanguin" class="badge">Aucun</span>
          </div>
          <div class="col-4">
            <span><b>Statut Familial : </b></span>
            <span *ngIf="patient.statusFamilial">{{patient.statusFamilial}}</span>
            <span *ngIf="!patient.statusFamilial" class="badge">Aucun</span> <br>
            <span><b>Profession : </b></span>
            <span *ngIf="patient.profession">{{patient.profession}}</span>
            <span *ngIf="!patient.profession" class="badge">Aucun</span> <br>
            <span><b>N° Mobile : </b></span>
            <span *ngIf="patient.tel">{{patient.tel}}</span>
            <span *ngIf="!patient.tel" class="badge">Aucun</span> <br>
            <span><b>Adresse : </b> </span>
            <span *ngIf="patient.adresse.adresse ||patient.adresse.codePostal||patient.adresse.ville ">{{patient.adresse.adresse}}, {{patient.adresse.codePostal}}{{patient.adresse.ville}}</span>
            <span *ngIf="!patient.adresse.adresse &&!patient.adresse.codePostal&&!patient.adresse.ville " class="badge">Aucun</span>
          </div>
          <div class="col-4">
            <span><b>Email : </b></span>
            <span *ngIf="patient.email">{{patient.email}}</span>
            <span *ngIf="!patient.email" class="badge">Aucun</span> <br>
            <span><b>APCI : </b> </span>
            <div *ngIf="patient.apci.length>0" class="inline-div">
              <ul class="inline-div-ul">
                <li *ngFor="let ap of patient.apci">{{getNameById(ap)}}</li>
              </ul>
            </div>
            <span *ngIf="patient.apci.length==0" class="badge"> Aucun</span>
          </div>
        </div>
        <br> <br>
      </div>
      <!---------------------------- Liste Visites effectuées ---------->
      <div class="" #historiqueExamen>
      </div>

      <div class="informationPat3" *ngIf="medecin">
        <div class="row bar">
          <div class="col-2"><a class="linkPart2"><b>Visites effectuées</b></a></div>

        </div>
        <div class="row rightButts">
          <div class="col-1 c1" style=" margin-right: 82%;">
            <div class=" buttoncadre0">
              <div class="row">
                <div class="col-2">
                  <img class="iconButt2 " src="assets/images/patsDetails/search_3.png" style="visibility: hidden">
                </div>
                <div class="col-6">
                  <input type="text" name="name" id="recherche" class="recherche" placeholder="Rechercher ... " style="visibility: hidden" [(ngModel)]="search" name="search">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="div-tab2">
          <div class="row" style="overflow-x:auto;">
            <table class="table  ">
              <thead>
                <th id="firstThTable" scope="col" style="color:#589590;text-align: center;"><b>DATE</b></th>
                <th scope="col" style="color:#589590;text-align: center;"><b>MOTIF</b></th>
                <th scope="col" style="color:#589590;text-align: center;"><b>DURÉE</b></th>
                <th scope="col" style="color:#589590;width: 20%;margin-right: 26px;text-align: center;" *ngIf="medecin"><b>PARAMÈTRES</b></th>
              </thead>
              <tbody *ngFor="let consultation of allExamnes| paginate: { itemsPerPage: 25, currentPage: p, id: 'p'  }|searching : search.toString().toLowerCase();let k=index">
                <tr [class.ligne-paire]="k % 2 !== 0" [class.ligne-impaire]="k % 2 === 0" class="text-center">
                  <td>{{consultation.start | date: 'dd/MM/yyyy, HH:mm'}}</td>
                  <td><span *ngFor="let mot of consultation.motif">{{mot}}<br></span></td>
                  <td>{{consultation.duration}}</td>
                  <td style="border-bottom-style: none;" *ngIf="medecin">
                    <a [routerLink]="['/medecin/ajout/interrogatoire',patient._id, consultation.id]" title="Modifier consultation"><i class="fa-solid fa-pencil mr-1"></i></a>
                    <a><i class="fa-solid fa-trash" (click)="selectedConsultationFunction(consultation)" title="Supprimer" data-toggle="modal" data-target="#myModal9"></i></a>
                  </td>
                  <!---------------------------- Pop up supprimer VISITE ---------->
                  <div class="modal fade" id="myModal9" tabindex="-1" role="dialog" aria-labelledby="myModal9" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                      <div class="modal-content modal-content-scan">
                        <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
                          <h4 class="modal-title">
                            Suppression d'un Examen
                          </h4>
                          <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>
                        </div>
                        <div class="modal-body">
                          <div class="row" style="margin-left: 6%;">
                            <label style="font-size: 14.6px;text-align: center;">Voulez-vous vraiment supprimer cet examen?</label>
                          </div>
                          <div class="d-flex justify-content-center mt-4">
                            <!-- Ajout de la classe pour centrer -->
                            <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                              <button class="btn btn-secondary mr-2" data-dismiss="modal">Annuler</button>
                              <button class="btn btn-primary" data-dismiss="modal" (click)="deleteconsultation(consultation)">Confirmer</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- modal suppression VISITE -->

                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="pagination2">
          <div class="d-flex justify-content-center" style="padding-top: 10px;">
            <pagination-controls previousLabel="Précédent " nextLabel="Suivant" (pageChange)="p = $event" id="p"></pagination-controls>
          </div>
        </div>
      </div>
    </section>

    <!---------------------------- Pop up supprimé avec succés ---------->
    <div class="modal fade" id="deleted" tabindex="-1" role="dialog" aria-labelledby="myModal0" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-scan">
          <div class="modal-header d-flex" style="border-bottom: 1px solid #e0e0e0;margin-top: auto;">
            <h4 class="modal-title">
              Suppression d'un Examen
            </h4>
            <a class="exit" aria-label="Close" data-dismiss="modal"><i class="fa-regular fa-circle-xmark"></i></a>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-left: 6%;">
              <label style="font-size: 14.6px;text-align: center;"> Cet examen est supprimé avec succés!</label>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <!-- Ajout de la classe pour centrer -->
              <div class="text-center"> <!-- Ajout de la classe pour centrer -->
                <!-- <button class="btn btn-secondary mr-2" data-dismiss="modal">Annuler</button> -->
                <button class="btn btn-primary" data-dismiss="modal">Confirmer</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---------------------------- Pop up modifié avec succés ---------->
    <div class="modal fade" id="edited" tabindex="-1" role="dialog" aria-labelledby="myModal0" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-scan">
          <div class="modal-header d-flex justify-content-center">
            <h3 class="titre-modal-statut">
              MODIFIÉ AVEC SUCCÉS !
            </h3>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-left: 6%;">
              <div class="col-10 text-center"><img src="assets/images/patsDetails/edit-2.png" class="imgPop"></div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <div class=" col d-flex justify-content-center buttonsPopup">
                <div class="col-auto nbr-dossier ">
                  <table>
                    <tr>
                      <td class="tdButt1 ">
                        <button class="btn-valider" data-dismiss="modal">Ok </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---------------------------- Pop up ajouté avec succés ---------->
    <div class="modal fade" id="added" tabindex="-1" role="dialog" aria-labelledby="myModal0" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-scan">
          <div class="modal-header d-flex justify-content-center">
            <h3 class="titre-modal-statut">
              AJOUTÉ AVEC SUCCÉS !
            </h3>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-left: 6%;">
              <div class="col-10 text-center"><img src="assets/images/patsDetails/plus-3.png" class="imgPop"></div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <div class=" col d-flex justify-content-center buttonsPopup">
                <div class="col-auto nbr-dossier ">
                  <table>
                    <tr>
                      <td class="tdButt1 ">
                        <button class="btn-valider" data-dismiss="modal">Ok </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---------------------------- Pop up message erreur ---------->
    <div class="modal fade" id="error" tabindex="-1" role="dialog" aria-labelledby="myModal0" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-content-scan">
          <div class="modal-header d-flex justify-content-center">
            <h3 class="titre-modal-statut">
              Erreur !
            </h3>
          </div>
          <div class="modal-body">
            <div class="row" style="margin-left: 6%;">
              <div class="col-10 text-center"><img src="assets/images/patsDetails/error.png" class="imgPop"></div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
              <div class=" col d-flex justify-content-center buttonsPopup">
                <div class="col-auto nbr-dossier ">
                  <table>
                    <tr>
                      <td class="tdButt1 ">
                        <button class="btn-valider" data-dismiss="modal">Ok </button>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </section>
</div>



<!-- mODEL UPLOAD IMAGE-->
<div class="modal" id="fileUploadModal" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="margin-left: -25%;min-width: 650px;max-width: 650px;">
      <div class="modal-header" style="border-bottom: 1px solid;margin-top: 0%;border-bottom-color: #eeeeee;background-color: #fafafa;">
        <h5 class="modal-title">Prendre une photo</h5>
        <button type="button" class="close" data-dismiss="modal" title="Fermer" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div id="app">
          <div class="row">
            <p *ngIf="!authorized" id="alertActiverCamera">L'accès à la caméra est bloqué. <br> Veuillez l'activer dans les paramètres de votre navigateur.</p>
          </div>
          <div class="row">
            <div class="col-md-6">
              <video #video id="video" width="280" height="280" autoplay></video>
            </div>
            <div class="col-md-6">
              <canvas #canvas id="canvas" style="border-radius: 140px;" width="280" height="280"></canvas>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <button class="btn button-save" style="background: #f16c28;color: white;margin: 0 15px;font-weight: bold;font-size: 13px;margin-bottom: 10px;" *ngIf="authorized" (click)="capture()">Prendre une photo</button>
              <br>
              <button class="btn button-save" style="color: white;background-color: #008A7F;margin: 0 15px;font-weight: bold;font-size: 13px;margin-bottom: 10px;" *ngIf="authorized && captures && captures.length >0" data-dismiss="modal" (click)="saveImages()">Sauvegarder</button>
            </div>
            <div class="col-md-6">
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>
<!--END UPLOAD MODEL-->