import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-refraction-patient',
  templateUrl: './refraction-patient.component.html',
  styleUrls: ['./refraction-patient.component.css', '../list-patients.component.css','../interrogatoire-patient/interrogatoire-patient.component.css']
})
export class RefractionPatientComponent implements OnInit {
  @Input() myExamen: any;
  echelleMonoyerList = ["PLBO", "PLMO", "VBLM", "CLD à 1m", "CLD à 2m", "CLD à 3m", "1/10", "2/10", "3/10", "4/10", "5/10", "6/10", "7/10", "8/10", "9/10", "10/10"]
  echelleSnellenList = ["PLBO", "PLMO", "VBLM", "CLD à 1m", "CLD à 2m", "CLD à 3m", "20/400", "20/200", "20/80", "20/40", "20/25", "20/20", "20/15", "20/10"]
  echelleParinaudList = ["P1", "P2", "P3", "P4", "P5"]
  constructor() { }

  ngOnInit(): void { }
  checkExistingRefraction() : boolean{
    return this.myExamen?.refraction.sansCycloplegie.oielDroit.sphere || this.myExamen?.refraction.sansCycloplegie.oielDroit.cylindre
    || this.myExamen?.refraction.sansCycloplegie.oielDroit.axe || this.myExamen?.refraction.sansCycloplegie.oielDroit.keratometrie
    || this.myExamen?.refraction.sansCycloplegie.oielGauche.sphere || this.myExamen?.refraction.sansCycloplegie.oielGauche.cylindre
    || this.myExamen?.refraction.sansCycloplegie.oielGauche.axe || this.myExamen?.refraction.sansCycloplegie.oielGauche.keratometrie
    || this.myExamen?.refraction.avecCycloplegie.type || this.myExamen?.refraction.avecCycloplegie.oielDroit.sphere || this.myExamen?.refraction.avecCycloplegie.oielDroit.cylindre
    || this.myExamen?.refraction.avecCycloplegie.oielDroit.axe || this.myExamen?.refraction.avecCycloplegie.oielDroit.keratometrie
    || this.myExamen?.refraction.avecCycloplegie.oielGauche.sphere || this.myExamen?.refraction.avecCycloplegie.oielGauche.cylindre
    || this.myExamen?.refraction.avecCycloplegie.oielGauche.axe || this.myExamen?.refraction.avecCycloplegie.oielGauche.keratometrie
    || this.myExamen?.refraction.acuitevisueldeloin.oielDroit.avsc || this.myExamen?.refraction.acuitevisueldeloin.oielGauche.avsc
    || this.myExamen?.refraction.acuitevisueldeloin.oielDroit.avac || this.myExamen?.refraction.acuitevisueldeloin.oielGauche.avac
    || this.myExamen?.refraction.acuitevisueldeloin.oielDroit.sphere || this.myExamen?.refraction.acuitevisueldeloin.oielDroit.cylindre || this.myExamen?.refraction.acuitevisueldeloin.oielDroit.axe
    || this.myExamen?.refraction.acuitevisueldeloin.oielGauche.sphere || this.myExamen?.refraction.acuitevisueldeloin.oielGauche.cylindre || this.myExamen?.refraction.acuitevisueldeloin.oielGauche.axe
    || this.myExamen?.refraction.acuitevisueldeloin.oeilDirecteur
    || this.myExamen?.refraction.acuitevisueldepres.oielDroit.avsc || this.myExamen?.refraction.acuitevisueldepres.oielGauche.avsc
    || this.myExamen?.refraction.acuitevisueldepres.oielDroit.avac || this.myExamen?.refraction.acuitevisueldepres.oielGauche.avac
    || this.myExamen?.refraction.acuitevisueldepres.oielDroit.add || this.myExamen?.refraction.acuitevisueldepres.oielGauche.add
    || this.myExamen?.refraction.acuitevisueldepres.oielDroit.sphere || this.myExamen?.refraction.acuitevisueldepres.oielDroit.cylindre || this.myExamen?.refraction.acuitevisueldepres.oielDroit.axe
    || this.myExamen?.refraction.acuitevisueldepres.oielGauche.sphere || this.myExamen?.refraction.acuitevisueldepres.oielGauche.cylindre || this.myExamen?.refraction.acuitevisueldepres.oielGauche.axe
    || this.myExamen?.refraction.acuitevisueldepres.oeilDirecteur
  }
}
