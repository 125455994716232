export const conversionTable =
    [{ "sphLunette": "-4,00", "SphLentille": "-3,75 " },
    { "sphLunette": "-4,25", "SphLentille": "-4,00 " },
    { "sphLunette": "-4,50", "SphLentille": "-4,25 " },
    { "sphLunette": "-4,75", "SphLentille": "-4,50 " },
    { "sphLunette": "-5,00", "SphLentille": "-4,75 " },
    { "sphLunette": "-5,25", "SphLentille": "-5,00 " },
    { "sphLunette": "-5,50", "SphLentille": "-5,25 " },
    { "sphLunette": "-5,75", "SphLentille": "-5,50 " },
    { "sphLunette": "-6,00", "SphLentille": "-5,50" },
    { "sphLunette": "-6,25", "SphLentille": "-5,75" },
    { "sphLunette": "-6,50", "SphLentille": "-6,00" },
    { "sphLunette": "-6,75", "SphLentille": "-6,25" },
    { "sphLunette": "-7,00", "SphLentille": "-6,50" },
    { "sphLunette": "-7,25", "SphLentille": "-6,75" },
    { "sphLunette": "-7,50", "SphLentille": "-7,00" },
    { "sphLunette": "-7,75", "SphLentille": "-7,00" },
    { "sphLunette": "-8,00", "SphLentille": "-7,25" },
    { "sphLunette": "-8,25", "SphLentille": "-7,50" },
    { "sphLunette": "-8,50", "SphLentille": "-7,75" },
    { "sphLunette": "-8,75", "SphLentille": "-8,00" },
    { "sphLunette": "-9,00", "SphLentille": "-8,00" },
    { "sphLunette": "-9,25", "SphLentille": "-8,25" },
    { "sphLunette": "-9,50", "SphLentille": "-8,50" },
    { "sphLunette": "-9,75", "SphLentille": "-8,75" },
    { "sphLunette": "-10,00", "SphLentille": "-9,00" },
    { "sphLunette": "-10,25", "SphLentille": "-9,25" },
    { "sphLunette": "-10,50", "SphLentille": "-9,25" },
    { "sphLunette": "-10,75", "SphLentille": "-9,50" },
    { "sphLunette": "-11,00", "SphLentille": "-9,75" },
    { "sphLunette": "-11,25", "SphLentille": "-10,00" },
    { "sphLunette": "-11,50", "SphLentille": "-10,00" },
    { "sphLunette": "-11,75", "SphLentille": "-10,25" },
    { "sphLunette": "-12,00", "SphLentille": "-10,50" },
    { "sphLunette": "-12,25", "SphLentille": "-10,75" },
    { "sphLunette": "-12,50", "SphLentille": "-10,75" },
    { "sphLunette": "-12,75", "SphLentille": "-11,00" },
    { "sphLunette": "-13,00", "SphLentille": "-11,25" },
    { "sphLunette": "-13,25", "SphLentille": "-11,50" },
    { "sphLunette": "-13,50", "SphLentille": "-11,50" },
    { "sphLunette": "-13,75", "SphLentille": "-11,75" },
    { "sphLunette": "-14,00", "SphLentille": "-12,00" },
    { "sphLunette": "-14,25", "SphLentille": "-12,25" },
    { "sphLunette": "-14,50", "SphLentille": "-12,25" },
    { "sphLunette": "-14,75", "SphLentille": "-12,50" },
    { "sphLunette": "-15,00", "SphLentille": "-12,75" },
    { "sphLunette": "-15,25", "SphLentille": "-13,00" },
    { "sphLunette": "-15,50", "SphLentille": "-13,00" },
    { "sphLunette": "-15,75", "SphLentille": "-13,25" },
    { "sphLunette": "-16,00", "SphLentille": "-13,50" },
    { "sphLunette": "-16,25", "SphLentille": "-13,50" },
    { "sphLunette": "-16,50", "SphLentille": "-13,75" },
    { "sphLunette": "-16,75", "SphLentille": "-14,00" },
    { "sphLunette": "-17,00", "SphLentille": "-14,00" },
    { "sphLunette": "-17,25", "SphLentille": "-14,25" },
    { "sphLunette": "-17,50", "SphLentille": "-14,50" },
    { "sphLunette": "-17,75", "SphLentille": "-14,75" },
    { "sphLunette": "-18,00", "SphLentille": "-14,75" },
    { "sphLunette": "-18,25", "SphLentille": "-15,00" },
    { "sphLunette": "-18,50", "SphLentille": "-15,25" },
    { "sphLunette": "-18,75", "SphLentille": "-15,25" },
    { "sphLunette": "-19,00", "SphLentille": "-15,50" },
    { "sphLunette": "-19,25", "SphLentille": "-15,75" },
    { "sphLunette": "-19,50", "SphLentille": "-15,75" },
    { "sphLunette": "-19,75", "SphLentille": "-16,00" },
    { "sphLunette": "-20,00", "SphLentille": "-16,25" },
    { "sphLunette": "-20,25", "SphLentille": "-16,25" },
    { "sphLunette": "-20,50", "SphLentille": "-16,50" },
    { "sphLunette": "-20,75", "SphLentille": "-16,50" },
    { "sphLunette": "-21,00", "SphLentille": "-16,75" },
    { "sphLunette": "-21,25", "SphLentille": "-17,00" },
    { "sphLunette": "-21,50", "SphLentille": "-17,00" },
    { "sphLunette": "-21,75", "SphLentille": "-17,25" },
    { "sphLunette": "-22,00", "SphLentille": "-17,50" },
    { "sphLunette": "-22,25", "SphLentille": "-17,50" },
    { "sphLunette": "-22,50", "SphLentille": "-17,75" },
    { "sphLunette": "-22,75", "SphLentille": "-17,75" },
    { "sphLunette": "-23,00", "SphLentille": "-18,00" },
    { "sphLunette": "-23,25", "SphLentille": "-18,25" },
    { "sphLunette": "-23,50", "SphLentille": "-18,25" },
    { "sphLunette": "-23,75", "SphLentille": "-18,50" },
    { "sphLunette": "-24,00", "SphLentille": "-18,75" },
    { "sphLunette": "-24,25", "SphLentille": "-18,75" },
    { "sphLunette": "-24,50", "SphLentille": "-19,00" },
    { "sphLunette": "-24,75", "SphLentille": "-19,00" },
    { "sphLunette": "-25,00", "SphLentille": "-19,25" },
    { "sphLunette": "-25,25", "SphLentille": "-19,50" },
    { "sphLunette": "-25,50", "SphLentille": "-19,50" },
    { "sphLunette": "-25,75", "SphLentille": "-19,75" },
    { "sphLunette": "-26,00", "SphLentille": "-19,75" },
    { "sphLunette": "-26,25", "SphLentille": "-20,00" },
    { "sphLunette": "-26,50", "SphLentille": "-20,00" },
    { "sphLunette": "+4,00", "SphLentille": "+4,25" },
    { "sphLunette": "+4,25", "SphLentille": "+4,50" },
    { "sphLunette": "+4,50", "SphLentille": "+4,75" },
    { "sphLunette": "+4,75", "SphLentille": "+5,00" },
    { "sphLunette": "+5,00", "SphLentille": "+5,25" },
    { "sphLunette": "+5,25", "SphLentille": "+5,50" },
    { "sphLunette": "+5,50", "SphLentille": "+6,00" },
    { "sphLunette": "+5,75", "SphLentille": "+6,25" },
    { "sphLunette": "+6,00", "SphLentille": "+6,50" },
    { "sphLunette": "+6,25", "SphLentille": "+6,75" },
    { "sphLunette": "+6,50", "SphLentille": "+7,00" },
    { "sphLunette": "+6,75", "SphLentille": "+7,25" },
    { "sphLunette": "+7,00", "SphLentille": "+7,75" },
    { "sphLunette": "+7,25", "SphLentille": "+8,00" },
    { "sphLunette": "+7,50", "SphLentille": "+8,25" },
    { "sphLunette": "+7,75", "SphLentille": "+8,50" },
    { "sphLunette": "+8,00", "SphLentille": "+8,75" },
    { "sphLunette": "+8,25", "SphLentille": "+9,25" },
    { "sphLunette": "+8,50", "SphLentille": "+9,50" },
    { "sphLunette": "+8,75", "SphLentille": "+9,75" },
    { "sphLunette": "+9,00", "SphLentille": "+10,00" },
    { "sphLunette": "+9,25", "SphLentille": "+10,50" },
    { "sphLunette": "+9,50", "SphLentille": "+10,75" },
    { "sphLunette": "+9,75", "SphLentille": "+11,00" },
    { "sphLunette": "+10,00", "SphLentille": "+11,25" },
    { "sphLunette": "+10,25", "SphLentille": "+11,75" },
    { "sphLunette": "+10,50", "SphLentille": "+12,00" },
    { "sphLunette": "+10,75", "SphLentille": "+12,25" },
    { "sphLunette": "+11,00", "SphLentille": "+12,75" },
    { "sphLunette": "+11,25", "SphLentille": "+13,00" },
    { "sphLunette": "+11,50", "SphLentille": "+13,25" },
    { "sphLunette": "+11,75", "SphLentille": "+13,75" },
    { "sphLunette": "+12,00", "SphLentille": "+14,00" },
    { "sphLunette": "+12,25", "SphLentille": "+14,25" },
    { "sphLunette": "+12,50", "SphLentille": "+14,75" },
    { "sphLunette": "+12,75", "SphLentille": "+15,00" },
    { "sphLunette": "+13,00", "SphLentille": "+15,50" },
    { "sphLunette": "+13,25", "SphLentille": "+15,75" },
    { "sphLunette": "+13,50", "SphLentille": "+16,00" },
    { "sphLunette": "+13,75", "SphLentille": "+16,50" },
    { "sphLunette": "+14,00", "SphLentille": "+16,75" },
    { "sphLunette": "+14,25", "SphLentille": "+17,25" },
    { "sphLunette": "+14,50", "SphLentille": "+17,50" },
    { "sphLunette": "+14,75", "SphLentille": "+18,00" },
    { "sphLunette": "+15,00", "SphLentille": "+18,25" },
    { "sphLunette": "+15,25", "SphLentille": "+18,75" },
    { "sphLunette": "+15,50", "SphLentille": "+19,00" },
    { "sphLunette": "+15,75", "SphLentille": "+19,50" },
    { "sphLunette": "+16,00", "SphLentille": "+19,75" },
    ]
