import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';


@Injectable(
    { providedIn: 'root' }
)
export class MedicalService {


    constructor(private http: HttpClient) { }

    ajoutMedical(medical) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.post(_getURL(API_CONFIG.medical), medical, { headers: headers })
    }
    getMedicalByDoctor() {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.get<any>(_getURL(API_CONFIG.medical) + "/get-medicals-by-doctor", { headers: headers });
    }
    deleteMedical(id) {
        const headers = new HttpHeaders({
            'Authorization': JSON.parse(localStorage.getItem('id_token')),
            'Content-Type': 'application/json'
        });
        return this.http.delete<any>(_getURL(API_CONFIG.medical) + "/delete-medical/" + id, { headers: headers });
    }
}