import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-interrogatoire-patient',
  templateUrl: './interrogatoire-patient.component.html',
  styleUrls: ['./interrogatoire-patient.component.css', '../list-patients.component.css']
})
export class InterrogatoirePatientComponent implements OnInit {
  @Input() myExamen: any;

  constructor() { }
  
  ngOnInit() { }

 checkExistingAnomalieInterrogatoire() : boolean {
  return this.myExamen?.interrogation && this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels && (this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.medicaux.length>0 
    ||  this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours.length>0
    ||  this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name
    ||  this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux.length>0
    || this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length>0 
    || this.myExamen?.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length>0
    || this.myExamen?.interrogation.antecedentsGeneraux.oielDroit && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.grossesseenCours && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.grossesseenCours.present
    || this.myExamen?.interrogation.antecedentsGeneraux.oielDroit && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainatopique && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainatopique.present
    || this.myExamen?.interrogation.antecedentsGeneraux.oielDroit && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainvasculaireparticulier && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainvasculaireparticulier.present
    || this.myExamen?.interrogation.antecedentsGeneraux.oielDroit && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainimmunodepression && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.terrainimmunodepression.present
    || this.myExamen?.interrogation.antecedentsGeneraux.oielDroit && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.maladiedysimunitaire && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.maladiedysimunitaire.present
    || this.myExamen?.interrogation.antecedentsGeneraux.oielDroit && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.maladieneurologique && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.maladieneurologique.present
    || this.myExamen?.interrogation.antecedentsGeneraux.oielDroit && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionvaccinationrecente && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionvaccinationrecente.present
    || this.myExamen?.interrogation.antecedentsGeneraux.oielDroit && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionanesthesierecente && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.notionanesthesierecente.present
    || this.myExamen?.interrogation.antecedentsGeneraux.oielDroit && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.traitementencours && this.myExamen?.interrogation.antecedentsGeneraux.oielDroit.traitementencours.present
    || this.myExamen?.interrogation.antecedentsFamiliauxOphtalmologique && this.myExamen?.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht && this.myExamen?.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht.length >0)
 }
}
