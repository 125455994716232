<div class="container demo">
    <div class="text-center" style="display: none;">
        <button type="button" class="btn btn-siderbar" id="btn-siderbar" data-toggle="modal" data-target="#exampleModal">
            Lancer
        </button>
    </div>
    <div class="modal left fade" id="exampleModal" tabindex="" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <h3 class="titre-historique"> Historiques</h3>
                    <div *ngIf="historiquesConsultations.length==0">
                        <p class="text-center">Aucune date n'est trouvée</p>
                    </div>
                    <div *ngFor="let examen of historiquesConsultations,let i=index" [ngClass]="{'selectedExamen':selectedExamenIndex === i}">
                        <ul>
                            <li> <a class="dates " (click)="showDataInfo(i)">{{examen.start | date: 'dd/MM/yyyy , HH:mm'}}</a></li>
                        </ul>
                    </div>


                    <div *ngIf="selectedExamenIndex !== null" id="examenDetails">
                        <hr>
                        <!--! Anomalie à l'interrogatoire  -->
                        <div class="row mt-2">
                            <div>
                                <div *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.medicaux?.length>0 
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux?.length>0 
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours?.length>0  
                                    || historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique?.length>0 
                                    || historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique?.length>0 
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.grossesseenCours.present==true 
                                    || historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainatopique.present ==true
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present==true
                                    || historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.maladiedysimunitaire.present ==true
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.maladieneurologique.present==true
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.notionvaccinationrecente.present ==true
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.notionanesthesierecente.present ==true
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.traitementencours.present ==true
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.antecedentByMedecin.length>0
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht?.length>0 
                                    ||historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie?.length>0">
                                    <h6 class="subTitle">Anomalie à l'interrogatoire</h6>
                                    <div class="row">
                                        <!-- Médicaux -->
                                        <div>
                                            <div *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.medicaux?.length > 0">
                                                <div class="row mt-2">

                                                    <!-- Check for oeilDroit or both eyes -->
                                                    <div class="col-md-4 codageElements">
                                                        <ng-container *ngIf="hasMedicalOeilDroit === false">None</ng-container>
                                                        <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.medicaux">
                                                            <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                                                                {{ value.name }}
                                                            </ng-container>
                                                        </p>
                                                    </div>

                                                    <div class="col-md-4 titleConclusionCenter">
                                                        <h6>Médicaux</h6>
                                                    </div>

                                                    <!-- Check for oeilGauche or both eyes -->
                                                    <div class="col-md-4 codageElements">
                                                        <ng-container *ngIf="hasMedicalOeilGauche === false">None</ng-container>
                                                        <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.medicaux">
                                                            <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                                                                {{ value.name }}
                                                            </ng-container>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!--Antécédents de traitement par laser  -->
                                        <div *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name ">
                                            <div class="row mt-4">

                                                <!-- Check for oeilDroit or both eyes -->
                                                <div class="col-md-4 codageElements">
                                                    <ng-container *ngIf="hasTraitementLaserOeilDroit === false">None</ng-container>
                                                    <ng-container *ngIf="hasTraitementLaserOeilDroit === true">
                                                        {{ historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name }}
                                                    </ng-container>
                                                </div>

                                                <div class="col-md-4 titleConclusionCenter">
                                                    <h6>Antécédents de traitement par laser
                                                    </h6>
                                                </div>

                                                <!-- Check for oeilGauche or both eyes -->
                                                <div class="col-md-4 codageElements">
                                                    <ng-container *ngIf="hasTraitementLaserOeilGauche === false">None</ng-container>
                                                    <ng-container *ngIf="hasTraitementLaserOeilGauche === true">
                                                        {{ historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.name }}
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>

                                        <!--traitement En Cours  -->
                                        <div *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours?.length > 0">
                                            <div class="row mt-4">

                                                <!-- Check for oeilDroit or both eyes -->
                                                <div class="col-md-4 codageElements">
                                                    <ng-container *ngIf="hasTraitementOeilDroit === false">None</ng-container>
                                                    <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours">
                                                        <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                                                            {{ value.name }}
                                                        </ng-container>
                                                    </p>
                                                </div>

                                                <div class="col-md-4 titleConclusionCenter">
                                                    <h6>Traitement En Cours</h6>
                                                </div>

                                                <!-- Check for oeilGauche or both eyes -->
                                                <div class="col-md-4 codageElements">
                                                    <ng-container *ngIf="hasTraitementOeilGauche === false">None</ng-container>
                                                    <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours">
                                                        <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                                                            {{ value.name }}
                                                        </ng-container>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <!--Chirurgicaux  -->
                                        <div *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux?.length > 0">
                                            <div class="row mt-4">

                                                <!-- Check for oeilDroit or both eyes -->
                                                <div class="col-md-4 codageElements">
                                                    <ng-container *ngIf="hasChirugieOeilDroit === false">None</ng-container>
                                                    <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux">
                                                        <ng-container *ngIf="value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux'">
                                                            {{ value.name }}
                                                        </ng-container>
                                                    </p>
                                                </div>

                                                <div class="col-md-4 titleConclusionCenter">
                                                    <h6>Chirurgicaux</h6>
                                                </div>

                                                <!-- Check for oeilGauche or both eyes -->
                                                <div class="col-md-4 codageElements">
                                                    <ng-container *ngIf="hasChirugieOeilGauche === false">None</ng-container>
                                                    <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux">
                                                        <ng-container *ngIf="value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux'">
                                                            {{ value.name }}
                                                        </ng-container>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <!--Correction optique  -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length>0 || historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length>0">
                                            <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length==0">
                                                <p>None</p>
                                            </div>
                                            <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique.length>0">
                                                <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.oielDroit.correctionoptique">{{value.name}}</p>
                                            </div>
                                            <div class="col-md-4 titleConclusionCenter">
                                                <h6>Correction optique</h6>
                                            </div>
                                            <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length==0">
                                                <p>None</p>
                                            </div>
                                            <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique.length>0">
                                                <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsophtalmologiquepersonnels.oielGauche.correctionoptique">{{value.name}}</p>
                                            </div>
                                        </div>

                                        <!--Grossesse en cours  -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux  && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.grossesseenCours.present">
                                            <div class="col-md-4 text-center"></div>
                                            <div class="col-md-4 titleConclusionCenter">
                                                <h6>Grossesse en cours </h6>
                                            </div>
                                            <div class="col-md-4 text-center"></div>
                                        </div>
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux  &&historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.antecedentByMedecin.length>0">
                                            <div class="col-md-4 text-center"></div>
                                            <div class="col-md-4 titleConclusionCenter" *ngFor="let ant of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.antecedentByMedecin">
                                                <h6>{{ant.name}}</h6>
                                            </div>
                                            <div class="col-md-4 text-center"></div>
                                        </div>
                                        <!-- Terrain Atopique -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux  && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainatopique.present">
                                            <div class="col-md-4 text-center"></div>
                                            <div class="col-md-4 titleConclusionCenter">
                                                <h6>Terrain Atopique</h6>
                                            </div>
                                            <div class="col-md-4 text-center"></div>
                                            <div class="d-flex">
                                                <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainatopique.valeurs">{{value.name}}</p>
                                            </div>
                                        </div>
                                        <!-- Terrain vasculaire particulier -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.present">
                                            <div class="col-md-4 text-center"></div>
                                            <div class="col-md-4 titleConclusionCenter">
                                                <h6>Terrain vasculaire particulier</h6>
                                            </div>
                                            <div class="col-md-4 text-center"></div>
                                            <div class="d-flex">
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.diabete.present">Diabète</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.hta.present">HTA</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainvasculaireparticulier.terrainvasculaireparticulierautre.present">Autre</p>
                                            </div>
                                        </div>
                                        <!-- Terrain d'immunodépression -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainimmunodepression && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainimmunodepression.present">
                                            <div class="col-md-4 text-center"></div>
                                            <div class="col-md-4 titleConclusionCenter">
                                                <h6>Terrain d'immunodépression</h6>
                                            </div>
                                            <div class="col-md-4 text-center"></div>
                                            <ul class="d-flex">
                                                <li *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.terrainimmunodepression.valeurs">{{value.name}}</li>
                                            </ul>
                                        </div>
                                        <!-- Maladie dysimmunitaire -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.maladiedysimunitaire && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.maladiedysimunitaire.present">
                                            <h6 class="text-center"><b>Maladie dysimmunitaire</b></h6>
                                            <div class="d-flex">
                                                <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.maladiedysimunitaire.valeurs">{{value.name}}</p>
                                            </div>
                                        </div>
                                        <!-- Maladie neurologique -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.maladieneurologique && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.maladieneurologique.present">
                                            <h6 class="text-center"><b>Maladie neurologique</b></h6>
                                            <div class="d-flex">
                                                <p *ngFor="let value of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.maladieneurologique.valeurs">{{value.name}}</p>
                                            </div>
                                        </div>
                                        <!-- Notion de vaccination récente -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.notionvaccinationrecente && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.notionvaccinationrecente.present">
                                            <h6 class="text-center"><b>Notion de vaccination récente</b></h6>
                                            <div class="d-flex">
                                                <p>Type : {{historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.notionvaccinationrecente.type}}</p>
                                                <p>Date : {{historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.notionvaccinationrecente.date}}</p>
                                            </div>
                                        </div>
                                        <!-- Notion d'anesthésie récente -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.notionanesthesierecente && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.notionanesthesierecente.present">
                                            <h6 class="text-center"><b>Notion d'anesthésie récente</b></h6>
                                            <div class="d-flex">
                                                <p>Type : {{historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.notionanesthesierecente.type}}</p>
                                                <p>Date : {{historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.notionanesthesierecente.date}}</p>
                                            </div>
                                        </div>
                                        <!--Traitement général en cours  -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.traitementencours && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.traitementencours.present">
                                            <h6 class="text-center"><b>Traitement général en cours</b></h6>
                                            <div class="d-flex">
                                                <p>Type : {{historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.traitementencours.type}}</p>
                                                <p>Dose : {{historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsGeneraux.traitementencours.dose}}</p>
                                            </div>
                                        </div>
                                        <!-- Type des antécédents familiaux -->
                                        <div class="row mt-4" *ngIf=" historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsFamiliauxOphtalmologique && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht?.length>0 ">
                                            <div class="col-md-4 text-center"></div>
                                            <div class="col-md-4 titleConclusionCenter">
                                                <h6>Type des antécédents familiaux</h6>
                                            </div>
                                            <div class="col-md-4 text-center"></div>
                                            <div class="d-flex ">
                                                <p *ngFor="let antecedent of historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsFamiliauxOphtalmologique.listtypeAnteFamiOpht">Type : {{antecedent.name}}</p>
                                            </div>
                                        </div>
                                        <!-- Habitudes de vie -->
                                        <div class="row mt-4" *ngIf="historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsFamiliauxOphtalmologique && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie?.length>0 ">
                                            <div class="col-md-4 text-center"></div>
                                            <div class="col-md-4 titleConclusionCenter">
                                                <h6>Habitudes de vie</h6>
                                            </div>
                                            <div class="col-md-4 text-center"></div>

                                            <div class="d-flex ">
                                                <p *ngFor="let antecedent of historiquesConsultations[selectedExamenIndex]?.interrogation && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsFamiliauxOphtalmologique && historiquesConsultations[selectedExamenIndex]?.interrogation.antecedentsFamiliauxOphtalmologique.listhabitudeDeVie">{{antecedent.name}}</p>
                                            </div>
                                        </div>

                                    </div>
                                    <h6 class="subTitle mt-4">Anomalie à l’examen</h6>
                                </div>
                            </div>
                        </div>
                        <!--! end of Anomalie à l'interrogatoire  -->
                        <!-- !examen conclusion -->
                        <div>

                            <!-- Inspection -->
                            <div class="row mt-3">
                                <!-- Oeil Droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection && historiquesConsultations[selectedExamenIndex]?.inspection.anomaly && historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.present">

                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.normale.present">Normale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.attitudedemalvoyance.present">Attitude de malvoyance</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.buphtalmie.present">Buphtalmie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.exphtalmie.present">Exphtalmie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.enophtalmie.present">Énophtalmie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.microphtalmie.present">Microphtalmie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.leucocorie.present">Leucocorie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.protheseoculaire.present">Prothèse oculaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.plaies.present">Plaies</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.paralysiefaciale.present">Paralysie faciale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.ptosis.present">Ptosis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.strabisme.present">Strabisme</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielDroit.allAnomalies">{{anomaly.titre}}</p>

                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Inspection</h6>
                                </div>
                                <!-- Oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection && historiquesConsultations[selectedExamenIndex]?.inspection.anomaly && historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.present">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.normale.present">Normale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.attitudedemalvoyance.present">Attitude de malvoyance</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.buphtalmie.present">Buphtalmie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.exphtalmie.present">Exphtalmie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.enophtalmie.present">Énophtalmie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.microphtalmie.present">Microphtalmie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.leucocorie.present">Leucocorie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.protheseoculaire.present">Prothèse oculaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.plaies.present">Plaies</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.paralysiefaciale.present">Paralysie faciale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.ptosis.present">Ptosis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.strabisme.present">Strabisme</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.inspection.anomaly.oielGauche.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end Inspection -->

                            <!-- Réfraction sans cycloplégie -->
                            <div class="row mt-3">
                                <div class="col-md-4">
                                    <div id="suboeilTitreSection">
                                        <div class="Sphère blocCyclop">
                                            <input type="text" value="Sph : &nbsp;{{ historiquesConsultations[selectedExamenIndex]?.refraction.sansCycloplegie.oielDroit.sphere}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="cylindre blocCyclop">

                                            <input type="text" value="Cyl : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.sansCycloplegie.oielDroit.cylindre}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="axe blocCyclop">
                                            <input type="text" value="Axe : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.sansCycloplegie.oielDroit.axe}}" class="form-control input-sm-new" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Réfraction sans cycloplégie</h6>
                                </div>
                                <div class="col-md-4">
                                    <div id="suboeilTitreSection">
                                        <div class="Sphère blocCyclop">
                                            <input type="text" value="Sph : &nbsp;{{ historiquesConsultations[selectedExamenIndex]?.refraction.sansCycloplegie.oielGauche.sphere}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="cylindre blocCyclop">

                                            <input type="text" value="Cyl : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.sansCycloplegie.oielGauche.cylindre}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="axe blocCyclop">
                                            <input type="text" value="Axe : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.sansCycloplegie.oielGauche.axe}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end Réfraction sans cycloplégie -->

                            <!-- Réfraction avec cycloplégie -->
                            <div class="row mt-3">
                                <div class="col-md-4">
                                    <div id="suboeilTitreSection">
                                        <div class="Sphère blocCyclop">
                                            <input type="text" value="Sph : &nbsp;{{ historiquesConsultations[selectedExamenIndex]?.refraction.avecCycloplegie.oielDroit.sphere}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="cylindre blocCyclop">

                                            <input type="text" value="Cyl : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.avecCycloplegie.oielDroit.cylindre}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="axe blocCyclop">
                                            <input type="text" value="Axe : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.avecCycloplegie.oielDroit.axe}}" class="form-control input-sm-new" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Réfraction avec cycloplégie</h6>
                                </div>
                                <div class="col-md-4">
                                    <div id="suboeilTitreSection">
                                        <div class="Sphère blocCyclop">
                                            <input type="text" value="Sph : &nbsp;{{ historiquesConsultations[selectedExamenIndex]?.refraction.avecCycloplegie.oielGauche.sphere}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="cylindre blocCyclop">

                                            <input type="text" value="Cyl : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.avecCycloplegie.oielGauche.cylindre}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="axe blocCyclop">
                                            <input type="text" value="Axe : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.avecCycloplegie.oielGauche.axe}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end Réfraction avec cycloplégie -->



                            <!-- Annexes -->
                            <div class="row mt-3">
                                <!-- Oeil Droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe && historiquesConsultations[selectedExamenIndex]?.annexe.anomaly && historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.present">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.normale.present">Normale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.chalazion.present">Chalazion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.dca.present">DCA</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.dcc.present">DCC</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.dermabrasion.present">Dermabrasion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.diplopie.present">Diplopie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.distichoasis.present">Distichoasis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.dgm.present">DGM</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.ecoulementlacrymal.present">Écoulement lacrymal</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.entropion.present">Entropion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.ectropion.present">Ectropion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.fistule.present">Fistule</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.insuffisancedeconvergence.present">Insuffisance de convergence</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.massedelalogelacrymal.present">Masse de la loge lacrymal</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.meeibomite.present">Meeibomite</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.ophtalmologie.present">Ophtalmologie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.orgelet.present">Orgelet</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.paralysieocculomotrice.present">Paralysie occulomotrice</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.plaie.present">Plaie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.presencedestrabisme.present">Présence de strabisme</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.ptosis.present">Ptosis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.refletcorneendecentre.present">Réflet cornéen décentré</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.stenoselacrymal.present">Sténose lacrymal</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.sondedeDCRenplace.present">Sonde de DCR en place</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.telangiectasie.present">Telangiectasie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.tumeurpaplepbrale.present">Tumeur paplepbrale</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielDroit.allAnomapes">{{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Annexes</h6>
                                </div>
                                <!-- Oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe && historiquesConsultations[selectedExamenIndex]?.annexe.anomaly && historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.present">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.normale.present">Normale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.chalazion.present">Chalazion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.dca.present">DCA</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.dcc.present">DCC</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.dermabrasion.present">Dermabrasion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.diplopie.present">Diplopie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.distichoasis.present">Distichoasis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.dgm.present">DGM</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.ecoulementlacrymal.present">Écoulement lacrymal</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.entropion.present">Entropion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.ectropion.present">Ectropion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.fistule.present">Fistule</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.insuffisancedeconvergence.present">Insuffisance de convergence</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.massedelalogelacrymal.present">Masse de la loge lacrymal</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.meeibomite.present">Meeibomite</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.ophtalmologie.present">Ophtalmologie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.orgelet.present">Orgelet</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.paralysieocculomotrice.present">Paralysie occulomotrice</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.plaie.present">Plaie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.presencedestrabisme.present">Présence de strabisme</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.ptosis.present">Ptosis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.refletcorneendecentre.present">Réflet cornéen décentré</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.stenoselacrymal.present">Sténose lacrymal</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.sondedeDCRenplace.present">Sonde de DCR en place</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.telangiectasie.present">Telangiectasie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.tumeurpaplepbrale.present">Tumeur paplepbrale</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.annexe.anomaly.oielGauche.allAnomapes">{{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end Annexes -->

                            <!-- AVSC de loin -->
                            <div class="row mt-3">
                                <div class="col-md-4 text-center">
                                    <p>{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldeloin.oielDroit.avsc}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>AVSC de loin</h6>
                                </div>
                                <div class="col-md-4 text-center">
                                    <p>{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldeloin.oielGauche.avsc}}</p>
                                </div>
                            </div>
                            <!-- end of AVSC de loin -->

                            <!-- AVAC de loin -->
                            <div class="row mt-3">
                                <div class="col-md-4 text-center">
                                    <p>{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldeloin.oielDroit.avac}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>AVAC de loin</h6>
                                </div>
                                <div class="col-md-4 text-center">
                                    <p>{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldeloin.oielGauche.avac}}</p>
                                </div>
                            </div>
                            <!-- end of AVAC de loin -->

                            <!-- Réfraction finale de loin -->
                            <div class="row mt-3">
                                <div class="col-md-4 text-center">
                                    <div id="suboeilTitreSection">
                                        <div class="Sphère blocCyclop">
                                            <input type="text" value="Sph : &nbsp;{{ historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldeloin.oielDroit.sphere}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="cylindre blocCyclop">

                                            <input type="text" value="Cyl : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldeloin.oielDroit.cylindre}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="axe blocCyclop">
                                            <input type="text" value="Axe : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldeloin.oielDroit.axe}}" class="form-control input-sm-new" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Réfraction finale de loin</h6>
                                </div>
                                <div class="col-md-4 text-center">
                                    <div id="suboeilTitreSection">
                                        <div class="Sphère blocCyclop">
                                            <input type="text" value="Sph : &nbsp;{{ historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldeloin.oielGauche.sphere}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="cylindre blocCyclop">

                                            <input type="text" value="Cyl : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldeloin.oielGauche.cylindre}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="axe blocCyclop">
                                            <input type="text" value="Axe : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldeloin.oielGauche.axe}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end of Réfraction finale de loin -->

                            <!-- AVSC de près -->
                            <div class="row mt-3">
                                <div class="col-md-4 text-center">
                                    <p>{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldepres.oielDroit.avsc}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>AVSC de près</h6>
                                </div>
                                <div class="col-md-4 text-center">
                                    <p>{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldepres.oielGauche.avsc}}</p>
                                </div>
                            </div>
                            <!-- end of AVSC de près -->

                            <!-- AVAC de près -->
                            <div class="row mt-3">
                                <div class="col-md-4 text-center">
                                    <p>{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldepres.oielDroit.avac}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>AVAC de près</h6>
                                </div>
                                <div class="col-md-4 text-center">
                                    <p>{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldepres.oielGauche.avac}}</p>
                                </div>
                            </div>
                            <!-- end of AVAC de près -->

                            <!-- Réfraction finale de près -->
                            <div class="row mt-3">
                                <div class="col-md-4 text-center">
                                    <div id="suboeilTitreSection">
                                        <div class="Sphère blocCyclop">
                                            <input type="text" value="Sph : &nbsp;{{ historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldepres.oielDroit.sphere}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="cylindre blocCyclop">

                                            <input type="text" value="Cyl : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldepres.oielDroit.cylindre}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="axe blocCyclop">
                                            <input type="text" value="Axe : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldepres.oielDroit.axe}}" class="form-control input-sm-new" disabled />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Réfraction finale de près</h6>
                                </div>
                                <div class="col-md-4 text-center">
                                    <div id="suboeilTitreSection">
                                        <div class="Sphère blocCyclop">
                                            <input type="text" value="Sph : &nbsp;{{ historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldepres.oielGauche.sphere}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="cylindre blocCyclop">

                                            <input type="text" value="Cyl : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldepres.oielGauche.cylindre}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                        <div class="axe blocCyclop">
                                            <input type="text" value="Axe : &nbsp;{{historiquesConsultations[selectedExamenIndex]?.refraction.acuitevisueldepres.oielGauche.axe}}" class=" form-control input-sm-new" disabled />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- end of Réfraction finale de près -->

                            <!-- Sclére -->
                            <div class="row mt-3">
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.sclere">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielDroit.blancheEtIntacte.present"> Blanche et intacte </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielDroit.episclerite.present"> Episclérite</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielDroit.plaie.present"> Plaie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielDroit.sclerite.present"> Sclérite</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielDroit.scleromalacie.present"> Scléromalacie</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielDroit.allAnomapes"> {{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Sclére</h6>
                                </div>
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.sclere">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielGauche.blancheEtIntacte.present"> Blanche et intacte </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielGauche.episclerite.present"> Episclérite</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielGauche.plaie.present"> Plaie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielGauche.sclerite.present"> Sclérite</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielGauche.scleromalacie.present"> Scléromalacie</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.sclere.oielGauche.allAnomapes"> {{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end of Sclére -->

                            <!-- Conjonctive -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.conj">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.normocoloreEtIntacte.present">Normocolore et intacte </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.ankyloblepharon.present"> Ankyloblepharon</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.cercleperikeratique.present"> Cercle périkératique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.Chemosis.present">Chémosis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.faussemembrane.present"> Fausse membrane</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.fibroseconjonctival.present">Fibrose conjonctival</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.follicule.present">Follicule</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.granulomeconjonctivale.present">Granulome conjonctivale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.hemorragiesousconjonctival.present">Hémorragie sous conjonctivale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.hyperhemieconj.present">Hyperhémie conjonctivale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.icterique.present">Ictérique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.micropapille.present ">Micro-papille</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.macropapille.present "> Macro-papille</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.papillegeante.present "> Papille géante</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.Pemphigoide.present">( Conj) Pemphigoïde</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.plaie.present ">Plaie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.ptgStade.present ">PTG(stade)</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.secretion.present ">Sécrétion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.symblepharon.present "> Symblépharon</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.tumeurconjonctivale.present"> Tumeur conjonctivale</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.conj.oielDroit.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Conjonctive</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.conj">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.normocoloreEtIntacte.present">Normocolore et intacte </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.ankyloblepharon.present"> Ankyloblepharon</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.cercleperikeratique.present"> Cercle périkératique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.Chemosis.present">Chémosis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.faussemembrane.present"> Fausse membrane</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.fibroseconjonctival.present">Fibrose conjonctival</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.follicule.present">Follicule</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.granulomeconjonctivale.present">Granulome conjonctivale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.hemorragiesousconjonctival.present">Hémorragie sous conjonctivale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.hyperhemieconj.present">Hyperhémie conjonctivale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.icterique.present">Ictérique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.micropapille.present ">Micro-papille</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.macropapille.present "> Macro-papille</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.papillegeante.present "> Papille géante</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.Pemphigoide.present">( Conj) Pemphigoïde</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.plaie.present ">Plaie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.ptgStade.present ">PTG(stade)</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.secretion.present ">Sécrétion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.symblepharon.present "> Symblépharon</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.tumeurconjonctivale.present"> Tumeur conjonctivale</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.conj.oielGauche.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end of Conjonctive -->

                            <!-- Cornée -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.transparente.present==true"> Transparente</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.abces.present==true"> Abcès</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.anneuximmunitaire.present==true"> Anneux immunitaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.bueeEpitheliale.present==true"> Buée épithéliale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.bullesousepitheliale.present==true"> Bulle sous épithéliale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.buphtalmie.present==true"> Buphtalmie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.corneaguttata.present==true"> Cornea guttata </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.endothelite.present==true"> Endothélite</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.epaississementepithelialeStromale.present==true"> Épaississement épithéliale/stromale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.gerontoxon.present==true"> Gérontoxon</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.hematocornee.present==true"> Hématocornée</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.hydrops.present==true"> Hydrops</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.hypoesthesie.present==true"> Hypoesthésie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.hypoesthesiecorneenne.present==true"> Hypoesthésie cornéenne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.irregularitePlisdeladescemet.present==true"> Irrégularité/plis de la descemet </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.KB.present==true">KB</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.Keratopathieenbandelette.present==true"> Kératopathie en bandelette </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.KPS.present==true"> KPS</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.leucome.present==true"> Leucome</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.microkysteEpitheliaux.present==true"> Microkyste épithéliaux</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.microkystique.present==true"> Microkystique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.neovascularisationcorneenne.present==true"> Néovascularisation cornéenne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.NSE.present==true"> NSE</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.oedemecorneen.present==true"> Oedème cornéen</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.opaque.present==true"> Opaque</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.opacite.present==true"> Opacité</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.perforationcorneenne.present==true"> Perforation cornéenne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.plaiescorneenne.present==true"> Plaie cornéenne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.plaquevernale.present==true"> Plaque vernale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.pointcorneen.present==true"> Point cornéen</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.PRD.present==true"> PRD</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.striesdevogt.present==true"> Stries de vogt</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.ulcerationcorneenne.present==true"> Ulcération cornéenne </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.visibiliteanormaledesnerfscorneen.present==true"> Visibilitéanormale des nerfs cornéen</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.ulcerevernal.present==true"> Ulcère vernal</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.noduledetrantas.present==true"> Nodule de trantas</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielDroit.allAnomalies"> {{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Cornée</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.transparente.present==true"> Transparente</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.abces.present==true"> Abcès</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.anneuximmunitaire.present==true"> Anneux immunitaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.bueeEpitheliale.present==true"> Buée épithéliale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.bullesousepitheliale.present==true"> Bulle sous épithéliale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.buphtalmie.present==true"> Buphtalmie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.corneaguttata.present==true"> Cornea guttata </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.endothelite.present==true"> Endothélite</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.epaississementepithelialeStromale.present==true"> Épaississement épithéliale/stromale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.gerontoxon.present==true"> Gérontoxon</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.hematocornee.present==true"> Hématocornée</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.hydrops.present==true"> Hydrops</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.hypoesthesie.present==true"> Hypoesthésie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.hypoesthesiecorneenne.present==true"> Hypoesthésie cornéenne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.irregularitePlisdeladescemet.present==true"> Irrégularité/plis de la descemet </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.KB.present==true">KB</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.Keratopathieenbandelette.present==true"> Kératopathie en bandelette </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.KPS.present==true"> KPS</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.leucome.present==true"> Leucome</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.microkysteEpitheliaux.present==true"> Microkyste épithéliaux</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.microkystique.present==true"> Microkystique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.neovascularisationcorneenne.present==true"> Néovascularisation cornéenne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.NSE.present==true"> NSE</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.oedemecorneen.present==true"> Oedème cornéen</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.opaque.present==true"> Opaque</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.opacite.present==true"> Opacité</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.perforationcorneenne.present==true"> Perforation cornéenne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.plaiescorneenne.present==true"> Plaie cornéenne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.plaquevernale.present==true"> Plaque vernale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.pointcorneen.present==true"> Point cornéen</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.PRD.present==true"> PRD</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.striesdevogt.present==true"> Stries de vogt</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.ulcerationcorneenne.present==true"> Ulcération cornéenne </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.visibiliteanormaledesnerfscorneen.present==true"> Visibilitéanormale des nerfs cornéen</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.ulcerevernal.present==true"> Ulcère vernal</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.noduledetrantas.present==true"> Nodule de trantas</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.corneetransparente.oielGauche.allAnomalies"> {{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end of Cornée -->

                            <!-- Pachymétrie -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 text-center" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.pachymtrie">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.pachymtrie.oielDroit.value">{{historiquesConsultations[selectedExamenIndex]?.laf.pachymtrie.oielDroit.value}} μm </p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Pachymétrie</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 text-center" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.pachymtrie">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.pachymtrie.oielGauche.value">{{historiquesConsultations[selectedExamenIndex]?.laf.pachymtrie.oielGauche.value}} μm </p>
                                </div>
                            </div>
                            <!-- end of Pachymétrie -->

                            <!-- BUT -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 text-center" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.butShirmer">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.butShirmer.oielDroit.secondes">{{historiquesConsultations[selectedExamenIndex]?.laf.butShirmer.oielDroit.secondes}} s </p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>BUT</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 text-center" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.butShirmer">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.butShirmer.oielGauche.secondes">{{historiquesConsultations[selectedExamenIndex]?.laf.butShirmer.oielGauche.secondes}} s </p>
                                </div>
                            </div>
                            <!-- end of BUT -->

                            <!-- Schirmer -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 text-center" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.butShirmer">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.butShirmer.oielDroit.secondes">{{historiquesConsultations[selectedExamenIndex]?.laf.butShirmer.oielDroit.mm}} mm </p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Schirmer</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 text-center" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.butShirmer">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.butShirmer.oielDroit.secondes">{{historiquesConsultations[selectedExamenIndex]?.laf.butShirmer.oielGauche.mm}} mm </p>
                                </div>
                            </div>
                            <!-- end of Schirmer -->

                            <!-- Chambre antérieure -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.calmeEtProfonde.present==true">Calme et profonde</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.CAetroite.present==true">CA étroite</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.corpsetranger.present==true">Corps étranger</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.cyclodialyse.present==true">Cyclodialyse</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.hemorragiesousconjonctival.present==true">Hémorragie sous conjonctivale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.hypopion.present==true">Hypopion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.hyphema.present==true">Hyphema</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.ICA.present==true">ICA</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.IP.present==true">IP</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.massecristallinienne.present==true">Masse cristallinienne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.mechedevitre.present==true ">Mèche de vitre</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.pigments.present==true ">Pigments</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.recession.present==true">Récession</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.signedulimbe.present==true">Signe du limbe +</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.testdevanherick.present==true">Test de van herick</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallcellulaire.present==true">Tyndall cellulaire </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.tyndallproteique.present==true">Tyndall proteique</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielDroit.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Chambre antérieure</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.calmeEtProfonde.present==true">Calme et profonde</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.CAetroite.present==true">CA étroite</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.corpsetranger.present==true">Corps étranger</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.cyclodialyse.present==true">Cyclodialyse</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.hemorragiesousconjonctival.present==true">Hémorragie sous conjonctivale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.hypopion.present==true">Hypopion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.hyphema.present==true">Hyphema</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.ICA.present==true">ICA</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.IP.present==true">IP</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.massecristallinienne.present==true">Masse cristallinienne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.mechedevitre.present==true ">Mèche de vitre</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.pigments.present==true ">Pigments</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.recession.present==true">Récession</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.signedulimbe.present==true">Signe du limbe +</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.testdevanherick.present==true">Test de van herick</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallcellulaire.present==true">Tyndall cellulaire </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.tyndallproteique.present==true">Tyndall proteique</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.chambreanterieurealmeetprofonde.oielGauche.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end of Chambre antérieure -->

                            <!-- Iris -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.irisNormale ">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.normale.present==true">Normale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.atrophieirienne.present==true">Atrophie irienne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.convexe.present==true">Convexe</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.cristauxirien.present==true">Cristaux irien</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.granulomeirien.present==true">Granulome irien</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.heterochromie.present==true">Hétérochromie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.irisconcave.present==true">Iris concave</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.melanomeMassesuspecte.present==true">Mélanome/masse suspecte</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.naevusirien.present==true">Naevus irien</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.noduleirien.present==true">Nodule irien </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.plaieirienne.present==true">Plaie irienne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.rubeose.present==true">Rubéose </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.SPEirien.present==true">SPE irien</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.synechieiridocristalienne.present==true">Synéchie irido-cristallienne</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielDroit.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Iris</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.irisNormale ">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.normale.present==true">Normale</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.atrophieirienne.present==true">Atrophie irienne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.convexe.present==true">Convexe</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.cristauxirien.present==true">Cristaux irien</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.granulomeirien.present==true">Granulome irien</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.heterochromie.present==true">Hétérochromie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.irisconcave.present==true">Iris concave</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.melanomeMassesuspecte.present==true">Mélanome/masse suspecte</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.naevusirien.present==true">Naevus irien</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.noduleirien.present==true">Nodule irien </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.plaieirienne.present==true">Plaie irienne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.rubeose.present==true">Rubéose </p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.SPEirien.present==true">SPE irien</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.synechieiridocristalienne.present==true">Synéchie irido-cristallienne</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.irisNormale.oielGauche.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end of Iris -->

                            <!-- Tonus -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 text-center" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.toAic ">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.toAic.oielDroit.mm">{{historiquesConsultations[selectedExamenIndex]?.laf.toAic.oielDroit.mm}} mmHg</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Tonus</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 text-center" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.toAic ">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.toAic.oielGauche.mm">{{historiquesConsultations[selectedExamenIndex]?.laf.toAic.oielGauche.mm}} mmHg</p>
                                </div>
                            </div>
                            <!-- end of Tonus -->

                            <!-- Angle irido cornéen -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.ouvertSur360.present==true"> Ouvert sur 360 degrés</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.apposition.present==true"> Apposition</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.corpsetranger.present==true"> Corps étranger</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.ferme.present==true "> Fermé</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.hyphema.present==true"> Hyphema</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.hypopion.present==true">Hypopion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.occludable.present==true"> Occludable</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.pigmente.present==true"> Pigmenté</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.rubeose.present==true"> Rubéose</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.synechie.present==true"> Synéchie</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielDroit.allAnomalies"> {{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Angle Irido</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.ouvertSur360.present==true"> Ouvert sur 360 degrés</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.apposition.present==true"> Apposition</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.corpsetranger.present==true"> Corps étranger</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.ferme.present==true "> Fermé</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.hyphema.present==true"> Hyphema</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.hypopion.present==true">Hypopion</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.occludable.present==true"> Occludable</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.pigmente.present==true"> Pigmenté</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.rubeose.present==true"> Rubéose</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.synechie.present==true"> Synéchie</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.angleiridocornee.oielGauche.allAnomalies"> {{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end of Angle irido cornéen -->

                            <!-- RPM -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielDroit.presentEtSymetrique.present==true"> Présent et symétrique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielDroit.marcusgun.present==true"> Marcus gun +</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielDroit.mydriase.present==true"> Mydriase</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielDroit.myosis.present==true"> Myosis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielDroit.semimydriase.present==true">Semi mydriase</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielDroit.allAnomalies"> {{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>RPM</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielGauche.presentEtSymetrique.present==true"> Présent et symétrique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielGauche.marcusgun.present==true"> Marcus gun +</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielGauche.mydriase.present==true"> Mydriase</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielGauche.myosis.present==true"> Myosis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielGauche.semimydriase.present==true">Semi mydriase</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.rpmpresentetsymetrique.oielGauche.allAnomalies"> {{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end of RPM -->

                            <!-- Dilatation -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.dilatation">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.dilatation.oielDroit.bonne.present==true">Bonne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.dilatation.oielDroit.mauvaise.present==true ">Mauvaise</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.dilatation.oielDroit.moyenne.present==true">Moyenne </p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.dilatation.oielDroit.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Dilatation</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.dilatation">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.dilatation.oielGauche.bonne.present==true">Bonne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.dilatation.oielGauche.mauvaise.present==true ">Mauvaise</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.dilatation.oielGauche.moyenne.present==true">Moyenne </p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.dilatation.oielGauche.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end of Dilatation -->

                            <!-- Cristallin -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.transparent.present==true">Transparent</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.aphaquie.present==true">Aphaquie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.capsuleantcalcifie.present==true">Capsule antérieure calcifiée</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.capsuleantrompu.present==true">Capsule antérieure rompue</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.cataracte.present==true">Cataracte</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.corpsetranger.present==true">Corps étranger</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.ectopie.present==true">Ectopie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.fragilitezonulaire.present==true">Fragilité zonulaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.glaucomeflecken.present==true">Glaucome flecken</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.luxationSubluxation.present==true">Luxation/subluxation</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.microspheiohaquie.present==true">Microspheiohaquie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.phacosclerose.present==true">Phacosclérose</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.phacodonesis.present==true">Phacodonésis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.pseudophaque.present==true">Pseudophaque</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.SPE.present==true">SPE</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielDroit.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Cristallin</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.transparent.present==true">Transparent</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.aphaquie.present==true">Aphaquie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.capsuleantcalcifie.present==true">Capsule antérieure calcifiée</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.capsuleantrompu.present==true">Capsule antérieure rompue</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.cataracte.present==true">Cataracte</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.corpsetranger.present==true">Corps étranger</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.ectopie.present==true">Ectopie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.fragilitezonulaire.present==true">Fragilité zonulaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.glaucomeflecken.present==true">Glaucome flecken</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.luxationSubluxation.present==true">Luxation/subluxation</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.microspheiohaquie.present==true">Microspheiohaquie</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.phacosclerose.present==true">Phacosclérose</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.phacodonesis.present==true">Phacodonésis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.pseudophaque.present==true">Pseudophaque</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.SPE.present==true">SPE</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.cristallinTransparent.oielGauche.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end of Cristallin -->

                            <!-- Vitré -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.vitre">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.calmeEtAcellulaire.present==true">Calme et acellulaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.banquise.present==true">Banquise</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.brides.present==true">Brides</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.cordage.present==true">Cordage</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.corpsasterode.present==true">Corps astéroïde</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.corpsetrangers.present==true">Corps étrangers</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.DPV.present==true">DPV</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.essaimagetumoral.present==true">Essaimage tumoral</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.fragmentcristallinien.present==true">Fragment cristallinien</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.hematique.present==true">Hématique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.hemorragiepreretinienne.present==true">Hémorragie prérétinienne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.hemorragievitreene.present==true">Hémorragie vitréene</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.Lacune.present==true">Lacune</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.liquefaction.present==true">Liquéfaction</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.massecristallinienne.present==true">Masse cristallinienne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.oeufdefourmis.present==true">Oeuf de fourmis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.persistanceduvitreprimitif.present==true">Persistance du vitré primitif</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.pigmentaire.present==true">Pigmentaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.precipiteencolierdeperle.present==true">Précipités en colier de perles</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.proteique.present==true">Protéique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.synchesisetincelant.present==true">Synchisis étincelant</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.tyndallcellulaire.present==true">Tyndall cellulaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.voilevitreen.present==true">Voile vitréen</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielDroit.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Vitre</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 codageElements" *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.vitre">
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.calmeEtAcellulaire.present==true">Calme et acellulaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.banquise.present==true">Banquise</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.brides.present==true">Brides</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.cordage.present==true">Cordage</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.corpsasterode.present==true">Corps astéroïde</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.corpsetrangers.present==true">Corps étrangers</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.DPV.present==true">DPV</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.essaimagetumoral.present==true">Essaimage tumoral</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.fragmentcristallinien.present==true">Fragment cristallinien</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.hematique.present==true">Hématique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.hemorragiepreretinienne.present==true">Hémorragie prérétinienne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.hemorragievitreene.present==true">Hémorragie vitréene</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.Lacune.present==true">Lacune</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.liquefaction.present==true">Liquéfaction</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.massecristallinienne.present==true">Masse cristallinienne</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.oeufdefourmis.present==true">Oeuf de fourmis</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.persistanceduvitreprimitif.present==true">Persistance du vitré primitif</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.pigmentaire.present==true">Pigmentaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.precipiteencolierdeperle.present==true">Précipités en colier de perles</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.proteique.present==true">Protéique</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.synchesisetincelant.present==true">Synchisis étincelant</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.tyndallcellulaire.present==true">Tyndall cellulaire</p>
                                    <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.voilevitreen.present==true">Voile vitréen</p>
                                    <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.vitre.oielGauche.allAnomalies">{{anomaly.titre}}</p>
                                </div>
                            </div>
                            <!-- end of Vitré -->

                            <!-- Fond de l'œil -->
                            <div class="row mt-3">
                                <!-- oeil droit -->
                                <div class="col-md-4 codageElements">
                                    <!-- ! Normale -->
                                    <div *ngIf="
                                        historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.normaleRapport?.present==true && historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.normaleRapport.present==true 
                                        && historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.normale.present==true&& historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche?.normale.present==true
                                        &&historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire?.oielDroit?.normale.present==true&&historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire?.oielGauche?.normale.present==true
                                        && historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit?.normale.present==true&&historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche?.normale.present==true
                                    ">
                                        Normale
                                    </div>
                                    <!-- !end of Normale -->

                                    <!-- ! Papille -->
                                    <div *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.papille && historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.normaleRapport.present==false||historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.normaleRapport.present==false">
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.normaleRapport.present==true">( Papille ) Normale rapport C/D < 3/10</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.atrophiepupillaire.present==true">( Papille ) Atrophie pupillaire</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.bouclesasculairesprepapillaires.present==true">( Papille ) Boucles vasculaires prépapillaires</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.drusen.present==true">( Papille ) Drusen</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.dysversion.present==true">( Papille ) Dysversion</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.ElargissementdelazoneB.present==true">( Papille ) Élargissement de la zone B </p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.Excavationpathologique.present==true">( Papille ) Excavation pathologique</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.ExclusiondUnvaisseauxcircumlineaire.present==true">( Papille ) Exclusion d’un vaisseaux circum linéaire </p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.fibreamyeline.present==true">( Papille ) Fibre à myéline</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.fossettecolobomateuse.present==true">( Papille ) Fossette colobomateuse</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.hemorragieperipapillaire.present==true">( Papille ) Hémorragie peripapillaire </p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.isntnonrespecte.present==true">( Papille ) ISNT non respecté</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.Morningglory.present==true">( Papille ) Morning glory</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.nevoxpapillaire.present==true">( Papille ) Néo-vaisseaux papillaire</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.oedemepapillaire.present==true">( Papille ) Oedème papillaire</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.papilleenfleurdeliseron.present==true">( Papille ) Papille en fleur de liseron</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.paleurpapillaire.present==true">( Papille ) Paleur papillaire</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.sriesangoide.present==true">( Papille ) Stries angioïdes</p>
                                                <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.allAnomalies">( Papille ) {{anomaly.titre}}</p>
                                    </div>
                                    <!-- ! end of Papille -->


                                    <!-- ! Macula-->
                                    <div *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.macule && historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.normale.present==false||historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.normale.present==false">
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.normale.present==true">( Macula ) Normale</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.aspectenoeildeboeuf.present==true">( Macula ) Aspect en oeil de boeuf</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.aspectjaunedoeufauplat.present==true"> ( Macula ) Aspect jaune d’oeuf au plat</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.aspectpoivreetsel.present==true"> ( Macula ) Aspect poivre et sel</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.couronneexcsudatif.present==true"> ( Macula ) Couronne exsudative </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.DEP.present==true">( Macula ) DEP </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.dreusenmaculaire.present==true"> ( Macula ) Dreusen maculaire</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.DSR.present==true"> ( Macula ) DSR</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.eLogettemaculaire.present==true">( Macula ) Logette maculaire</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.etoilemaculaire.present==true">( Macula ) Etoile maculaire </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.hematomemaculaire.present==true">( Macula ) Hématome maculaire </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.hemorragiemaculaire.present==true">( Macula ) Hémorragie maculaire </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.hemorragieretrohyaloidienne.present==true"> ( Macula ) Hémorragie rétrohyaloïdienne </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.macroanevrysme.present==true"> ( Macula ) Macroanévrisme</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.macularougecerise.present==true"> ( Macula ) Macula rouge cerise</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.membraneepimaculairen.present==true"> ( Macula ) Membrane épimaculaire</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.perterefletfoveolaire.present==true"> ( Macula ) Perte du reflet fovéolaire</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.pigmentationanormale.present==true"> ( Macula ) Pigmentation anormale</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.plagedatrophie.present==true">( Macula ) Plage d’atrophie</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.remaniementmicorkystique.present==true">( Macula ) Remaniement micorkystique </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.trousmaculaire.present==true">( Macula ) Trous maculaire </p>
                                        <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.allAnomalies">( Macula ) {{anomaly.titre}}</p>
                                    </div>
                                    <!-- ! end of Macula-->

                                    <!-- ! Anomalie vasculaire   -->
                                    <div *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire && historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire?.oielDroit?.normale.present==false||historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire?.oielGauche?.normale.present==false">
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielDroit.normale.present==true">( Anomalie vasculaire ) Normale</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielDroit.arteriosclerose.present==true">( Anomalie vasculaire ) Artériosclérose </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielDroit.signedeRH.present==true"> ( Anomalie vasculaire ) Signe de rétinopathie hypertensive</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielDroit.signedeRD.present==true">( Anomalie vasculaire ) Signe de rétinopathie diabétique</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielDroit.signedOcclusionVeineuse.present==true"> ( Anomalie vasculaire ) Signe d’Occlusion Veineuse</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielDroit.signedOcclusionARTERIELLE.present==true">( Anomalie vasculaire ) Signe d’Occlusion Artérielle</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielDroit.vasculite.present==true"> ( Anomalie vasculaire ) Vasculite </p>
                                        <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielDroit.allAnomalies">( Anomalie vasculaire ) {{anomaly.titre}}</p>
                                    </div>
                                    <!-- ! end of Anomalie vasculaire   -->


                                    <!-- ! Rétine -->
                                    <div *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.retine && historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit?.normale.present==false||historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche?.normale.present==false">
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.normale.present==true">( Rétine ) Normale</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.blancavecpression.present==true"> ( Rétine ) Blanc avec pression</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.blancsanspression.present==true"> ( Rétine ) Blanc sans pression</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.decollementchorodien.present==true"> ( Rétine ) Décollement choroïdien</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.decollementretine.present==true">( Rétine ) Décollement de rétine</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.degenerescencelattice.present==true"> ( Rétine ) Dégénérescence lattice</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.dehiscenceretinienne.present==true"> ( Rétine ) Déhiscence rétinienne</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.DEP.present==true"> ( Rétine ) DEP</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.DSR.present==true"> ( Rétine ) DSR </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.exsudat.present==true">( Rétine ) Exsudat </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.foyer.present==true">( Rétine ) Foyer </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.masseretinochoroidienne.present==true"> ( Rétine ) Masse rétino/choroïdienne</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.givre.present==true">( Rétine ) Givre</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.hemorragieretinienne.present==true"> ( Rétine ) Hémorragie rétinienne</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.macroanevrisme.present==true"> ( Rétine ) Macroanévrisme</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.microanevrisme.present==true"> ( Rétine ) Microanévrisme</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.nodulecotonneux.present==true"> ( Rétine ) Nodule cotonneux </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.palissade.present==true"> ( Rétine ) Palissade</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.pigmentation.present==true">( Rétine ) Pigmentation</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.retinoschisis.present==true"> ( Rétine ) Rétinoschisis</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.tachederp.present==true">( Rétine ) Tâche de rétinite pigmentaire </p>
                                        <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.allAnomalies">( Rétine ) {{anomaly.titre}}</p>
                                    </div>
                                    <!-- ! end of Rétine -->
                                </div>
                                <div class="col-md-4 titleConclusionCenter">
                                    <h6>Fond de l'œil</h6>
                                </div>
                                <!-- oeil gauche -->
                                <div class="col-md-4 codageElements">
                                    <!-- ! Normale -->
                                    <div *ngIf="
            historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.normaleRapport.present==true && historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.normaleRapport.present==true 
            && historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.normale.present==true&& historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.normale.present==true
            &&historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielDroit.normale.present==true&&historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielGauche.normale.present==true
            && historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.normale.present==true&&historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.normale.present==true
            ">
                                        Normale
                                    </div>
                                    <!-- !end of Normale -->
                                    <!-- ! Papille -->
                                    <div *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.papille && historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielDroit?.normaleRapport.present==false||historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.normaleRapport.present==false">
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.normaleRapport.present==true"> ( Papille ) Normale rapport C/D < 3/10</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.atrophiepupillaire.present==true">( Papille ) Atrophie pupillaire</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.bouclesasculairesprepapillaires.present==true">( Papille ) Boucles vasculaires prépapillaires</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.drusen.present==true">( Papille ) Drusen</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.dysversion.present==true">( Papille ) Dysversion</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.ElargissementdelazoneB.present==true">( Papille ) Élargissement de la zone B </p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.Excavationpathologique.present==true">( Papille ) Excavation pathologique</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.ExclusiondUnvaisseauxcircumlineaire.present==true">( Papille ) Exclusion d’un vaisseaux circum linéaire </p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.fibreamyeline.present==true">( Papille ) Fibre à myéline</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.fossettecolobomateuse.present==true">( Papille ) Fossette colobomateuse</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.hemorragieperipapillaire.present==true">( Papille ) Hémorragie peripapillaire </p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.isntnonrespecte.present==true">( Papille ) ISNT non respecté</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.Morningglory.present==true">( Papille ) Morning glory</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.nevoxpapillaire.present==true">( Papille ) Néo-vaisseaux papillaire</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.oedemepapillaire.present==true">( Papille ) Oedème papillaire</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.papilleenfleurdeliseron.present==true">( Papille ) Papille en fleur de liseron</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.paleurpapillaire.present==true">( Papille ) Paleur papillaire</p>
                                                <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.sriesangoide.present==true">( Papille ) Stries angioïdes</p>
                                                <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.papille?.oielGauche?.allAnomalies">( Papille ) {{anomaly.titre}}</p>
                                    </div>
                                    <!-- ! end of Papille -->


                                    <!-- ! Macula-->
                                    <div *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.macule && historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielDroit?.normale.present==false||historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.normale.present==false">
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.normale.present==true">( Macula ) Normale</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.aspectenoeildeboeuf.present==true">( Macula ) Aspect en oeil de boeuf</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.aspectjaunedoeufauplat.present==true"> ( Macula ) Aspect jaune d’oeuf au plat</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.aspectpoivreetsel.present==true"> ( Macula ) Aspect poivre et sel</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.couronneexcsudatif.present==true"> ( Macula ) Couronne exsudative </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.DEP.present==true">( Macula ) DEP </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.dreusenmaculaire.present==true"> ( Macula ) Dreusen maculaire</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.DSR.present==true"> ( Macula ) DSR</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.eLogettemaculaire.present==true">( Macula ) Logette maculaire</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.etoilemaculaire.present==true">( Macula ) Etoile maculaire </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.hematomemaculaire.present==true">( Macula ) Hématome maculaire </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.hemorragiemaculaire.present==true">( Macula ) Hémorragie maculaire </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.hemorragieretrohyaloidienne.present==true"> ( Macula ) Hémorragie rétrohyaloïdienne </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.macroanevrysme.present==true"> ( Macula ) Macroanévrisme</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.macularougecerise.present==true"> ( Macula ) Macula rouge cerise</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.membraneepimaculairen.present==true"> ( Macula ) Membrane épimaculaire</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.perterefletfoveolaire.present==true"> ( Macula ) Perte du reflet fovéolaire</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.pigmentationanormale.present==true"> ( Macula ) Pigmentation anormale</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.plagedatrophie.present==true">( Macula ) Plage d’atrophie</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.remaniementmicorkystique.present==true">( Macula ) Remaniement micorkystique </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.trousmaculaire.present==true">( Macula ) Trous maculaire </p>
                                        <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.macule?.oielGauche.allAnomalies">( Macula ) {{anomaly.titre}}</p>
                                    </div>
                                    <!-- ! end of Macula-->

                                    <!-- ! Anomalie vasculaire   -->
                                    <div *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire && historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire?.oielDroit.normale.present==false||historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire?.oielGauche.normale.present==false">
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielGauche.normale.present==true">( Anomalie vasculaire ) Normale</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielGauche.arteriosclerose.present==true">( Anomalie vasculaire ) Artériosclérose </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielGauche.signedeRH.present==true"> ( Anomalie vasculaire ) Signe de rétinopathie hypertensive</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielGauche.signedeRD.present==true">( Anomalie vasculaire ) Signe de rétinopathie diabétique</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielGauche.signedOcclusionVeineuse.present==true"> ( Anomalie vasculaire ) Signe d’Occlusion Veineuse</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielGauche.signedOcclusionARTERIELLE.present==true">( Anomalie vasculaire ) Signe d’Occlusion Artérielle</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielGauche.vasculite.present==true"> ( Anomalie vasculaire ) Vasculite </p>
                                        <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.anomalieVasculaire.oielGauche.allAnomalies">( Anomalie vasculaire ) {{anomaly.titre}}</p>
                                    </div>
                                    <!-- ! end of Anomalie vasculaire   -->


                                    <!-- ! Rétine -->
                                    <div *ngIf="historiquesConsultations[selectedExamenIndex]?.laf && historiquesConsultations[selectedExamenIndex]?.laf.retine && historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielDroit.normale.present==false||historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.normale.present==false">
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.normale.present==true">( Rétine ) Normale</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.blancavecpression.present==true"> ( Rétine ) Blanc avec pression</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.blancsanspression.present==true"> ( Rétine ) Blanc sans pression</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.decollementchorodien.present==true"> ( Rétine ) Décollement choroïdien</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.decollementretine.present==true">( Rétine ) Décollement de rétine</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.degenerescencelattice.present==true"> ( Rétine ) Dégénérescence lattice</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.dehiscenceretinienne.present==true"> ( Rétine ) Déhiscence rétinienne</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.DEP.present==true"> ( Rétine ) DEP</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.DSR.present==true"> ( Rétine ) DSR </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.exsudat.present==true">( Rétine ) Exsudat </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.foyer.present==true">( Rétine ) Foyer </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.masseretinochoroidienne.present==true"> ( Rétine ) Masse rétino/choroïdienne</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.givre.present==true"> ( Rétine ) Givre</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.hemorragieretinienne.present==true"> ( Rétine ) Hémorragie rétinienne</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.macroanevrisme.present==true"> ( Rétine ) Macroanévrisme</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.microanevrisme.present==true"> ( Rétine ) Microanévrisme</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.nodulecotonneux.present==true"> ( Rétine ) Nodule cotonneux </p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.palissade.present==true"> ( Rétine ) Palissade</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.pigmentation.present==true">( Rétine ) Pigmentation</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.retinoschisis.present==true"> ( Rétine ) Rétinoschisis</p>
                                        <p *ngIf="historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.tachederp.present==true">( Rétine ) Tâche de rétinite pigmentaire </p>
                                        <p *ngFor="let anomaly of historiquesConsultations[selectedExamenIndex]?.laf.retine?.oielGauche.allAnomalies">( Rétine ) {{anomaly.titre}}</p>

                                    </div>
                                    <!-- ! end of Rétine -->
                                </div>
                            </div>
                            <!-- end of Fond de l'œil -->
                        </div>
                        <!-- !end of examen conclusion -->
                    </div>



                </div>
                <div class="modal-footer">
                    <button type="button" style="background-color: #00958a; border: 1px solid #00958a; color: white;" id="btn-siderbar-close" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</div>