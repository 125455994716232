import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { ExamenService } from 'src/app/services/examen/examen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2IzitoastService } from 'ng2-izitoast';
import { SharedDataService } from 'src/app/services/shared/shared.service';
import { prescrireVerreModel, prescrireLentilleModel, chirurgieModel, examenComplementaireModel, prescrireLaserModel, certificataptitudepermisModel } from 'src/app/dashboard/pays/models-cat'
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms'
import * as moment from 'moment';
import { MedecinService } from 'src/app/services/profils/medecin/medecin.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MedicamentsService } from 'src/app/services/medicaments/medicaments.service';
import { ResponsiveService } from 'src/app/services/responsive/responsive.service'
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { motifMaxLength, motifMinLength, champSpecialCaracters, champMinLength } from 'src/app/services/constants/messages';
import { ValidationService } from 'src/app/services/validate/validateError.service';
import { medecamentClassTherapeutique } from '../../medicaments/medicamentsAvecClasse';
import { conversionTable } from 'src/app/services/constants/tableConversion';
import { MotifService } from 'src/app/services/motif/motif.service';

@Component({
  selector: 'conclusion',
  templateUrl: './conclusion.component.html',
  styleUrls: ['./conclusion.component.scss', '../cat/cat.component.css'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(450, style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate(450, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class ConclusionComponent implements OnInit {

  today: any;
  motif: any;
  traitementCorrection = true
  isActiveTraitement = false
  isActiveConclusion = true
  isActiveCatetProchainConsul = false

  isActivePrescrireDesVerres = false;
  isActivePrescrireDesLentilles = false;
  isActivePrescrireOrdo = false
  isActivePrescrireBObilanorthoptique = false;
  isActiveChirurgie = false;
  isActiveExamenComplementaire = false;
  isActivePrescrireLasers = false;
  isActiveCertificatMedical = false;
  isActiveCertificataptitudePermis = false;
  isActiveLettreConfrere = false;

  listAntecdantLaser = ["Capsulotomie au laser ND YAG", "Iridotomie au laser ND YAG", "Iridotomie au laser Argon", "Photo coagulation par laser PASCAL", "Photo coagulation par laser ARGON", "Traitement focale pour œdème maculaire", "Traitement par laser micropulse"]

  selectedAntecdantLaser: any

  // -------------------------------------end Antécédents de traitement par laser vraiable
  // -------------------------------------start Chirigaux vraiable
  listChurigieType = [
    "None",
    "Chirurgie de la cataracte par phacoémulsification",
    "Chirurgie de la cataracte  par voie extra capsulaire",
    "Chirurgie de la cataracte par voie intra capsulaire",
    "Chirurgie du strabisme",
    "Chirurgie du ptosis",
    "Chirurgie du décollement de la rétine",
    "Chirurgie de trous maculaire",
    "Chirurgie de Membrane épimaculaire",
    "Chirurgie du glaucome",
    "Chirurgie de pterygion",
    "Chirurgie de chalazion",
    "Chirurgie refractive",
    "Cross linking du collagene corneen",
    "Blepharoplastie",
    "Enucleation",
    "Evisceration",
    "Greffe de cornée transfixiante",
    "Greffe de cornée lamellaire",
    "Greffe de cornée endothéliale",
    "Injection intra oculaire d’anti VEGF",
    "Anneux intracorneen",
    "Implantation phaque ICL",
    "Implantation secondaire avec un implant de chambre antérieure",
    "Implantation secondaire avec un implant Artisan"
  ].sort((a, b) => { return a.localeCompare(b); });

  // -------------------------------------end   Chirigaux vraiable
  // -------------------------------------start   Correction optique vraiable
  listCorrectionOptique = ["None", "Chirurgie de la cataracte par phacoémulsification", "Chirurgie de la cataracte  par voie extra capsulaire",]

  selectedCorrectionOptique: any
  listselectedCorrectionOptique: string[] = []
  // -------------------------------------end   Correction optique vraiable
  // start varibales refraction

  avecCycAtropine = true
  avecCycCyclocol = false
  avecCycTropicamide = false
  // echelle moyner
  echelleMoyner = true
  moynerAVSCLeft: any
  moynerAVSCRight: any
  moynerAVACLeft: any
  moynerAVACRight: any
  // echelle snellen
  echelleSnellen = false

  snellenAVSCLeft: any
  snellenAVSCRight: any
  snellenAVACLeft: any
  snellenAVACRight: any

  oeilDroitAVLoin = true
  oeilGaucheAVLoin = false

  oeilDroitAVPres = true
  oeilGaucheAVPres = false
  // echeele Parinaud
  echelleParinaud = true
  parinaudAVSCLeft: any
  parinaudAVSCRight: any
  parinaudAVACLeft: any
  parinaudAVACRight: any

  // endvaribales refraction
  // Conduite à tenir
  listCat = [
    "None",
    "Prescrire des lunettes",
    "Prescrire des lentilles",
    "Prescrire une ordonnance",
    "Prescrire une chirurgie",
    "Prescrire des examens complémentaires",
    "Prescrire un laser",
    "Prescrire un bilan orthoptique",
    "Prescrire un certificat médical",
    "Prescrire un certificat d'aptitude à la conduite",
    "Prescrire une lettre pour confrère"
  ]
  selectedCat: any
  // ------------------------ motif 
  motifList = []
  selectedMotif: any
  myExamen: any;
  minDate = new Date().toISOString().substring(0, 16);
  objecttoSend = {
    TOOD: 0,
    TOOG: 0,
    butOD: 0,
    butOG: 0,
    shirmerOD: 0,
    shirmerOG: 0,
    cat: [],
    additionalConclusion: "",
    haveSpecificDiagnostic: false,
    source: "",
    sansCycloSphereDroit: 0,
    sansCycloSphereGauche: 0,
    sansCycloCylindreDroit: 0,
    sansCycloCylindreGauche: 0,
    avecCycloSphereDroit: 0,
    avecCycloSphereGauche: 0,
    avecCycloCylindreDroit: 0,
    avecCycloCylindreGauche: 0,
    addOD: 0,
    addOG: 0,
    nextAppointment: "",
    nextAppointmentDuration: "",
    nextMotif: []
  };
  choosedTag: any;
  prescrireVerre: any;
  prescrireLentille: any
  drugsForm: FormGroup;
  prescrireOrdo: any;
  prescrireBoBilanOrtho: any;
  chirurgie: any;
  examenComplementaire: any;
  prescrireLaser: any;
  certificatMedical: any
  certificataptitudepermis: any;
  lettreConfrere: any;
  mesMedecins: any[] = []
  staticMedecins: any[] = []
  nom: any
  selectedMedecinn: any;
  //data cat 

  presecVerreLoin = true
  presecVerrePres = true
  leftCorrection = 0
  rightCorrection = 0
  selectedVerre: any
  selectedVerres: string[] = []
  selectedFiltre: any
  selectedFiltres: string[] = []

  listVerre = ["Unifocaux", "Photochromiques", "EyeZen", "Myopia", "Progressifs", "Relaxy", "Myopilux"]
  listFiltre = ["Filtre Anti UV", "Anti lumière bleue", "Anti rayure"]
  listMarque = ["Hoya", "Essilor", "Optylab"]
  // ------------end ofPrescrire verre Variable--------//

  // !------------start of Prescrire Lentille Variable--------//
  typeLentilles = ["souple", "Rigide", "Sclérale"]
  torique = true
  modeRenouvellemnt = ["Journalier", "Mensuel", "Annuel"]

  solutionMultifonction = false
  solutionSaline = false
  solutionOxygene = false
  // ------------end of Prescrire Lentille Variable--------//
  // !------------start of Prescrire Ordo Variable--------//
  ClasseTherapeutique = ["analgesiques non opioides & ains", "analgesiques opioides", "anesthesiques locaux /ophtalmologie", "anesthesiques locaux", "rachianesthesie",
    "anthelmintiques intestinaux", "antiacides et autres antiulcereux", "antiallergiques/antianaphylactiques", "antiamibiens et antigiardiens", "antianemiques", "antiangoreux", "antiarythmiques", "antiocytociques", "autres antibacteriens", "antibacteriens beta lactamines", "anticonvulsivants/ antiepileptiques", "antidepresseurs", "cephalosporines", "diuretiques", "ocytociques", "vitamines"
  ]
  // ------------end of Prescrire Ordo Variable--------//

  //! ------------start of Prescrire:BO bilan orthoptique--------//
  listMotifBlian = ["Cure d’amblyopie", "Réducation orthoptique pour insuffisance de convergence", "Test de Lancaster pour paralysie oculomotrice"]
  selectedMotifBilan: any = ''
  selectedMotifsBilan: string[] = []

  // ------------end of Prescrire:BO bilan orthoptique--------//


  // !------------start of Chirurgie--------//

  selectedChirugieType: any
  selectedChirugieTypes: string[] = []
  OeilDroitChirugie = false
  OeilGaucheChirugie = false
  precaution = ["precaution1", "precaution2", "precaution3"]
  // ------------end of Chirurgie--------//

  //! ------------start of examen complémentaire--------//
  listExamCompType = ["None", "Angiographie rétinienne á l’ICG", "Angiographie rétinienne á la fluorescéine",
    "Bilan glaucome", "Bilan orthoptique", "Echographie en mode A", "Echographie en mode B", "OCT chambre antérieure", "OCT cornée",
    "OCT papille", "OCT macula", "Orbscan", "Pentacam"
  ]
  selectedExamComp: any
  listSelectedExamsComp: string[] = []
  OeilDroitExamComp = false
  OeilGaucheExamComp = false
  precautionExamComp = ["precaution1", "precaution2", "precaution3"]
  // ------------end of examen complémentaire-------//

  //! ------------start of Laser--------//
  listPrescLaserType = ["Capsulotomie au laser ND YAG", "Iridotomie au laser ND YAG", "Iridotomie au laser Argon", "Photo coagulation par laser PASCAL", "Photo coagulation par laser ARGON", "Traitement focale pour œdème maculaire", "Traitement par laser micropulse"]
  selectedPrescLaser: any
  listSelectedPrescLaser: string[] = []
  OeilDroitPrescLaser = false
  OeilGauchePrescLaser = false
  precautionPrescLaser = ["precaution1", "precaution2", "precaution3"]
  showTypeLaser = false
  // ------------end of Laser--------//

  //! ------------start of Certificat Médical--------//
  listCertificatMedTypes = ["Certificat médical de repos", "Certificat médical de présence", "Certificat d’accompagnement"]
  selectedCertifMed = "Certificat médical de repos"
  // end data cat
  nextMotif: any;
  selectedMotifs: string[] = [];
  // medicaments: any[] = [];
  public options: any;
  medicamentsData: any[] = []
  screenWidth: any
  ng2slectwidh: any
  formMotif: any
  motifMinLength = motifMinLength
  motifMaxLength = motifMaxLength
  champSpecialCaracters = champSpecialCaracters
  medicamentsList: any = medecamentClassTherapeutique
  // Droit
  typeClasses = Object.keys(this.medicamentsList).sort((a, b) => {
    if (a === 'None') return -1; // "None" comes first
    if (b === 'None') return 1;
    return a.localeCompare(b); // Alphabetical order for the rest
  }); // Extracts typeClass names
  selectedTypeClasses: string[] = [];

  selectedCategories: string[] = [];
  medicamentsPerRow: any[] = [];
  categoriesPerRow: any[] = [];
  selectedData: any
  selectedCategory: string;
  selectedMedicament: string;
  medicaments: string[];
  categories: string[];
  hasMedicalOeilDroit: any
  hasMedicalOeilGauche: any
  hasTraitementOeilDroit: any
  hasTraitementOeilGauche: any
  hasTraitementLaserOeilDroit: any
  hasTraitementLaserOeilGauche: any
  hasChirugieOeilDroit: any
  hasChirugieOeilGauche: any
  isConc = "1";
  isCond = "0";
  constructor(private responsiveService: ResponsiveService, private medicamentsService: MedicamentsService, private _sanitizer: DomSanitizer, private medecinService: MedecinService,
    private sharedDataService: SharedDataService, private examenService: ExamenService, private router: Router,
    private route: ActivatedRoute, public iziToast: Ng2IzitoastService, private fb: FormBuilder, private formBuilder: FormBuilder, private motifService: MotifService) {

    this.drugsForm = this.fb.group({
      consigne: '',
      informationAdditionnel: '',
      listDrugs: this.fb.array([this.createDrugsControl()]),
    });
    this.formMotif = this.formBuilder.group({
      motif: ['', [Validators.minLength(3), Validators.maxLength(100), ValidationService.validateMotif]]
    })
    this.motifService.getMotifByDoctor()
      .subscribe((motifs: any[]) => {
        this.motifList = motifs;
      });
  }

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    // Subscribe to screen width changes
    this.responsiveService.getScreenWidth().subscribe((width) => {
      this.screenWidth = width;
      if (this.screenWidth < 768) {
        // Handle small screens
        this.ng2slectwidh = 200
        return true;
      } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
        // Handle medium screens
        this.ng2slectwidh = 380
        return true;
      } else {
        // Handle large screens
        this.ng2slectwidh = 480
        return true;
      }
    });
    this.sharedDataService.dataConclusionConduite$.subscribe((data) => {
      if(data && data.conduite && data.conclusion){
        this.isCond = data.conduite
        this.isConc = data.conclusion
        if(this.isConc === '1'){
          this.showConclusion();
          return true;
        }
        if(this.isCond === '1'){
          this.showCatetProchainConsul();
          return true;
        }
      }
    });
    this.today = new Date()

    const idExamen = this.route.snapshot.paramMap.get('idExamen');
    if (idExamen && idExamen != undefined && idExamen != null) {
      this.examenService.getExamenByIdForConclusion(idExamen).subscribe((data: any) => {
        this.myExamen = data
        if (this.myExamen) {
          this.configureDataExamen()
          return true;
        }
      },
        err => {
          return false;
        });
    }

    // get all médecins 
    this.medecinService.getMedecinAccepted().subscribe((medecins: any[]) => {
      this.medecinService.getMedecinScapedBypage(0, 1000).subscribe((medecinsss: any[]) => {
        this.mesMedecins = medecins.concat(medecinsss);
        this.staticMedecins = medecins.concat(medecinsss);
      },
        err => {
          return false;
        });

    },
      err => {
        return false;
      });

    this.options = {
      multiple: false,
      maximumSelectionLength: 20
    }
  }
  medicalOeilDroit() {
    this.hasMedicalOeilDroit = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux.some(
      (value: any) => value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux');
  }

  medicalOeilGauche() {
    this.hasMedicalOeilGauche = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.medicaux.some(
      (value: any) => value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux');
  }

  traitementOeilDroit() {
    this.hasTraitementOeilDroit = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours.some(
      (value: any) => value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux');
  }

  traitementOeilGauche() {
    this.hasTraitementOeilGauche = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.traitementEnCours.some(
      (value: any) => value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux');
  }
  traitementLaserOeilDroit() {
    this.hasTraitementLaserOeilDroit = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser && (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'oeilDroit' ||
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'deuxYeux')
  }
  traitementLaserOeilGauche() {
    this.hasTraitementLaserOeilGauche = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser && (this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'oeilGauche' ||
      this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.antecedentsdetraitementparlaser.oiel === 'deuxYeux')
  }
  chirugieOeilDroit() {
    this.hasChirugieOeilDroit = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux.some(
      (value: any) => value.oiel === 'oeilDroit' || value.oiel === 'deuxYeux');
  }
  chirugieOeilGauche() {
    this.hasChirugieOeilGauche = this.myExamen.interrogation.antecedentsophtalmologiquepersonnels.chirurgicaux.some(
      (value: any) => value.oiel === 'oeilGauche' || value.oiel === 'deuxYeux');
  }
  configureDataExamen() {

    this.medicalOeilDroit()
    this.medicalOeilGauche()

    this.traitementOeilDroit()
    this.traitementOeilGauche()

    this.traitementLaserOeilDroit()
    this.traitementLaserOeilGauche()

    this.chirugieOeilDroit()
    this.chirugieOeilGauche()

    this.motif = this.myExamen.motif
    this.selectedMotifs = this.myExamen.nextMotif
    this.objecttoSend.cat = this.myExamen.tags
    this.minDate = new Date(this.myExamen.start).toISOString().substring(0, 16);
    if (this.myExamen.refraction && !this.myExamen.refraction.sansCycloplegie) {
      this.myExamen.refraction.sansCycloplegie = {
        oielDroit: {
          sphere: "",
          cylindre: "",
          axe: "",
          keratometrie: "",
        },
        oielGauche: {
          sphere: "",
          cylindre: "",
          axe: "",
          keratometrie: "",
        }
      }
    }
    if (this.myExamen.refraction && !this.myExamen.refraction.avecCycloplegie) {
      this.myExamen.refraction.avecCycloplegie = {
        type: "",
        oielDroit: {
          sphere: "",
          cylindre: "",
          axe: "",
          keratometrie: "",
        },
        oielGauche: {
          sphere: "",
          cylindre: "",
          axe: "",
          keratometrie: "",
        },
      }
    }

    if (this.myExamen.refraction && this.myExamen.refraction.avecCycloplegie && !this.myExamen.refraction.avecCycloplegie.oielDroit) {
      this.myExamen.refraction.avecCycloplegie.oielDroit = {
        sphere: "",
        cylindre: "",
        axe: "",
        keratometrie: "",
      }
    }
    if (this.myExamen.refraction && this.myExamen.refraction.avecCycloplegie && !this.myExamen.refraction.avecCycloplegie.oielGauche) {
      this.myExamen.refraction.avecCycloplegie.oielGauche = {
        sphere: "",
        cylindre: "",
        axe: "",
        keratometrie: "",
      }
    }

    // --------Reinialisation des valeurs lorsque ils sont 'undefined'
    this.myExamen.refraction.acuitevisueldeloin.oielDroit.sphere !== undefined ? this.myExamen.refraction.acuitevisueldeloin.oielDroit.sphere : this.myExamen.refraction.acuitevisueldeloin.oielDroit.sphere = ''
    this.myExamen.refraction.acuitevisueldeloin.oielDroit.cylindre !== undefined ? this.myExamen.refraction.acuitevisueldeloin.oielDroit.cylindre : this.myExamen.refraction.acuitevisueldeloin.oielDroit.cylindre = ''
    this.myExamen.refraction.acuitevisueldeloin.oielDroit.axe !== undefined ? this.myExamen.refraction.acuitevisueldeloin.oielDroit.axe : this.myExamen.refraction.acuitevisueldeloin.oielDroit.axe = ''
    this.myExamen.refraction.acuitevisueldeloin.oielDroit.avac !== undefined ? this.myExamen.refraction.acuitevisueldeloin.oielDroit.avac : this.myExamen.refraction.acuitevisueldeloin.oielDroit.avac = ''
    this.myExamen.refraction.acuitevisueldeloin.oielDroit.avsc !== undefined ? this.myExamen.refraction.acuitevisueldeloin.oielDroit.avsc : this.myExamen.refraction.acuitevisueldeloin.oielDroit.avsc = ''
    this.myExamen.refraction.acuitevisueldeloin.oielGauche.sphere !== undefined ? this.myExamen.refraction.acuitevisueldeloin.oielGauche.sphere : this.myExamen.refraction.acuitevisueldeloin.oielGauche.sphere = ''
    this.myExamen.refraction.acuitevisueldeloin.oielGauche.cylindre !== undefined ? this.myExamen.refraction.acuitevisueldeloin.oielGauche.cylindre : this.myExamen.refraction.acuitevisueldeloin.oielGauche.cylindre = ''
    this.myExamen.refraction.acuitevisueldeloin.oielGauche.axe !== undefined ? this.myExamen.refraction.acuitevisueldeloin.oielGauche.axe : this.myExamen.refraction.acuitevisueldeloin.oielGauche.axe = ''
    this.myExamen.refraction.acuitevisueldeloin.oielGauche.avac !== undefined ? this.myExamen.refraction.acuitevisueldeloin.oielGauche.avac : this.myExamen.refraction.acuitevisueldeloin.oielGauche.avac = ''
    this.myExamen.refraction.acuitevisueldeloin.oielGauche.avsc !== undefined ? this.myExamen.refraction.acuitevisueldeloin.oielGauche.avsc : this.myExamen.refraction.acuitevisueldeloin.oielGauche.avsc = ''


    this.myExamen.refraction.acuitevisueldepres.oielDroit.sphere !== undefined ? this.myExamen.refraction.acuitevisueldepres.oielDroit.sphere : this.myExamen.refraction.acuitevisueldepres.oielDroit.sphere = ''
    this.myExamen.refraction.acuitevisueldepres.oielDroit.cylindre !== undefined ? this.myExamen.refraction.acuitevisueldepres.oielDroit.cylindre : this.myExamen.refraction.acuitevisueldepres.oielDroit.cylindre = ''
    this.myExamen.refraction.acuitevisueldepres.oielDroit.axe !== undefined ? this.myExamen.refraction.acuitevisueldepres.oielDroit.axe : this.myExamen.refraction.acuitevisueldepres.oielDroit.axe = ''
    this.myExamen.refraction.acuitevisueldepres.oielGauche.sphere !== undefined ? this.myExamen.refraction.acuitevisueldepres.oielGauche.sphere : this.myExamen.refraction.acuitevisueldepres.oielGauche.sphere = ''
    this.myExamen.refraction.acuitevisueldepres.oielGauche.cylindre !== undefined ? this.myExamen.refraction.acuitevisueldepres.oielGauche.cylindre : this.myExamen.refraction.acuitevisueldepres.oielGauche.cylindre = ''
    this.myExamen.refraction.acuitevisueldepres.oielGauche.axe !== undefined ? this.myExamen.refraction.acuitevisueldepres.oielGauche.axe : this.myExamen.refraction.acuitevisueldepres.oielGauche.axe = ''
    // -------end  Reinialisation des valeurs lorsque ils sont 'undefined'
    this.myExamen.refraction.acuitevisueldepres.oielGauche.add !== undefined && this.myExamen.refraction.acuitevisueldepres.oielGauche.add > 0 ? this.myExamen.refraction.acuitevisueldepres.oielGauche.add = "+" + this.myExamen.refraction.acuitevisueldepres.oielGauche.add : this.myExamen.refraction.acuitevisueldepres.oielGauche.add = this.myExamen.refraction.acuitevisueldepres.oielGauche.add
    this.myExamen.refraction.acuitevisueldepres.oielDroit.add !== undefined && this.myExamen.refraction.acuitevisueldepres.oielDroit.add > 0 ? this.myExamen.refraction.acuitevisueldepres.oielDroit.add = "+" + this.myExamen.refraction.acuitevisueldepres.oielDroit.add : this.myExamen.refraction.acuitevisueldepres.oielDroit.add = this.myExamen.refraction.acuitevisueldepres.oielDroit.add

    prescrireVerreModel.deLoin.oielDroit.sphere = this.myExamen.refraction.acuitevisueldeloin.oielDroit.sphere
    prescrireVerreModel.deLoin.oielDroit.cylindre = this.myExamen.refraction.acuitevisueldeloin.oielDroit.cylindre
    prescrireVerreModel.deLoin.oielDroit.axe = this.myExamen.refraction.acuitevisueldeloin.oielDroit.axe
    prescrireVerreModel.deLoin.oielGauche.sphere = this.myExamen.refraction.acuitevisueldeloin.oielGauche.sphere
    prescrireVerreModel.deLoin.oielGauche.cylindre = this.myExamen.refraction.acuitevisueldeloin.oielGauche.cylindre
    prescrireVerreModel.deLoin.oielGauche.axe = this.myExamen.refraction.acuitevisueldeloin.oielGauche.axe


    prescrireVerreModel.dePres.oielDroit.sphere = this.myExamen.refraction.acuitevisueldepres.oielDroit.sphere
    prescrireVerreModel.dePres.oielDroit.cylindre = this.myExamen.refraction.acuitevisueldepres.oielDroit.cylindre
    prescrireVerreModel.dePres.oielDroit.axe = this.myExamen.refraction.acuitevisueldepres.oielDroit.axe
    prescrireVerreModel.dePres.oielDroit.add = this.myExamen.refraction.acuitevisueldepres.oielDroit.add
    prescrireVerreModel.dePres.oielGauche.sphere = this.myExamen.refraction.acuitevisueldepres.oielGauche.sphere
    prescrireVerreModel.dePres.oielGauche.cylindre = this.myExamen.refraction.acuitevisueldepres.oielGauche.cylindre
    prescrireVerreModel.dePres.oielGauche.axe = this.myExamen.refraction.acuitevisueldepres.oielGauche.axe
    prescrireVerreModel.dePres.oielGauche.add = this.myExamen.refraction.acuitevisueldepres.oielGauche.add

    // certificat D'aptitude à la conduite
    certificataptitudepermisModel.date = this.myExamen.cat.certificataptitudepermis.date
    certificataptitudepermisModel.oeilDroit.acuiteVisuelSansCorrection = this.myExamen.refraction.acuitevisueldeloin.oielDroit.avsc
    certificataptitudepermisModel.oeilDroit.acuiteVisuelAvecCorrection = this.myExamen.refraction.acuitevisueldeloin.oielDroit.avac
    certificataptitudepermisModel.oeilGauche.acuiteVisuelSansCorrection = this.myExamen.refraction.acuitevisueldeloin.oielGauche.avsc
    certificataptitudepermisModel.oeilGauche.acuiteVisuelAvecCorrection = this.myExamen.refraction.acuitevisueldeloin.oielGauche.avac

    // Prescrire lentille

    let convertedValueDroit = conversionTable.find(el => parseFloat(el.sphLunette) === parseFloat(this.myExamen.refraction.acuitevisueldeloin.oielDroit.sphere))
    if (convertedValueDroit) {
      prescrireLentilleModel.oielDroit.sphere = convertedValueDroit.SphLentille
    } else {
      prescrireLentilleModel.oielDroit.sphere = this.myExamen.refraction.acuitevisueldeloin.oielDroit.sphere
    }


    prescrireLentilleModel.oielDroit.cylindre = this.myExamen.refraction.acuitevisueldeloin.oielDroit.cylindre
    prescrireLentilleModel.oielDroit.axe = this.myExamen.refraction.acuitevisueldeloin.oielDroit.axe

    let convertedValueGauche = conversionTable.find(el => parseFloat(el.sphLunette) === parseFloat(this.myExamen.refraction.acuitevisueldeloin.oielGauche.sphere))
    if (convertedValueGauche) {
      prescrireLentilleModel.oielGauche.sphere = convertedValueGauche.SphLentille
    } else {
      prescrireLentilleModel.oielGauche.sphere = this.myExamen.refraction.acuitevisueldeloin.oielGauche.sphere
    }

    prescrireLentilleModel.oielGauche.cylindre = this.myExamen.refraction.acuitevisueldeloin.oielGauche.cylindre
    prescrireLentilleModel.oielGauche.axe = this.myExamen.refraction.acuitevisueldeloin.oielGauche.axe



    this.prescrireVerre = prescrireVerreModel
    this.prescrireLentille = prescrireLentilleModel
    this.prescrireOrdo = {
      "consigne": "",
      "informationAdditionnel": "",
      "listDrugs": []
    }
    this.prescrireBoBilanOrtho = {
      "date": new Date(),
      "motifs": []
    }
    this.chirurgie = chirurgieModel
    this.examenComplementaire = examenComplementaireModel
    this.prescrireLaser = prescrireLaserModel
    this.certificatMedical = {
      date: new Date(),
      types: "",
      nombre: "",
      compterDe: "",
    }
    this.certificataptitudepermis = certificataptitudepermisModel
    this.lettreConfrere = {
      "date": new Date(),
      "medecin": "",
      "contenue": "",
    }
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.prescrireVerre && this.myExamen.cat.prescrireVerre.length > 0) {
      this.prescrireVerre = this.myExamen.cat.prescrireVerre[0]
    }
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.prescrireLentille && this.myExamen.cat.prescrireLentille.length > 0) {
      this.prescrireLentille = this.myExamen.cat.prescrireLentille[0]
    }
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.prescrireOrdo && this.myExamen.cat.prescrireOrdo.length > 0) {
      this.prescrireOrdo = this.myExamen.cat.prescrireOrdo[0]
    }
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.prescrireBoBilanOrtho && this.myExamen.cat.prescrireBoBilanOrtho.length > 0) {
      this.prescrireBoBilanOrtho = this.myExamen.cat.prescrireBoBilanOrtho[0]
    }
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.chirurgie && this.myExamen.cat.chirurgie.length > 0) {
      this.chirurgie = this.myExamen.cat.chirurgie[0]
    }
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.examenComplementaire && this.myExamen.cat.examenComplementaire.length > 0) {
      this.examenComplementaire = this.myExamen.cat.examenComplementaire[0]
    }
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.prescrireLaser && this.myExamen.cat.prescrireLaser.length > 0) {
      this.prescrireLaser = this.myExamen.cat.prescrireLaser[0]
    }
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.certificatMedical && this.myExamen.cat.certificatMedical.length > 0) {
      this.certificatMedical = this.myExamen.cat.certificatMedical[0]
    }
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.certificataptitudepermis && this.myExamen.cat.certificataptitudepermis.length > 0) {
      this.certificataptitudepermis = this.myExamen.cat.certificataptitudepermis[0]
    }
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.lettreConfrere && this.myExamen.cat.lettreConfrere.length > 0) {
      this.lettreConfrere = this.myExamen.cat.lettreConfrere[0]
      if (!this.lettreConfrere.medecin.prenom) {
        this.nom = this.lettreConfrere.medecin.nom + ' ' + this.lettreConfrere.medecin.specialite
      }
      if (this.lettreConfrere.medecin.prenom) {
        this.nom = this.lettreConfrere.medecin.nom + ' ' + this.lettreConfrere.medecin.prenom + ' ' + this.lettreConfrere.medecin.specialite
      }
    }
    if (!this.myExamen.refraction.acuitevisueldepres.oielDroit.add) {
      prescrireVerreModel.dePres.oielDroit.add = 0
    }
    if (!this.myExamen.refraction.acuitevisueldepres.oielGauche.add) {
      prescrireVerreModel.dePres.oielGauche.add = 0
    }
    this.medicamentsService.getAlluniqueNomMedicaments(this.myExamen.createdByDoctor).subscribe((medcments: any[]) => {
      this.medicamentsData = medcments
    },
      err => {
        return false;
      });
  }
  showAndHidePrescire(type) {
    if (type == "Prescrire des lunettes") {
      this.isActivePrescrireDesVerres = !this.isActivePrescrireDesVerres;
    }
    if (type == "Prescrire des lentilles") {
      this.isActivePrescrireDesLentilles = !this.isActivePrescrireDesLentilles;
    }
    if (type == "Prescrire une ordonnance") {
      this.isActivePrescrireOrdo = !this.isActivePrescrireOrdo;
    }
    if (type == "Prescrire une chirurgie") {
      this.isActiveChirurgie = !this.isActiveChirurgie;
    }
    if (type == "Prescrire des examens complémentaires") {
      this.isActiveExamenComplementaire = !this.isActiveExamenComplementaire;
    }
    if (type == "Prescrire un laser") {
      this.isActivePrescrireLasers = !this.isActivePrescrireLasers;
    }
    if (type == "Prescrire un bilan orthoptique") {
      this.isActivePrescrireBObilanorthoptique = !this.isActivePrescrireBObilanorthoptique;
    }
    if (type == "Prescrire un certificat médical") {
      this.isActiveCertificatMedical = !this.isActiveCertificatMedical;
    }
    if (type == "Prescrire un certificat d'aptitude à la conduite") {
      this.isActiveCertificataptitudePermis = !this.isActiveCertificataptitudePermis;
    }
    if (type == "Prescrire une lettre pour confrère") {
      this.isActiveLettreConfrere = !this.isActiveLettreConfrere;
    }
  }
  // ----------------
  showTraitement() {
    window.scroll(0, 0)
    this.isActiveTraitement = true
    this.isActiveConclusion = false
    this.isActiveCatetProchainConsul = false
  }
  showConclusion() {
    window.scroll(0, 0)
    this.isActiveTraitement = false
    this.isActiveConclusion = true
    this.isActiveCatetProchainConsul = false
    this.choosedTag = null
  }
  showCatetProchainConsul() {
    window.scroll(0, 0)
    this.isActiveTraitement = false
    this.isActiveConclusion = false
    this.isActiveCatetProchainConsul = true
    this.choosedTag = null
  }
  chooseTag(tag) {
    this.choosedTag = tag
  }

  onChirurgieTypeChange() {
    if (this.selectedChirugieType && !this.selectedChirugieTypes.includes(this.selectedChirugieType)) {
      this.selectedChirugieTypes.push(this.selectedChirugieType);
    }
  }


  //  ----------------------------end chirigue functions
  //  ----------------------------satrt Correction optique functions

  onCorrectionOptiqueChange() {
    if (this.selectedCorrectionOptique && !this.listselectedCorrectionOptique.includes(this.selectedCorrectionOptique)) {
      this.listselectedCorrectionOptique.push(this.selectedCorrectionOptique);
    }
  }
  removeCorrectionOptique(item) {
    const index = this.listselectedCorrectionOptique.indexOf(item);
    if (index !== -1) {
      this.listselectedCorrectionOptique.splice(index, 1);
      this.selectedCorrectionOptique = null; // Clear selected item after removal
    }
  }

  selectEchelleMoyner() {
    this.echelleMoyner = true
    this.echelleSnellen = false
  }
  selectEchelleSnellen() {
    this.echelleMoyner = false
    this.echelleSnellen = true
  }
  isToastVisible = false
  // end code reffaction
  // start code CAT
  updateSpecificDiagnostic(event) {
    const value = event.target.value.trim(); // Trim the value to avoid issues with leading/trailing spaces
    const index = this.myExamen.additionalConclusion.indexOf(value);

    if (event.key === 'Enter') {
      if (!value || value.length <= 3 || index !== -1) {
        let msg = '';
        if (!value) {
          msg = champMinLength;
        } else if (value.length <= 3) {
          msg = champMinLength;
        } else if (index !== -1) {
          msg = 'Le diagnostic spécifique est déjà présent.';
        }

        if (!this.isToastVisible) {
          this.isToastVisible = true;
          const self = this;
          this.iziToast.show({
            message: msg,
            messageColor: '#800f2f',
            titleColor: '#800f2f',
            progressBarColor: '#c9184a',
            position: 'topRight',
            timeout: 5000,
            backgroundColor: '#ff8fa3',
            transitionIn: 'flipInX',
            transitionOut: 'flipOutX',
            overlay: true,
            overlayClose: true,
            onClosed: function () {
              self.isToastVisible = false;
            }
          });
        }
        event.preventDefault(); // Prevent default behavior (submitting form)
        event.target.value = '';
        return false;
      } else {
        this.myExamen.additionalConclusion.push(value);

        event.target.value = '';
      }
    }

    this.objecttoSend.source = "haveSpecificDiagnostic"
    this.objecttoSend.haveSpecificDiagnostic = this.myExamen.haveSpecificDiagnostic
    this.objecttoSend.additionalConclusion = this.myExamen.additionalConclusion
    this.sharedDataService.updateData(this.objecttoSend);
    return false;
  }
  removeDiagnostic(item) {
    const index = this.myExamen.additionalConclusion.indexOf(item);
    if (index !== -1) {
      this.myExamen.additionalConclusion.splice(index, 1);
      this.objecttoSend.source = "haveSpecificDiagnostic"
      this.objecttoSend.haveSpecificDiagnostic = this.myExamen.haveSpecificDiagnostic
      this.objecttoSend.additionalConclusion = this.myExamen.additionalConclusion
      this.sharedDataService.updateData(this.objecttoSend);
    }
  }
  onCatChange() {
    this.isActivePrescrireDesVerres = false;
    this.isActivePrescrireDesLentilles = false;
    this.isActivePrescrireOrdo = false
    this.isActivePrescrireBObilanorthoptique = false;
    this.isActiveChirurgie = false;
    this.isActiveExamenComplementaire = false;
    this.isActivePrescrireLasers = false;
    this.isActiveCertificatMedical = false;
    this.isActiveCertificataptitudePermis = false;
    this.isActiveLettreConfrere = false;
    if (this.selectedCat && !this.myExamen.tags.includes(this.selectedCat)) {
      this.myExamen.tags.push(this.selectedCat);
      this.objecttoSend.source = "tags"
      this.objecttoSend.cat = this.myExamen.tags
      this.sharedDataService.updateData(this.objecttoSend);
      if (this.selectedCat == "Prescrire des lunettes") {
        this.prescrireVerre = prescrireVerreModel
        this.prescrireVerre.titrePrecrire = this.selectedCat
        this.myExamen.cat.prescrireVerre[0] = this.prescrireVerre
        this.isActivePrescrireDesVerres = true;
      }
      if (this.selectedCat == "Prescrire des lentilles") {
        this.prescrireLentille = prescrireLentilleModel
        this.prescrireLentille.titrePrecrire = this.selectedCat
        this.myExamen.cat.prescrireLentille[0] = this.prescrireLentille
        this.isActivePrescrireDesLentilles = true;
      }
      if (this.selectedCat == "Prescrire une ordonnance") {
        this.prescrireOrdo = {
          "consigne": "",
          "informationAdditionnel": "",
          "listDrugs": []
        }
        this.prescrireOrdo.titrePrecrire = this.selectedCat
        this.myExamen.cat.prescrireOrdo[0] = this.prescrireOrdo
        this.isActivePrescrireOrdo = true;
      }
      if (this.selectedCat == "Prescrire une chirurgie") {
        this.chirurgie = chirurgieModel
        this.chirurgie.titrePrecrire = this.selectedCat
        this.myExamen.cat.chirurgie[0] = this.chirurgie
        this.isActiveChirurgie = true;
      }
      if (this.selectedCat == "Prescrire des examens complémentaires") {
        this.examenComplementaire = examenComplementaireModel
        this.examenComplementaire.titrePrecrire = this.selectedCat
        this.myExamen.cat.examenComplementaire[0] = this.examenComplementaire
        this.isActiveExamenComplementaire = true;
      }
      if (this.selectedCat == "Prescrire un laser") {
        this.prescrireLaser = prescrireLaserModel
        this.prescrireLaser.titrePrecrire = this.selectedCat
        this.myExamen.cat.prescrireLaser[0] = this.prescrireLaser
        this.isActivePrescrireLasers = true;
      }
      if (this.selectedCat == "Prescrire un bilan orthoptique") {
        this.prescrireBoBilanOrtho = {
          "date": new Date(),
          "motifs": []
        }
        this.prescrireBoBilanOrtho.titrePrecrire = this.selectedCat
        this.myExamen.cat.prescrireBoBilanOrtho[0] = this.prescrireBoBilanOrtho
        this.isActivePrescrireBObilanorthoptique = true;
      }
      if (this.selectedCat == "Prescrire un certificat médical") {
        this.certificatMedical = {
          date: new Date(),
          types: "",
          nombre: "",
          compterDe: "",
        }
        this.certificatMedical.titrePrecrire = this.selectedCat
        this.myExamen.cat.certificatMedical[0] = this.certificatMedical
        this.isActiveCertificatMedical = true;
      }
      if (this.selectedCat == "Prescrire un certificat d'aptitude à la conduite") {
        this.certificataptitudepermis = certificataptitudepermisModel
        this.certificataptitudepermis.titrePrecrire = this.selectedCat
        this.myExamen.cat.certificataptitudepermis[0] = this.certificataptitudepermis
        this.isActiveCertificataptitudePermis = true;
      }
      if (this.selectedCat == "Prescrire une lettre pour confrère") {
        this.lettreConfrere = {
          "date": new Date(),
          "medecin": "",
          "contenue": "",
        }
        this.lettreConfrere.titrePrecrire = this.selectedCat
        this.myExamen.cat.lettreConfrere[0] = this.lettreConfrere
        this.isActiveLettreConfrere = true;
      }
    }
  }
  removeCat(item) {
    const index = this.myExamen.tags.indexOf(item);
    if (index !== -1) {
      this.myExamen.tags.splice(index, 1);
      this.selectedCat = null; // Clear selected item after removal
      this.objecttoSend.cat = this.myExamen.tags
      this.objecttoSend.source = "tags"
      this.sharedDataService.updateData(this.objecttoSend);
      if (item == "Prescrire des lunettes") {
        this.myExamen.cat.prescrireVerre = []
        while (this.myExamen.cat.prescrireVerre.length > 0) {
          this.myExamen.cat.prescrireVerre.pop()
        }
        this.isActivePrescrireDesVerres = false;
      }
      if (item == "Prescrire des lentilles") {
        this.myExamen.cat.prescrireLentille = []
        while (this.myExamen.cat.prescrireLentille.length > 0) {
          this.myExamen.cat.prescrireLentille.pop()
        }
        this.isActivePrescrireDesLentilles = false;
      }
      if (item == "Prescrire une ordonnance") {
        this.myExamen.cat.prescrireOrdo = []
        while (this.myExamen.cat.prescrireOrdo.length > 0) {
          this.myExamen.cat.prescrireOrdo.pop()
        }
        this.isActivePrescrireOrdo = false;
      }
      if (item == "Prescrire une chirurgie") {
        this.myExamen.cat.chirurgie = []
        while (this.myExamen.cat.chirurgie.length > 0) {
          this.myExamen.cat.chirurgie.pop()
        }
        this.isActiveChirurgie = false;
      }
      if (item == "Prescrire des examens complémentaires") {
        this.myExamen.cat.examenComplementaire = []
        while (this.myExamen.cat.examenComplementaire.length > 0) {
          this.myExamen.cat.examenComplementaire.pop()
        }
        this.isActiveExamenComplementaire = false;
      }
      if (item == "Prescrire un laser") {
        this.myExamen.cat.prescrireLaser = []
        while (this.myExamen.cat.prescrireLaser.length > 0) {
          this.myExamen.cat.prescrireLaser.pop()
        }
        this.isActivePrescrireLasers = false;
      }
      if (item == "Prescrire un bilan orthoptique") {
        this.myExamen.cat.prescrireBoBilanOrtho = []
        while (this.myExamen.cat.prescrireBoBilanOrtho.length > 0) {
          this.myExamen.cat.prescrireBoBilanOrtho.pop()
        }
        this.isActivePrescrireBObilanorthoptique = false;
      }
      if (item == "Prescrire un certificat médical") {
        this.myExamen.cat.certificatMedical = []
        while (this.myExamen.cat.certificatMedical.length > 0) {
          this.myExamen.cat.certificatMedical.pop()
        }
        this.isActiveCertificatMedical = false;
      }
      if (item == "Prescrire un certificat d'aptitude à la conduite") {
        this.myExamen.cat.certificataptitudepermis = []
        while (this.myExamen.cat.certificataptitudepermis.length > 0) {
          this.myExamen.cat.certificataptitudepermis.pop()
        }
        this.isActiveCertificataptitudePermis = false;
      }
      if (item == "Prescrire une lettre pour confrère") {
        this.myExamen.cat.lettreConfrere = []
        while (this.myExamen.cat.lettreConfrere.length > 0) {
          this.myExamen.cat.lettreConfrere.pop()
        }
        this.isActiveLettreConfrere = false;
      }
    }
  }

  //
  createDrugsControl() {
    return this.fb.group({
      nom: '',
      duree: '',
      posology: '',
      oeilDroit: false,
      oeilGauche: false
    });
  }
  // Getter for easy access to the FormArray
  get listDrugs() {
    return this.drugsForm.get('listDrugs') as FormArray;
  }
  // Add a new email control to the FormArray
  addDrug() {
    this.listDrugs.push(this.createDrugsControl());
  }
  // !----------------------------------------------------------------------------------------------------------
  onTypeClassChange(index: number) {
    const selectedTypeClass = this.selectedTypeClasses[index];  // Get the selected type class for the current row
    const selectedData = this.medicamentsList[selectedTypeClass];  // Get the data for the selected type class

    if (selectedTypeClass === "None") {
      this.medicamentsPerRow[index] = null;
      this.categoriesPerRow[index] = null;
      ((this.drugsForm.get('listDrugs') as FormArray).at(index) as FormGroup).get('duree').patchValue(null);
      ((this.drugsForm.get('listDrugs') as FormArray).at(index) as FormGroup).get('posology').patchValue(null);
    } else {
      if (selectedData) {
        if (selectedData.medicaments) {
          this.medicamentsPerRow[index] = selectedData.medicaments.sort();
          this.categoriesPerRow[index] = null; // Reset categories if only medications are available
        } else {
          this.medicamentsPerRow[index] = null; // Reset medications
          this.categoriesPerRow[index] = Object.keys(selectedData).filter(key =>
            selectedData[key].medicaments && selectedData[key].medicaments.length > 0
          ).sort();
        }
        ((this.drugsForm.get('listDrugs') as FormArray).at(index) as FormGroup).get('duree').patchValue(selectedData.duree);
        ((this.drugsForm.get('listDrugs') as FormArray).at(index) as FormGroup).get('posology').patchValue(selectedData.posologie);
      } else {
        this.medicamentsPerRow[index] = null;
        this.categoriesPerRow[index] = null;
      }

      this.selectedCategories[index] = null; // Reset the selected category for this row
      ((this.drugsForm.get('listDrugs') as FormArray).at(index) as FormGroup).get('nom').patchValue(null);
    }
  }


  onCategoryChange(index: number) {
    const selectedTypeClass = this.selectedTypeClasses[index];  // Get the type class for the current row
    const selectedCategory = this.selectedCategories[index];    // Get the selected category for the current row

    if (selectedCategory && selectedTypeClass) {
      // Load medications for the selected category and store them in the medicamentsPerRow array
      this.medicamentsPerRow[index] = this.medicamentsList[selectedTypeClass][selectedCategory].medicaments.sort();

      // Clear the selected medication for the current index
      ((this.drugsForm.get('listDrugs') as FormArray).at(index) as FormGroup).get('nom').patchValue(null);
    }
  }


  // !----------------------------------------------------------------------------------------------------------
  // Remove an email control from the FormArray
  removeDrug(index: number) {
    this.listDrugs.removeAt(index);
  }
  removeOldDrug(j) {
    this.prescrireOrdo.listDrugs.splice(j, 1);
  }

  // end code CAT
  // update examen
  updateExamen() {
    const showIziToast = (message, messageColor, progressBarColor, backgroundColor) => {
      this.iziToast.show({
        message: message,
        messageColor: messageColor,
        titleColor: messageColor,
        progressBarColor: progressBarColor,
        position: 'topRight',
        timeout: 5000,
        backgroundColor: backgroundColor,
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
    };

    if (this.myExamen.nextAppointment < this.minDate) {
      showIziToast('Merci de vérifier la date de prochain rendez vous', '#800f2f', '#c9184a', '#ff8fa3');
      return false;
    }

    if (this.myExamen?.cat?.lettreConfrere?.length > 0 && !this.myExamen.cat.lettreConfrere[0].medecin) {
      showIziToast('Merci de choisir un médecin pour la lettre', '#800f2f', '#c9184a', '#ff8fa3');
      return false;
    }

    this.examenService.updateExamen(this.myExamen).subscribe(
      (_patient: any) => {
        this.examenService.getExamenByIdForConclusion(this.myExamen.id).subscribe(
          (data: any) => {
            this.myExamen = data;
            if (this.myExamen) {
              showIziToast('Bien enregistré', '#386641', '#6a994e', '#dde5b6');
              this.configureDataExamen();
              return true;
            }
          },
          err => false
        );
      }
    );

    return true;
  }

  // search objetc ijn mutiple array 

  searchAndRemoveInMultipleArrays(arrays, targetName) {
    let result = [];

    for (let array of arrays) {
      // Search in each array
      let indexToRemove = -1;

      for (let i = 0; i < array.length; i++) {
        let obj = array[i];
        if (obj.titrePrecrire === targetName) {
          result.push(obj);
          indexToRemove = i;
          break; // Stop searching once the first match is found
        }
      }

      // Remove the object with the specified name from the current array
      if (indexToRemove !== -1) {
        array.splice(indexToRemove, 1);
      }
    }

    return result;
  }

  // data cat 

  //! ------------start Prescrire verre functions--------//
  // todo--- Acuite visuel de loin
  //todo----- Sphére  
  changeLoinODSph(value) {
    const acuiteVisuelLoin = this.prescrireVerre.deLoin.oielDroit;
    const acuiteVisuelPres = this.prescrireVerre.dePres.oielDroit;

    // Parse value once
    let sphereLoin = parseFloat(value);

    if (sphereLoin === 0) {
      acuiteVisuelLoin.sphere = "Plan";
      acuiteVisuelPres.sphere = parseFloat(acuiteVisuelPres.add);

      if (acuiteVisuelPres.sphere > 0) {
        acuiteVisuelPres.sphere = `+${acuiteVisuelPres.sphere}`;
      } else if (acuiteVisuelPres.sphere === 0) {
        acuiteVisuelPres.sphere = "Plan"
      }
      return;
    }

    // Handle non-numeric or NaN cases
    if (isNaN(sphereLoin)) {
      acuiteVisuelLoin.sphere = value;
      return;
    }

    // Handle numeric cases
    if (sphereLoin > 0) {
      acuiteVisuelLoin.sphere = `+${sphereLoin}`;
    } else {
      acuiteVisuelLoin.sphere = sphereLoin;
    }

    acuiteVisuelPres.sphere = sphereLoin + parseFloat(acuiteVisuelPres.add);
    if (acuiteVisuelPres.sphere === 0) {
      acuiteVisuelPres.sphere = "Plan";
    } else if (acuiteVisuelPres.sphere > 0) {
      acuiteVisuelPres.sphere = `+${acuiteVisuelPres.sphere}`;
    }
  }
  incrementLoinODSph() {
    // Parse values as floats, initializing as 0 if parsing fails
    let acuiteVisuelLoinSphere = parseFloat(this.prescrireVerre.deLoin.oielDroit.sphere) || 0;
    const add = parseFloat(this.prescrireVerre.dePres.oielDroit.add) || 0;

    // Check if the current sphere value is "Plan"
    if (this.prescrireVerre.deLoin.oielDroit.sphere === "Plan") {
      acuiteVisuelLoinSphere = 0;
    }

    if (isNaN(acuiteVisuelLoinSphere) || acuiteVisuelLoinSphere === undefined) {
      acuiteVisuelLoinSphere = 0;
    }

    acuiteVisuelLoinSphere += 0.25;
    let updatedSpherePres = acuiteVisuelLoinSphere + add;

    // Format the values
    let formattedSphereLoin;
    let formattedSpherePres;

    // Format loin sphere value
    if (acuiteVisuelLoinSphere === 0) {
      formattedSphereLoin = "Plan";
    } else {
      formattedSphereLoin = acuiteVisuelLoinSphere > 0
        ? `+${acuiteVisuelLoinSphere}`
        : `${acuiteVisuelLoinSphere}`;
    }

    // Format pres sphere value
    formattedSpherePres = updatedSpherePres === 0
      ? "Plan"
      : updatedSpherePres > 0
        ? `+${updatedSpherePres}`
        : `${updatedSpherePres}`;

    // Update the original object properties
    this.prescrireVerre.deLoin.oielDroit.sphere = formattedSphereLoin;
    this.prescrireVerre.dePres.oielDroit.sphere = formattedSpherePres;

  }
  decrementLoinODSph() {
    // Convert current sphere to a float, with fallback to 0 if conversion fails
    let loinSphere = parseFloat(this.prescrireVerre.deLoin.oielDroit.sphere) || 0;
    let presSphere = parseFloat(this.prescrireVerre.dePres.oielDroit.sphere) || 0;
    const add = parseFloat(this.prescrireVerre.dePres.oielDroit.add) || 0;

    // Check if the current sphere value is "Plan" and convert it back to 0 for operations
    if (this.prescrireVerre.deLoin.oielDroit.sphere === "Plan") {
      loinSphere = 0;
    }

    // Decrement the sphere value
    loinSphere -= 0.25;

    // Update loinSphere value after decrement
    if (isNaN(loinSphere)) {
      loinSphere = 0;
    }

    // Calculate presSphere based on the updated loinSphere
    presSphere = loinSphere + add;

    // Set 'sphere' to "Plan" if it becomes exactly 0 after decrementing
    let formattedLoinSphere = loinSphere === 0 ? "Plan" : loinSphere > 0 ? `+${loinSphere}` : `${loinSphere}`;
    let formattedPresSphere = presSphere === 0 ? "Plan" : presSphere > 0 ? `+${presSphere}` : `${presSphere}`;

    // Update the original object properties
    this.prescrireVerre.deLoin.oielDroit.sphere = formattedLoinSphere;
    this.prescrireVerre.dePres.oielDroit.sphere = formattedPresSphere;
  }

  changeLoinOGSph(value) {
    const acuiteVisuelLoin = this.prescrireVerre.deLoin.oielGauche;
    const acuiteVisuelPres = this.prescrireVerre.dePres.oielGauche;

    // Parse value once
    let sphereLoin = parseFloat(value);

    if (sphereLoin === 0) {
      acuiteVisuelLoin.sphere = "Plan";
      acuiteVisuelPres.sphere = parseFloat(acuiteVisuelPres.add);

      if (acuiteVisuelPres.sphere > 0) {
        acuiteVisuelPres.sphere = `+${acuiteVisuelPres.sphere}`;
      } else if (acuiteVisuelPres.sphere === 0) {
        acuiteVisuelPres.sphere = "Plan"
      }
      return;
    }

    // Handle non-numeric or NaN cases
    if (isNaN(sphereLoin)) {
      acuiteVisuelLoin.sphere = value;
      return;
    }

    // Handle numeric cases
    if (sphereLoin > 0) {
      acuiteVisuelLoin.sphere = `+${sphereLoin}`;
    } else {
      acuiteVisuelLoin.sphere = sphereLoin;
    }

    acuiteVisuelPres.sphere = sphereLoin + parseFloat(acuiteVisuelPres.add);

    if (acuiteVisuelPres.sphere === 0) {
      acuiteVisuelPres.sphere = "Plan";
    } else if (acuiteVisuelPres.sphere > 0) {
      acuiteVisuelPres.sphere = `+${acuiteVisuelPres.sphere}`;
    }
  }
  incrementLoinOGSph() {
    // Parse values as floats, initializing as 0 if parsing fails
    let acuiteVisuelLoinSphere = parseFloat(this.prescrireVerre.deLoin.oielGauche.sphere) || 0;
    const add = parseFloat(this.prescrireVerre.dePres.oielGauche.add) || 0;

    // Check if the current sphere value is "Plan"
    if (this.prescrireVerre.deLoin.oielGauche.sphere === "Plan") {
      acuiteVisuelLoinSphere = 0;
    }

    if (isNaN(acuiteVisuelLoinSphere) || acuiteVisuelLoinSphere === undefined) {
      acuiteVisuelLoinSphere = 0;
    }

    acuiteVisuelLoinSphere += 0.25;
    let updatedSpherePres = acuiteVisuelLoinSphere + add;

    // Format the values
    let formattedSphereLoin;
    let formattedSpherePres;

    // Format loin sphere value
    if (acuiteVisuelLoinSphere === 0) {
      formattedSphereLoin = "Plan";
    } else {
      formattedSphereLoin = acuiteVisuelLoinSphere > 0
        ? `+${acuiteVisuelLoinSphere}`
        : `${acuiteVisuelLoinSphere}`;
    }

    // Format pres sphere value
    formattedSpherePres = updatedSpherePres === 0
      ? "Plan"
      : updatedSpherePres > 0
        ? `+${updatedSpherePres}`
        : `${updatedSpherePres}`;

    // Update the original object properties
    this.prescrireVerre.deLoin.oielGauche.sphere = formattedSphereLoin;
    this.prescrireVerre.dePres.oielGauche.sphere = formattedSpherePres;
  }
  decrementLoinOGSph() {
    // Convert current sphere to a float, with fallback to 0 if conversion fails
    let loinSphere = parseFloat(this.prescrireVerre.deLoin.oielGauche.sphere) || 0;
    let presSphere = parseFloat(this.prescrireVerre.dePres.oielGauche.sphere) || 0;
    const add = parseFloat(this.prescrireVerre.dePres.oielGauche.add) || 0;

    // Check if the current sphere value is "Plan" and convert it back to 0 for operations
    if (this.prescrireVerre.deLoin.oielGauche.sphere === "Plan") {
      loinSphere = 0;
    }

    // Decrement the sphere value
    loinSphere -= 0.25;

    // Update loinSphere value after decrement
    if (isNaN(loinSphere)) {
      loinSphere = 0;
    }

    // Calculate presSphere based on the updated loinSphere
    presSphere = loinSphere + add;

    // Set 'sphere' to "Plan" if it becomes exactly 0 after decrementing
    let formattedLoinSphere = loinSphere === 0 ? "Plan" : loinSphere > 0 ? `+${loinSphere}` : `${loinSphere}`;
    let formattedPresSphere = presSphere === 0 ? "Plan" : presSphere > 0 ? `+${presSphere}` : `${presSphere}`;

    // Update the original object properties
    this.prescrireVerre.deLoin.oielGauche.sphere = formattedLoinSphere;
    this.prescrireVerre.dePres.oielGauche.sphere = formattedPresSphere;
  }
  //todo----- end of Sphére
  //todo-----  Cylindre  
  changeLoinODCy(value) {
    const acuiteVisuelLoin = this.prescrireVerre.deLoin.oielDroit;
    const acuiteVisuelPres = this.prescrireVerre.dePres.oielDroit;

    // Parse value once
    let cylindreLoin = parseFloat(value);

    if (cylindreLoin === 0) {
      acuiteVisuelLoin.cylindre = "Sph";
      acuiteVisuelPres.cylindre = parseFloat(acuiteVisuelPres.add);

      if (acuiteVisuelPres.cylindre > 0) {
        acuiteVisuelPres.cylindre = `+${acuiteVisuelPres.cylindre}`;
      } else if (acuiteVisuelPres.cylindre === 0) {
        acuiteVisuelPres.cylindre = "Sph"
      }
      return;
    }

    // Handle non-numeric or NaN cases
    if (isNaN(cylindreLoin)) {
      acuiteVisuelLoin.cylindre = value;
      return;
    }

    // Handle numeric cases
    if (cylindreLoin > 0) {
      acuiteVisuelLoin.cylindre = `+${cylindreLoin}`;
    } else {
      acuiteVisuelLoin.cylindre = cylindreLoin;
    }

    acuiteVisuelPres.cylindre = cylindreLoin + parseFloat(acuiteVisuelPres.add);

    if (acuiteVisuelPres.cylindre === 0) {
      acuiteVisuelPres.cylindre = "Sph"
    } else if (acuiteVisuelPres.cylindre > 0) {
      acuiteVisuelPres.cylindre = `+${acuiteVisuelPres.cylindre}`;
    }
  }
  incrementLoinODCy() {
    // Parse values as floats, initializing as 0 if parsing fails
    let acuiteVisuelLoincylindre = parseFloat(this.prescrireVerre.deLoin.oielDroit.cylindre) || 0;
    const add = parseFloat(this.prescrireVerre.dePres.oielDroit.add) || 0;

    // Check if the current cylindre value is "Sph"
    if (this.prescrireVerre.deLoin.oielDroit.cylindre === "Sph") {
      acuiteVisuelLoincylindre = 0;
    }

    if (isNaN(acuiteVisuelLoincylindre) || acuiteVisuelLoincylindre === undefined) {
      acuiteVisuelLoincylindre = 0;
    }

    acuiteVisuelLoincylindre += 0.25;
    let updatedcylindrePres = acuiteVisuelLoincylindre + add;

    // Format the values
    let formattedcylindreLoin;
    let formattedcylindrePres;

    // Format loin cylindre value
    if (acuiteVisuelLoincylindre === 0) {
      formattedcylindreLoin = "Sph";
    } else {
      formattedcylindreLoin = acuiteVisuelLoincylindre > 0
        ? `+${acuiteVisuelLoincylindre}`
        : `${acuiteVisuelLoincylindre}`;
    }

    // Format pres cylindre value
    formattedcylindrePres = updatedcylindrePres === 0 ? "Sph" :
      updatedcylindrePres > 0
        ? `+${updatedcylindrePres}`
        : `${updatedcylindrePres}`;

    // Update the original object properties
    this.prescrireVerre.deLoin.oielDroit.cylindre = formattedcylindreLoin;
    this.prescrireVerre.dePres.oielDroit.cylindre = formattedcylindrePres;
  }
  decrementLoinODCy() {
    // Convert current cylindre to a float, with fallback to 0 if conversion fails
    let loincylindre = parseFloat(this.prescrireVerre.deLoin.oielDroit.cylindre) || 0;
    let prescylindre = parseFloat(this.prescrireVerre.dePres.oielDroit.cylindre) || 0;
    const add = parseFloat(this.prescrireVerre.dePres.oielDroit.add) || 0;

    // Check if the current cylindre value is "Sph" and convert it back to 0 for operations
    if (this.prescrireVerre.deLoin.oielDroit.cylindre === "Sph") {
      loincylindre = 0;
    }

    // Decrement the cylindre value
    loincylindre -= 0.25;

    // Update loincylindre value after decrement
    if (isNaN(loincylindre)) {
      loincylindre = 0;
    }

    // Calculate prescylindre based on the updated loincylindre
    prescylindre = loincylindre + add;

    // Set 'cylindre' to "Sph" if it becomes exactly 0 after decrementing
    let formattedLoincylindre = loincylindre === 0 ? "Sph" : loincylindre > 0 ? `+${loincylindre}` : `${loincylindre}`;
    let formattedPrescylindre = prescylindre === 0 ? "Sph" : prescylindre > 0 ? `+${prescylindre}` : `${prescylindre}`;

    // Update the original object properties
    this.prescrireVerre.deLoin.oielDroit.cylindre = formattedLoincylindre;
    this.prescrireVerre.dePres.oielDroit.cylindre = formattedPrescylindre;
  }

  changeLoinOGCy(value) {
    const acuiteVisuelLoin = this.prescrireVerre.deLoin.oielGauche;
    const acuiteVisuelPres = this.prescrireVerre.dePres.oielGauche;

    // Parse value once
    let cylindreLoin = parseFloat(value);

    if (cylindreLoin === 0) {
      acuiteVisuelLoin.cylindre = "Sph";
      acuiteVisuelPres.cylindre = parseFloat(acuiteVisuelPres.add);

      if (acuiteVisuelPres.cylindre > 0) {
        acuiteVisuelPres.cylindre = `+${acuiteVisuelPres.cylindre}`;
      } else if (acuiteVisuelPres.cylindre === 0) {
        acuiteVisuelPres.cylindre = "Sph"
      }
      return;
    }

    // Handle non-numeric or NaN cases
    if (isNaN(cylindreLoin)) {
      acuiteVisuelLoin.cylindre = value;
      return;
    }

    // Handle numeric cases
    if (cylindreLoin > 0) {
      acuiteVisuelLoin.cylindre = `+${cylindreLoin}`;
    } else {
      acuiteVisuelLoin.cylindre = cylindreLoin;
    }

    acuiteVisuelPres.cylindre = cylindreLoin + parseFloat(acuiteVisuelPres.add);

    if (acuiteVisuelPres.cylindre === 0) {
      acuiteVisuelPres.cylindre = "Sph"
    } else if (acuiteVisuelPres.cylindre > 0) {
      acuiteVisuelPres.cylindre = `+${acuiteVisuelPres.cylindre}`;
    }
  }
  incrementLoinOGCy() {
    // Parse values as floats, initializing as 0 if parsing fails
    let acuiteVisuelLoincylindre = parseFloat(this.prescrireVerre.deLoin.oielGauche.cylindre) || 0;
    const add = parseFloat(this.prescrireVerre.dePres.oielGauche.add) || 0;

    // Check if the current cylindre value is "Sph"
    if (this.prescrireVerre.deLoin.oielGauche.cylindre === "Sph") {
      acuiteVisuelLoincylindre = 0;
    }

    if (isNaN(acuiteVisuelLoincylindre) || acuiteVisuelLoincylindre === undefined) {
      acuiteVisuelLoincylindre = 0;
    }

    acuiteVisuelLoincylindre += 0.25;
    let updatedcylindrePres = acuiteVisuelLoincylindre + add;

    // Format the values
    let formattedcylindreLoin;
    let formattedcylindrePres;

    // Format loin cylindre value
    if (acuiteVisuelLoincylindre === 0) {
      formattedcylindreLoin = "Sph";
    } else {
      formattedcylindreLoin = acuiteVisuelLoincylindre > 0
        ? `+${acuiteVisuelLoincylindre}`
        : `${acuiteVisuelLoincylindre}`;
    }

    // Format pres cylindre value
    formattedcylindrePres = updatedcylindrePres === 0 ? "Sph" :
      updatedcylindrePres > 0
        ? `+${updatedcylindrePres}`
        : `${updatedcylindrePres}`;

    // Update the original object properties
    this.prescrireVerre.deLoin.oielGauche.cylindre = formattedcylindreLoin;
    this.prescrireVerre.dePres.oielGauche.cylindre = formattedcylindrePres;
  }
  decrementLoinOGCy() {
    // Convert current cylindre to a float, with fallback to 0 if conversion fails
    let loincylindre = parseFloat(this.prescrireVerre.deLoin.oielGauche.cylindre) || 0;
    let prescylindre = parseFloat(this.prescrireVerre.dePres.oielGauche.cylindre) || 0;
    const add = parseFloat(this.prescrireVerre.dePres.oielGauche.add) || 0;

    // Check if the current cylindre value is "Sph" and convert it back to 0 for operations
    if (this.prescrireVerre.deLoin.oielGauche.cylindre === "Sph") {
      loincylindre = 0;
    }

    // Decrement the cylindre value
    loincylindre -= 0.25;

    // Update loincylindre value after decrement
    if (isNaN(loincylindre)) {
      loincylindre = 0;
    }

    // Calculate prescylindre based on the updated loincylindre
    prescylindre = loincylindre + add;

    // Set 'cylindre' to "Sph" if it becomes exactly 0 after decrementing
    let formattedLoincylindre = loincylindre === 0 ? "Sph" : loincylindre > 0 ? `+${loincylindre}` : `${loincylindre}`;
    let formattedPrescylindre = prescylindre === 0 ? "Sph" : prescylindre > 0 ? `+${prescylindre}` : `${prescylindre}`;

    // Update the original object properties
    this.prescrireVerre.deLoin.oielGauche.cylindre = formattedLoincylindre;
    this.prescrireVerre.dePres.oielGauche.cylindre = formattedPrescylindre;
  }
  //todo----- end of Cylindre  
  //todo-----  Axe  
  changeLoinODAxe(value) {
    this.prescrireVerre.deLoin.oielDroit.axe = parseFloat(value);
    this.prescrireVerre.dePres.oielDroit.axe = parseFloat(this.prescrireVerre.dePres.oielDroit.axe)
    if (!isNaN(value)) {
      if (this.prescrireVerre.deLoin.oielDroit.axe < 0) {
        this.prescrireVerre.deLoin.oielDroit.axe = 0;
        this.prescrireVerre.dePres.oielDroit.axe = parseFloat(this.prescrireVerre.deLoin.oielDroit.axe) + parseFloat(this.prescrireVerre.dePres.oielDroit.add)
      } else {
        this.prescrireVerre.deLoin.oielDroit.axe
        this.prescrireVerre.dePres.oielDroit.axe = parseFloat(this.prescrireVerre.deLoin.oielDroit.axe) + parseFloat(this.prescrireVerre.dePres.oielDroit.add)
      }
    } else {
      this.prescrireVerre.deLoin.oielDroit.axe = value;
    }
    if (isNaN(this.prescrireVerre.dePres.oielDroit.axe)) {
      this.prescrireVerre.dePres.oielDroit.axe = ""
    } else {
      this.prescrireVerre.dePres.oielDroit.axe
    }
  }

  incrementLoinODAxe() {
    this.prescrireVerre.deLoin.oielDroit.axe = parseFloat(this.prescrireVerre.deLoin.oielDroit.axe);
    this.prescrireVerre.dePres.oielDroit.axe = parseFloat(this.prescrireVerre.dePres.oielDroit.axe)
    if (isNaN(this.prescrireVerre.deLoin.oielDroit.axe) || this.prescrireVerre.deLoin.oielDroit.axe === undefined) {
      this.prescrireVerre.deLoin.oielDroit.axe = 0;
    } else if (this.prescrireVerre.deLoin.oielDroit.axe >= 0) {
      this.prescrireVerre.deLoin.oielDroit.axe += 5;
      this.prescrireVerre.deLoin.oielDroit.axe
    }
    this.prescrireVerre.dePres.oielDroit.axe = parseFloat(this.prescrireVerre.deLoin.oielDroit.axe) + parseFloat(this.prescrireVerre.dePres.oielDroit.add)
    if (isNaN(this.prescrireVerre.dePres.oielDroit.axe)) {
      this.prescrireVerre.dePres.oielDroit.axe = ""
    }
  }

  decrementLoinODAxe() {
    this.prescrireVerre.deLoin.oielDroit.axe = parseFloat(this.prescrireVerre.deLoin.oielDroit.axe);
    this.prescrireVerre.dePres.oielDroit.axe = parseFloat(this.prescrireVerre.dePres.oielDroit.axe)
    if (isNaN(this.prescrireVerre.deLoin.oielDroit.axe) || this.prescrireVerre.deLoin.oielDroit.axe === undefined) {
      this.prescrireVerre.deLoin.oielDroit.axe = 0;
    }
    else if (this.prescrireVerre.deLoin.oielDroit.axe <= 1) {
      this.prescrireVerre.deLoin.oielDroit.axe = 0;
    }
    else {
      this.prescrireVerre.deLoin.oielDroit.axe -= 5;
      if (this.prescrireVerre.deLoin.oielDroit.axe < 0) {
        this.prescrireVerre.deLoin.oielDroit.axe = 0
      }
    }
    this.prescrireVerre.dePres.oielDroit.axe = parseFloat(this.prescrireVerre.deLoin.oielDroit.axe) + parseFloat(this.prescrireVerre.dePres.oielDroit.add)
    if (isNaN(this.prescrireVerre.dePres.oielDroit.axe)) {
      this.prescrireVerre.dePres.oielDroit.axe = ""
    }
  }
  changeLoinOGAxe(value) {
    this.prescrireVerre.deLoin.oielGauche.axe = parseFloat(value);
    this.prescrireVerre.dePres.oielGauche.axe = parseFloat(this.prescrireVerre.dePres.oielGauche.axe)
    if (!isNaN(value)) {
      if (this.prescrireVerre.deLoin.oielGauche.axe < 0) {
        this.prescrireVerre.deLoin.oielGauche.axe = 0;
        this.prescrireVerre.dePres.oielGauche.axe = parseFloat(this.prescrireVerre.deLoin.oielGauche.axe) + parseFloat(this.prescrireVerre.dePres.oielGauche.add)
      } else {
        this.prescrireVerre.deLoin.oielGauche.axe
        this.prescrireVerre.dePres.oielGauche.axe = parseFloat(this.prescrireVerre.deLoin.oielGauche.axe) + parseFloat(this.prescrireVerre.dePres.oielGauche.add)
      }
    } else {
      this.prescrireVerre.deLoin.oielGauche.axe = value;
    }
    if (isNaN(this.prescrireVerre.dePres.oielGauche.axe)) {
      this.prescrireVerre.dePres.oielGauche.axe = ""
    } else {
      this.prescrireVerre.dePres.oielGauche.axe
    }
  }

  incrementLoinOGAxe() {
    this.prescrireVerre.deLoin.oielGauche.axe = parseFloat(this.prescrireVerre.deLoin.oielGauche.axe);
    this.prescrireVerre.dePres.oielGauche.axe = parseFloat(this.prescrireVerre.dePres.oielGauche.axe)
    if (isNaN(this.prescrireVerre.deLoin.oielGauche.axe) || this.prescrireVerre.deLoin.oielGauche.axe === undefined) {
      this.prescrireVerre.deLoin.oielGauche.axe = 0;
    } else if (this.prescrireVerre.deLoin.oielGauche.axe >= 0) {
      this.prescrireVerre.deLoin.oielGauche.axe += 5;
      this.prescrireVerre.deLoin.oielGauche.axe
    }
    this.prescrireVerre.dePres.oielGauche.axe = parseFloat(this.prescrireVerre.deLoin.oielGauche.axe) + parseFloat(this.prescrireVerre.dePres.oielGauche.add)
    if (isNaN(this.prescrireVerre.dePres.oielGauche.axe)) {
      this.prescrireVerre.dePres.oielGauche.axe = ""
    }

  }

  decrementLoinOGAxe() {
    this.prescrireVerre.deLoin.oielGauche.axe = parseFloat(this.prescrireVerre.deLoin.oielGauche.axe);
    this.prescrireVerre.dePres.oielGauche.axe = parseFloat(this.prescrireVerre.dePres.oielGauche.axe)
    if (isNaN(this.prescrireVerre.deLoin.oielGauche.axe) || this.prescrireVerre.deLoin.oielGauche.axe === undefined) {
      this.prescrireVerre.deLoin.oielGauche.axe = 0;
    }
    else if (this.prescrireVerre.deLoin.oielGauche.axe <= 1) {
      this.prescrireVerre.deLoin.oielGauche.axe = 0;
    }
    else {
      this.prescrireVerre.deLoin.oielGauche.axe -= 5;
      if (this.prescrireVerre.deLoin.oielGauche.axe < 0) {
        this.prescrireVerre.deLoin.oielGauche.axe = 0;
      }
    }
    this.prescrireVerre.dePres.oielGauche.axe = parseFloat(this.prescrireVerre.deLoin.oielGauche.axe) + parseFloat(this.prescrireVerre.dePres.oielGauche.add)
    if (isNaN(this.prescrireVerre.dePres.oielGauche.axe)) {
      this.prescrireVerre.dePres.oielGauche.axe = ""
    }
  }
  //todo----- end of  Axe  
  // !--- Acuite visuel de Pres
  //!----- Sphére  
  changePresODSph(value) {
    const parsedValue = parseFloat(value);

    // Check if the parsed value is NaN
    if (isNaN(parsedValue)) {
      // If it's NaN, use the original input value
      this.prescrireVerre.dePres.oielDroit.sphere = value;
    } else {
      // If it's a valid number, determine the formatting
      if (parsedValue === 0) {
        // Convert zero to "Plan"
        this.prescrireVerre.dePres.oielDroit.sphere = "Plan";
      } else if (parsedValue > 0) {
        // Format positive numbers with a "+" sign
        this.prescrireVerre.dePres.oielDroit.sphere = `+${parsedValue}`;
      } else {
        // Directly convert negative numbers to a string with two decimal places
        this.prescrireVerre.dePres.oielDroit.sphere = parsedValue;
      }
    }
  }

  incrementPresODSph() {
    // Parse the sphere value to a float
    let sphereValue = parseFloat(this.prescrireVerre.dePres.oielDroit.sphere);

    // Handle NaN or undefined by resetting it to 0
    if (isNaN(sphereValue) || this.prescrireVerre.dePres.oielDroit.sphere === "Plan") {
      sphereValue = 0;
    }

    // Increment the sphere value by 0.25
    sphereValue += 0.25;

    // Format the sphere value
    if (sphereValue === 0) {
      // Convert 0 to "Plan"
      this.prescrireVerre.dePres.oielDroit.sphere = "Plan";
    } else if (sphereValue > 0) {
      // Add a "+" sign to positive values
      this.prescrireVerre.dePres.oielDroit.sphere = `+${sphereValue}`;
    } else {
      // For negative values, just set the value with two decimal places
      this.prescrireVerre.dePres.oielDroit.sphere = sphereValue;
    }
  }

  decrementPresODSph() {
    // Parse the sphere value to a float
    let sphereValue = parseFloat(this.prescrireVerre.dePres.oielDroit.sphere);

    // Handle NaN or undefined by resetting it to 0
    if (isNaN(sphereValue) || sphereValue === undefined || this.prescrireVerre.dePres.oielDroit.sphere === "Plan") {
      sphereValue = 0;
    }
    // Decrement the sphere value by 0.25
    sphereValue -= 0.25;


    // Format the sphere value
    if (sphereValue === 0) {
      // Convert 0 to "Plan"
      this.prescrireVerre.dePres.oielDroit.sphere = "Plan";
    } else if (sphereValue > 0) {
      // Add a "+" sign to positive values and format to two decimal places
      this.prescrireVerre.dePres.oielDroit.sphere = `+${sphereValue}`;
    } else {
      // For negative values, format to two decimal places
      this.prescrireVerre.dePres.oielDroit.sphere = sphereValue;
    }
  }

  changePresPresOGSph(value) {
    const parsedValue = parseFloat(value);

    // Check if the parsed value is NaN
    if (isNaN(parsedValue)) {
      // If it's NaN, use the original input value
      this.prescrireVerre.dePres.oielGauche.sphere = value;
    } else {
      // If it's a valid number, determine the formatting
      if (parsedValue === 0) {
        // Convert zero to "Plan"
        this.prescrireVerre.dePres.oielGauche.sphere = "Plan";
      } else if (parsedValue > 0) {
        // Format positive numbers with a "+" sign
        this.prescrireVerre.dePres.oielGauche.sphere = `+${parsedValue}`;
      } else {
        // Directly convert negative numbers to a string with two decimal places
        this.prescrireVerre.dePres.oielGauche.sphere = parsedValue;
      }
    }
  }

  incrementPresOGSph() {
    // Parse the sphere value to a float
    let sphereValue = parseFloat(this.prescrireVerre.dePres.oielGauche.sphere);

    // Handle NaN or undefined by resetting it to 0
    if (isNaN(sphereValue) || this.prescrireVerre.dePres.oielGauche.sphere === "Plan") {
      sphereValue = 0;
    }

    // Increment the sphere value by 0.25
    sphereValue += 0.25;

    // Format the sphere value
    if (sphereValue === 0) {
      // Convert 0 to "Plan"
      this.prescrireVerre.dePres.oielGauche.sphere = "Plan";
    } else if (sphereValue > 0) {
      // Add a "+" sign to positive values
      this.prescrireVerre.dePres.oielGauche.sphere = `+${sphereValue}`;
    } else {
      // For negative values, just set the value with two decimal places
      this.prescrireVerre.dePres.oielGauche.sphere = sphereValue;
    }
  }

  decrementPresOGSph() {
    // Parse the sphere value to a float
    let sphereValue = parseFloat(this.prescrireVerre.dePres.oielGauche.sphere);

    // Handle NaN or undefined by resetting it to 0
    if (isNaN(sphereValue) || sphereValue === undefined || this.prescrireVerre.dePres.oielGauche.sphere === "Plan") {
      sphereValue = 0;
    }
    // Decrement the sphere value by 0.25
    sphereValue -= 0.25;


    // Format the sphere value
    if (sphereValue === 0) {
      // Convert 0 to "Plan"
      this.prescrireVerre.dePres.oielGauche.sphere = "Plan";
    } else if (sphereValue > 0) {
      // Add a "+" sign to positive values and format to two decimal places
      this.prescrireVerre.dePres.oielGauche.sphere = `+${sphereValue}`;
    } else {
      // For negative values, format to two decimal places
      this.prescrireVerre.dePres.oielGauche.sphere = sphereValue;
    }
  }
  //!----- end of Sphére
  //!-----  Cylindre  
  changePresODCy(value) {
    const parsedValue = parseFloat(value);

    // Check if the parsed value is NaN
    if (isNaN(parsedValue)) {
      // If it's NaN, use the original input value
      this.prescrireVerre.dePres.oielDroit.cylindre = value;
    } else {
      // If it's a valid number, determine the formatting
      if (parsedValue === 0) {
        // Convert zero to "Sph"
        this.prescrireVerre.dePres.oielDroit.cylindre = "Sph";
      } else if (parsedValue > 0) {
        // Format positive numbers with a "+" sign
        this.prescrireVerre.dePres.oielDroit.cylindre = `+${parsedValue}`;
      } else {
        // Directly convert negative numbers to a string with two decimal places
        this.prescrireVerre.dePres.oielDroit.cylindre = parsedValue;
      }
    }
  }

  incrementPresODCy() {
    // Parse the cylindre value to a float
    let cylindreValue = parseFloat(this.prescrireVerre.dePres.oielDroit.cylindre);

    // Handle NaN or undefined by resetting it to 0
    if (isNaN(cylindreValue) || this.prescrireVerre.dePres.oielDroit.cylindre === "Sph") {
      cylindreValue = 0;
    }

    // Increment the cylindre value by 0.25
    cylindreValue += 0.25;

    // Format the cylindre value
    if (cylindreValue === 0) {
      // Convert 0 to "Sph"
      this.prescrireVerre.dePres.oielDroit.cylindre = "Sph";
    } else if (cylindreValue > 0) {
      // Add a "+" sign to positive values
      this.prescrireVerre.dePres.oielDroit.cylindre = `+${cylindreValue}`;
    } else {
      // For negative values, just set the value with two decimal places
      this.prescrireVerre.dePres.oielDroit.cylindre = cylindreValue;
    }
  }

  decrementPresODCy() {
    // Parse the cylindre value to a float
    let cylindreValue = parseFloat(this.prescrireVerre.dePres.oielDroit.cylindre);

    // Handle NaN or undefined by resetting it to 0
    if (isNaN(cylindreValue) || cylindreValue === undefined || this.prescrireVerre.dePres.oielDroit.cylindre === "Sph") {
      cylindreValue = 0;
    }
    // Decrement the cylindre value by 0.25
    cylindreValue -= 0.25;


    // Format the cylindre value
    if (cylindreValue === 0) {
      // Convert 0 to "Sph"
      this.prescrireVerre.dePres.oielDroit.cylindre = "Sph";
    } else if (cylindreValue > 0) {
      // Add a "+" sign to positive values and format to two decimal places
      this.prescrireVerre.dePres.oielDroit.cylindre = `+${cylindreValue}`;
    } else {
      // For negative values, format to two decimal places
      this.prescrireVerre.dePres.oielDroit.cylindre = cylindreValue;
    }
  }

  changePresOGCy(value) {
    const parsedValue = parseFloat(value);

    // Check if the parsed value is NaN
    if (isNaN(parsedValue)) {
      // If it's NaN, use the original input value
      this.prescrireVerre.dePres.oielGauche.cylindre = value;
    } else {
      // If it's a valid number, determine the formatting
      if (parsedValue === 0) {
        // Convert zero to "Sph"
        this.prescrireVerre.dePres.oielGauche.cylindre = "Sph";
      } else if (parsedValue > 0) {
        // Format positive numbers with a "+" sign
        this.prescrireVerre.dePres.oielGauche.cylindre = `+${parsedValue}`;
      } else {
        // Directly convert negative numbers to a string with two decimal places
        this.prescrireVerre.dePres.oielGauche.cylindre = parsedValue;
      }
    }
  }
  incrementPresOGCy() {
    // Parse the cylindre value to a float
    let cylindreValue = parseFloat(this.prescrireVerre.dePres.oielGauche.cylindre);

    // Handle NaN or undefined by resetting it to 0
    if (isNaN(cylindreValue) || this.prescrireVerre.dePres.oielGauche.cylindre === "Sph") {
      cylindreValue = 0;
    }

    // Increment the cylindre value by 0.25
    cylindreValue += 0.25;

    // Format the cylindre value
    if (cylindreValue === 0) {
      // Convert 0 to "Sph"
      this.prescrireVerre.dePres.oielGauche.cylindre = "Sph";
    } else if (cylindreValue > 0) {
      // Add a "+" sign to positive values
      this.prescrireVerre.dePres.oielGauche.cylindre = `+${cylindreValue}`;
    } else {
      // For negative values, just set the value with two decimal places
      this.prescrireVerre.dePres.oielGauche.cylindre = cylindreValue;
    }
  }
  decrementPresOGCy() {
    // Parse the cylindre value to a float
    let cylindreValue = parseFloat(this.prescrireVerre.dePres.oielGauche.cylindre);

    // Handle NaN or undefined by resetting it to 0
    if (isNaN(cylindreValue) || cylindreValue === undefined || this.prescrireVerre.dePres.oielGauche.cylindre === "Sph") {
      cylindreValue = 0;
    }
    // Decrement the cylindre value by 0.25
    cylindreValue -= 0.25;


    // Format the cylindre value
    if (cylindreValue === 0) {
      // Convert 0 to "Sph"
      this.prescrireVerre.dePres.oielGauche.cylindre = "Sph";
    } else if (cylindreValue > 0) {
      // Add a "+" sign to positive values and format to two decimal places
      this.prescrireVerre.dePres.oielGauche.cylindre = `+${cylindreValue}`;
    } else {
      // For negative values, format to two decimal places
      this.prescrireVerre.dePres.oielGauche.cylindre = cylindreValue;
    }
  }
  //!----- end of Cylindre  
  //!-----  Axe  
  changePresODAxe(value) {
    this.prescrireVerre.dePres.oielDroit.axe = parseFloat(value);
    if (!isNaN(value)) {
      if (this.prescrireVerre.dePres.oielDroit.axe < 0) {
        this.prescrireVerre.dePres.oielDroit.axe = 0;
      } else {
        this.prescrireVerre.dePres.oielDroit.axe
      }
    } else {
      this.prescrireVerre.dePres.oielDroit.axe = value;
    }
  }

  incrementPresODAxe() {
    this.prescrireVerre.dePres.oielDroit.axe = parseFloat(this.prescrireVerre.dePres.oielDroit.axe)
    if (isNaN(this.prescrireVerre.dePres.oielDroit.axe) || this.prescrireVerre.dePres.oielDroit.axe === undefined) {
      this.prescrireVerre.dePres.oielDroit.axe = 0;
    } else if (this.prescrireVerre.dePres.oielDroit.axe >= 0) {
      this.prescrireVerre.dePres.oielDroit.axe += 5;
    }
  }

  decrementPresODAxe() {
    this.prescrireVerre.dePres.oielDroit.axe = parseFloat(this.prescrireVerre.dePres.oielDroit.axe)
    if (isNaN(this.prescrireVerre.dePres.oielDroit.axe) || this.prescrireVerre.dePres.oielDroit.axe === undefined) {
      this.prescrireVerre.dePres.oielDroit.axe = 0;
    }
    else if (this.prescrireVerre.dePres.oielDroit.axe <= 1) {
      this.prescrireVerre.dePres.oielDroit.axe = 0;
    }
    else {
      this.prescrireVerre.dePres.oielDroit.axe -= 5;
      if (this.prescrireVerre.dePres.oielDroit.axe < 0) {
        this.prescrireVerre.dePres.oielDroit.axe = 0;
      }
    }
  }
  changePresOGAxe(value) {
    this.prescrireVerre.dePres.oielGauche.axe = parseFloat(value);
    if (!isNaN(value)) {
      if (this.prescrireVerre.dePres.oielGauche.axe < 0) {
        this.prescrireVerre.dePres.oielGauche.axe = 0;
      } else {
        this.prescrireVerre.dePres.oielGauche.axe
      }
    } else {
      this.prescrireVerre.dePres.oielGauche.axe = value;
    }
  }

  incrementPresOGAxe() {
    this.prescrireVerre.dePres.oielGauche.axe = parseFloat(this.prescrireVerre.dePres.oielGauche.axe)
    if (isNaN(this.prescrireVerre.dePres.oielGauche.axe) || this.prescrireVerre.dePres.oielGauche.axe === undefined) {
      this.prescrireVerre.dePres.oielGauche.axe = 0;
    } else if (this.prescrireVerre.dePres.oielGauche.axe >= 0) {
      this.prescrireVerre.dePres.oielGauche.axe += 5;
    }
  }

  decrementPresOGAxe() {
    this.prescrireVerre.dePres.oielGauche.axe = parseFloat(this.prescrireVerre.dePres.oielGauche.axe)
    if (isNaN(this.prescrireVerre.dePres.oielGauche.axe) || this.prescrireVerre.dePres.oielGauche.axe === undefined) {
      this.prescrireVerre.dePres.oielGauche.axe = 0;
    }
    else if (this.prescrireVerre.dePres.oielGauche.axe <= 1) {
      this.prescrireVerre.dePres.oielGauche.axe = 0;
    }
    else {
      this.prescrireVerre.dePres.oielGauche.axe -= 5;
      if (this.prescrireVerre.dePres.oielGauche.axe < 0) {
        this.prescrireVerre.dePres.oielGauche.axe = 0;
      }
    }
  }
  //!----- end of  Axe 
  isStringAndNotNaN(value: any): boolean {
    return typeof value === 'string' && value.toLowerCase() !== 'nan';
  }
  // !-------- Add
  onchangeAddDroit(value) {
    // Convert the input value to a number or set it to 0 if it's invalid
    this.prescrireVerre.dePres.oielDroit.add = parseFloat(value);
    let addValue = this.prescrireVerre.dePres.oielDroit.add;

    // Check if addValue is NaN or negative, and set to 0 if so
    if (isNaN(addValue) || addValue < 0) {
      addValue = this.prescrireVerre.dePres.oielDroit.add = 0;
    }

    const attributes = ["sphere"];
    const deLoinOeilDroit = this.prescrireVerre.deLoin.oielDroit;
    const dePresOeilDroit = this.prescrireVerre.dePres.oielDroit;

    attributes.forEach(attr => {
      let loinValue = parseFloat(deLoinOeilDroit[attr]);
      let presValue = parseFloat(dePresOeilDroit[attr]);

      if (!isNaN(loinValue) && !isNaN(presValue)) {
        dePresOeilDroit[attr] = loinValue + addValue;
      } else if (!isNaN(presValue) && !this.isStringAndNotNaN(loinValue)) {
        dePresOeilDroit[attr] = addValue;
      } else if (isNaN(loinValue) && isNaN(presValue)) {
        dePresOeilDroit[attr];
      }
      // Add "+" sign to positive values
      if (dePresOeilDroit[attr] === 0) {
        dePresOeilDroit[attr] = "Plan";
      } else if (dePresOeilDroit[attr] > 0) {
        dePresOeilDroit[attr] = "+" + dePresOeilDroit[attr];
      }


    });

    // Add "+" sign to the add value if positive
    if (addValue > 0) {
      dePresOeilDroit.add = "+" + addValue;
    }
  }
  incrementDroit() {
    if (this.prescrireVerre.dePres.oielDroit.sphere === "Plan") {
      this.prescrireVerre.dePres.oielDroit.sphere = 0
    }
    // Convert the value to a number
    let addPres = parseFloat(this.prescrireVerre.dePres.oielDroit.add);
    let spherePres = parseFloat(this.prescrireVerre.dePres.oielDroit.sphere)
    let spehreLoin = parseFloat(this.prescrireVerre.deLoin.oielDroit.sphere)



    // Ensure `add` is not negative
    if (addPres < 0) {
      addPres = 0;
    } else {
      // Increment `add` by 0.25 if it's non-negative
      addPres += 0.25;
    }

    // Process sphere value
    if (!isNaN(spherePres) && !isNaN(spehreLoin)) {
      spherePres = spehreLoin + addPres;
    } else if (!isNaN(spherePres) && !this.isStringAndNotNaN(spehreLoin)) {
      spherePres = addPres;
    } else if (!this.isStringAndNotNaN(spherePres) && !this.isStringAndNotNaN(spehreLoin)) {
      spherePres = this.prescrireVerre.dePres.oielDroit.sphere;
    }

    // Ensure positive numbers are formatted with a "+" prefix
    if (spherePres === 0) {
      this.prescrireVerre.dePres.oielDroit.sphere = "Plan"
    } else if (spherePres > 0) {
      this.prescrireVerre.dePres.oielDroit.sphere = "+" + spherePres;
    } else {
      this.prescrireVerre.dePres.oielDroit.sphere = spherePres
    }


    if (addPres > 0) {
      this.prescrireVerre.dePres.oielDroit.add = "+" + addPres;
    }
  }

  decrementDroit() {
    // Ensure the 'add' value is a float
    let presAdd = parseFloat(this.prescrireVerre.dePres.oielDroit.add);
    let spherePres = parseFloat(this.prescrireVerre.dePres.oielDroit.sphere)
    let spehreLoin = parseFloat(this.prescrireVerre.deLoin.oielDroit.sphere)

    // Decrease 'add' by 0.25 if it's 0.25 or more
    if (presAdd >= 0.25) {
      presAdd -= 0.25;

      if (this.prescrireVerre.dePres.oielDroit.sphere === "Plan") {
        this.prescrireVerre.dePres.oielDroit.sphere = 0
      }
      // Process sphere value
      if (!isNaN(spherePres) && !isNaN(spehreLoin)) {
        spherePres = spehreLoin + presAdd;
        this.prescrireVerre.dePres.oielDroit.sphere = spherePres
      } else if (!isNaN(spherePres) && this.isStringAndNotNaN(this.prescrireVerre.deLoin.oielDroit.sphere)) {
        spherePres = presAdd;
        this.prescrireVerre.dePres.oielDroit.sphere = spherePres
      } else if (this.isStringAndNotNaN(spherePres) && this.isStringAndNotNaN(spehreLoin)) {
        this.prescrireVerre.dePres.oielDroit.sphere;
      }

      // Add '+' prefix for positive values
      if (spherePres === 0) {
        this.prescrireVerre.dePres.oielDroit.sphere = "Plan"
      } else if (spherePres > 0) {
        this.prescrireVerre.dePres.oielDroit.sphere = "+" + spherePres;
      }

      if (presAdd > 0) {
        this.prescrireVerre.dePres.oielDroit.add = "+" + presAdd;
      }
      // Ensure 'add' is not less than 0
      if (presAdd <= 0) {
        this.prescrireVerre.dePres.oielDroit.add = 0;
      }
    }
  }

  onchangeAddGauche(value) {
    // Convert the input value to a number or set it to 0 if it's invalid
    this.prescrireVerre.dePres.oielGauche.add = parseFloat(value);
    let addValue = this.prescrireVerre.dePres.oielGauche.add;

    // Check if addValue is NaN or negative, and set to 0 if so
    if (isNaN(addValue) || addValue < 0) {
      addValue = this.prescrireVerre.dePres.oielGauche.add = 0;
    }

    const attributes = ["sphere"];
    const deLoinOeilDroit = this.prescrireVerre.deLoin.oielGauche;
    const dePresOeilDroit = this.prescrireVerre.dePres.oielGauche;

    attributes.forEach(attr => {
      let loinValue = parseFloat(deLoinOeilDroit[attr]);
      let presValue = parseFloat(dePresOeilDroit[attr]);

      if (!isNaN(loinValue) && !isNaN(presValue)) {
        dePresOeilDroit[attr] = loinValue + addValue;
      } else if (!isNaN(presValue) && !this.isStringAndNotNaN(loinValue)) {
        dePresOeilDroit[attr] = addValue;
      } else if (isNaN(loinValue) && isNaN(presValue)) {
        dePresOeilDroit[attr];
      }
      // Add "+" sign to positive values
      if (dePresOeilDroit[attr] === 0) {
        dePresOeilDroit[attr] = "Plan";
      } else if (dePresOeilDroit[attr] > 0) {
        dePresOeilDroit[attr] = "+" + dePresOeilDroit[attr];
      }


    });

    // Add "+" sign to the add value if positive
    if (addValue > 0) {
      dePresOeilDroit.add = "+" + addValue;
    }
  }

  incrementGauche() {
    if (this.prescrireVerre.dePres.oielGauche.sphere === "Plan") {
      this.prescrireVerre.dePres.oielGauche.sphere = 0
    }
    // Convert the value to a number
    let addPres = parseFloat(this.prescrireVerre.dePres.oielGauche.add);
    let spherePres = parseFloat(this.prescrireVerre.dePres.oielGauche.sphere)
    let spehreLoin = parseFloat(this.prescrireVerre.deLoin.oielGauche.sphere)



    // Ensure `add` is not negative
    if (addPres < 0) {
      addPres = 0;
    } else {
      // Increment `add` by 0.25 if it's non-negative
      addPres += 0.25;
    }

    // Process sphere value
    if (!isNaN(spherePres) && !isNaN(spehreLoin)) {
      spherePres = spehreLoin + addPres;
    } else if (!isNaN(spherePres) && !this.isStringAndNotNaN(spehreLoin)) {
      spherePres = addPres;
    } else if (!this.isStringAndNotNaN(spherePres) && !this.isStringAndNotNaN(spehreLoin)) {
      spherePres = this.prescrireVerre.dePres.oielGauche.sphere;
    }

    // Ensure positive numbers are formatted with a "+" prefix
    if (spherePres === 0) {
      this.prescrireVerre.dePres.oielGauche.sphere = "Plan"
    } else if (spherePres > 0) {
      this.prescrireVerre.dePres.oielGauche.sphere = "+" + spherePres;
    } else {
      this.prescrireVerre.dePres.oielGauche.sphere = spherePres;
    }


    if (addPres > 0) {
      this.prescrireVerre.dePres.oielGauche.add = "+" + addPres;
    }
  }

  decrementGauche() {
    // Ensure the 'add' value is a float
    let presAdd = parseFloat(this.prescrireVerre.dePres.oielGauche.add);
    let spherePres = parseFloat(this.prescrireVerre.dePres.oielGauche.sphere)
    let spehreLoin = parseFloat(this.prescrireVerre.deLoin.oielGauche.sphere)

    // Decrease 'add' by 0.25 if it's 0.25 or more
    if (presAdd >= 0.25) {
      presAdd -= 0.25;

      if (this.prescrireVerre.dePres.oielGauche.sphere === "Plan") {
        this.prescrireVerre.dePres.oielGauche.sphere = 0
      }
      // Process sphere value
      if (!isNaN(spherePres) && !isNaN(spehreLoin)) {
        spherePres = spehreLoin + presAdd;
        this.prescrireVerre.dePres.oielGauche.sphere = spherePres
      } else if (!isNaN(spherePres) && this.isStringAndNotNaN(this.prescrireVerre.deLoin.oielGauche.sphere)) {
        spherePres = presAdd;
        this.prescrireVerre.dePres.oielGauche.sphere = spherePres
      } else if (this.isStringAndNotNaN(spherePres) && this.isStringAndNotNaN(spehreLoin)) {
        this.prescrireVerre.dePres.oielGauche.sphere;
      }

      // Add '+' prefix for positive values
      if (spherePres === 0) {
        this.prescrireVerre.dePres.oielGauche.sphere = "Plan"
      } else if (spherePres > 0) {
        this.prescrireVerre.dePres.oielGauche.sphere = "+" + spherePres;
      }

      if (presAdd > 0) {
        this.prescrireVerre.dePres.oielGauche.add = "+" + presAdd;
      }
      // Ensure 'add' is not less than 0
      if (presAdd <= 0) {
        this.prescrireVerre.dePres.oielGauche.add = 0;
      }
    }
  }
  // !-------- end of  Add
  // !------- Dp de prés
  onchangePresDp(value) {
    this.prescrireVerre.dePres.dp = parseFloat(value);
    if (!isNaN(value) || value == undefined) {
      this.prescrireVerre.dePres.dp = this.prescrireVerre.dePres.dp;
    } else {
      this.prescrireVerre.dePres.dp = 0;
    }
  }

  incrementPresDp() {
    this.prescrireVerre.dePres.dp = parseFloat(this.prescrireVerre.dePres.dp);
    if (isNaN(this.prescrireVerre.dePres.dp) || this.prescrireVerre.dePres.dp === undefined) {
      this.prescrireVerre.dePres.dp = 0;
    } else {
      this.prescrireVerre.dePres.dp += 1;
    }
  }

  decrementPresDp() {
    this.prescrireVerre.dePres.dp = parseFloat(this.prescrireVerre.dePres.dp);
    if (isNaN(this.prescrireVerre.dePres.dp) || this.prescrireVerre.dePres.dp === undefined) {
      this.prescrireVerre.dePres.dp = 0;
    } else {
      this.prescrireVerre.dePres.dp -= 1;
    }
  }
  // !------- end of Dp de prés
  //!---- end of Acuite visuel de Pres


  // !------- Dp de loin
  onchangeLoinDp(value) {
    this.prescrireVerre.deLoin.dp = parseFloat(value);
    if (!isNaN(value) || value == undefined) {
      this.prescrireVerre.deLoin.dp = this.prescrireVerre.deLoin.dp;
    } else {
      this.prescrireVerre.deLoin.dp = 0;
    }
  }

  incrementLoinDp() {
    this.prescrireVerre.deLoin.dp = parseFloat(this.prescrireVerre.deLoin.dp);
    if (isNaN(this.prescrireVerre.deLoin.dp) || this.prescrireVerre.deLoin.dp === undefined) {
      this.prescrireVerre.deLoin.dp = 0;
    } else {
      this.prescrireVerre.deLoin.dp += 1;
    }
  }

  decrementLoinDp() {
    this.prescrireVerre.deLoin.dp = parseFloat(this.prescrireVerre.deLoin.dp);
    if (isNaN(this.prescrireVerre.deLoin.dp) || this.prescrireVerre.deLoin.dp === undefined) {
      this.prescrireVerre.deLoin.dp = 0;
    } else {
      this.prescrireVerre.deLoin.dp -= 1;
    }
  }
  // !------- end of Dp de loin
  // todo---- end of Acuite visuel de loin

  onVerreChange() {
    if (this.selectedVerre && !this.prescrireVerre.typeVerre.includes(this.selectedVerre)) {
      this.prescrireVerre.typeVerre.push(this.selectedVerre);
    }
    //  else {
    //   const index = this.selectedVerres.indexOf(this.selectedVerre);
    //   if (index !== -1) {
    //     this.selectedVerres.splice(index, 1);
    //   }
    // }
  }
  removeVerre(item) {
    const index = this.prescrireVerre.typeVerre.indexOf(item);
    if (index !== -1) {
      this.prescrireVerre.typeVerre.splice(index, 1);
      this.selectedVerre = null; // Clear selected item after removal
    }
  }
  onFiltreChange() {
    if (this.selectedFiltre && !this.prescrireVerre.filtre.includes(this.selectedFiltre)) {
      this.prescrireVerre.filtre.push(this.selectedFiltre);
    }
  }
  removeFiltre(item) {
    const index = this.prescrireVerre.filtre.indexOf(item);
    if (index !== -1) {
      this.prescrireVerre.filtre.splice(index, 1);
      this.selectedFiltre = null; // Clear selected item after removal
    }
  }
  // ------------end Prescrire verre functions--------//
  //! ------------start Prescrire Lentille functions--------//
  resetData() {
    this.prescrireLentille.solution = undefined
    this.prescrireLentille.modeDeRenouvellement = undefined
    this.solutionMultifonction = false
    this.solutionSaline = false
    this.solutionOxygene = false
  }
  hideTorique() {
    this.prescrireLentille.torique = !this.prescrireLentille.torique
    this.resetData()
  }
  showTorique() {
    this.prescrireLentille.torique = this.prescrireLentille.torique
    this.resetData()
  }

  choisirSolutionMultifonction() {
    this.solutionMultifonction = true
    this.solutionSaline = false
    this.solutionOxygene = false
    this.prescrireLentille.solution = "Solution multifonction"
  }
  choisirSolutionSaline() {
    this.solutionMultifonction = false
    this.solutionSaline = true
    this.solutionOxygene = false
    this.prescrireLentille.solution = "Solution saline"
  }
  choisirSolutionOxygene() {
    this.solutionMultifonction = false
    this.solutionSaline = false
    this.solutionOxygene = true
    this.prescrireLentille.solution = "Solution oxygéné"
  }
  // ------------end Prescrire Lentille functions--------//
  // !------------start Prescrire Ordo functions--------//

  // ------------end Prescrire Ordo functions--------//


  // !------------start Prescrire:BO bilan orthoptique functions--------//
  onBilanMotifChange() {
    if (this.selectedMotifBilan && !this.prescrireBoBilanOrtho.motifs.includes(this.selectedMotifBilan)) {
      this.prescrireBoBilanOrtho.motifs.push(this.selectedMotifBilan);
    }
    //  else {
    //   const index = this.selectedVerres.indexOf(this.selectedVerre);
    //   if (index !== -1) {
    //     this.selectedVerres.splice(index, 1);
    //   }
    // }
  }
  removeBilanMotif(item) {
    const index = this.prescrireBoBilanOrtho.motifs.indexOf(item);
    if (index !== -1) {
      this.prescrireBoBilanOrtho.motifs.splice(index, 1);
      this.selectedMotifBilan = ''; // Clear selected item after removal
    }
  }
  // ------------end Prescrire:BO bilan orthoptique functions--------//



  // !------------start Chirurgie functions--------//

  removeChirurgieType(item) {
    const index = this.chirurgie.types.indexOf(item);
    if (index !== -1) {
      this.chirurgie.types.splice(index, 1);
      this.selectedChirugieType = null; // Clear selected item after removal
    }
  }

  setOeilDroitChirugie() {
    this.OeilDroitChirugie = true
    this.OeilGaucheChirugie = false
    this.chirurgie.oeil = "Oeil droit"
  }
  setOeilGaucheChirugie() {
    this.OeilDroitChirugie = false
    this.OeilGaucheChirugie = true
    this.chirurgie.oeil = "Oeil gauche"
  }
  // ------------end Chirurgie functions--------//

  // !------------start Examen Complémentaire functions--------//
  onExamCompChange() {
    if (this.selectedExamComp && !this.examenComplementaire.types.includes(this.selectedExamComp)) {
      this.examenComplementaire.types.push(this.selectedExamComp);
    }
  }
  removeExamCompType(item) {
    const index = this.examenComplementaire.types.indexOf(item);
    if (index !== -1) {
      this.examenComplementaire.types.splice(index, 1);
      this.selectedExamComp = null; // Clear selected item after removal
    }
  }

  setOeilDroitExamComp() {
    this.OeilDroitExamComp = true
    this.OeilGaucheExamComp = false
    this.examenComplementaire.oeil = "Oeil droit"
  }
  setOeilGauchExamComp() {
    this.OeilDroitExamComp = false
    this.OeilGaucheExamComp = true
    this.examenComplementaire.oeil = "Oeil gauche"
  }
  // ------------end Examen Complémentaire functions--------//

  // !------------start Prescrire Lasers functions--------//
  //  onPrescLaserChange() {
  //   this.selectedPrescLaser=this.selectedPrescLaser||null
  //  }
  // removePrescLaserType(item) {

  //   item=undefined
  //   this.selectedPrescLaser=null
  //    this.showTypeLaser=false
  // }

  setOeilDroitPrescLaser() {
    this.OeilDroitPrescLaser = true
    this.OeilGauchePrescLaser = false
    this.prescrireLaser.oeil = "Oeil droit"
  }
  setOeilGauchPrescLaser() {
    this.OeilDroitPrescLaser = false
    this.OeilGauchePrescLaser = true
    this.prescrireLaser.oeil = "Oeil gauche"
  }
  // ------------end Examen Complémentaire functions--------//

  // !------------start Certificat Médical functions--------//


  // ------------end Certificat Médical functions--------//
  // save prescrire verre

  savePrescrire() {
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.prescrireVerre) {
      this.prescrireVerre.date = moment().add(1, 'hour').format()
      this.myExamen.cat.prescrireVerre[0] = this.prescrireVerre
      this.updateExamen()
      return true;
    }
  }
  // save prescrire lebtille 

  savePrescrirelentilles() {
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.prescrireLentille) {
      this.prescrireLentille.date = moment().add(1, 'hour').format()
      this.myExamen.cat.prescrireLentille[0] = this.prescrireLentille
      this.updateExamen()
      return true;
    }
  }

  //prescrireOrdo
  saveOrdo() {
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.prescrireOrdo) {
      var datatosave = {
        "titrePrecrire": "Prescrire une ordonnance",
        "date": moment().add(1, 'hour').format(),
        "consigne": this.prescrireOrdo.consigne,
        "informationAdditionnel": this.prescrireOrdo.informationAdditionnel,
        "listDrugs": []
      }
      if (this.prescrireOrdo.listDrugs.length > 0) {
        for (var j = 0; j < this.prescrireOrdo.listDrugs.length; j++) {
          let checkvalidity = this.hasNonNullAttribute(this.prescrireOrdo.listDrugs[j])
          if (checkvalidity) {
            datatosave.listDrugs.push(this.prescrireOrdo.listDrugs[j])
          }
        }
      }
      if (this.drugsForm.value.listDrugs.length > 0) {
        for (var j = 0; j < this.drugsForm.value.listDrugs.length; j++) {
          let checkvalidity = this.hasNonNullAttribute(this.drugsForm.value.listDrugs[j])
          if (checkvalidity) {
            datatosave.listDrugs.push(this.drugsForm.value.listDrugs[j])
          }
        }
      }
      this.myExamen.cat.prescrireOrdo[0] = datatosave
      this.updateExamen()
      this.drugsForm.get('listDrugs').reset()
      this.selectedTypeClasses[0] = 'None'
      return true;
    }
  }
  // save Prescrire BO Bilan Orthoptique
  savePrescrireBOBilanOrthoptique() {
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.prescrireBoBilanOrtho) {
      this.prescrireBoBilanOrtho.date = moment().add(1, 'hour').format()
      this.myExamen.cat.prescrireBoBilanOrtho[0] = this.prescrireBoBilanOrtho
      this.updateExamen()
      return true;
    }
  }
  // save Chirurgie
  saveChirurgie() {
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.chirurgie) {
      this.chirurgie.date = moment().add(1, 'hour').format()
      this.myExamen.cat.chirurgie[0] = this.chirurgie
      this.updateExamen()
      return true;
    }
  }
  //save Examen Complementaire
  saveExamenComp() {
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.examenComplementaire) {
      this.examenComplementaire.date = moment().add(1, 'hour').format()
      this.myExamen.cat.examenComplementaire[0] = this.examenComplementaire
      this.updateExamen()
      return true;
    }
  }
  // save Prescrire Laser
  savePrescrireLaser() {
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.prescrireLaser) {
      this.prescrireLaser.date = moment().add(1, 'hour').format()
      this.myExamen.cat.prescrireLaser[0] = this.prescrireLaser
      this.updateExamen()
      return true;
    }
  }
  // save Certificat Mecical
  saveCertificatMecical() {
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.certificatMedical) {
      this.certificatMedical.date = moment().add(1, 'hour').format()
      this.myExamen.cat.certificatMedical[0] = this.certificatMedical
      this.updateExamen()
      return true;
    }
  }
  //savecertificataptitudepermis()
  savecertificataptitudepermis() {
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.certificataptitudepermis) {
      this.myExamen.cat.certificataptitudepermis[0] = this.certificataptitudepermis
      this.updateExamen()
      return true;
    }
  }
  //saveLettreconfrere()
  saveLettreconfrere() {
    if (this.myExamen && this.myExamen.cat && this.myExamen.cat.lettreConfrere) {

      this.lettreConfrere.date = moment().add(1, 'hour').format()
      if (this.selectedMedecinn) {
        this.lettreConfrere.medecin = this.selectedMedecinn._id
      }
      if (!this.lettreConfrere.medecin) {
        this.iziToast.show({
          message: 'Merci de choisir un médecin pour la lettre',
          messageColor: '#800f2f',
          titleColor: '#800f2f',
          progressBarColor: '#c9184a',
          position: 'topRight',
          timeout: 5000,
          backgroundColor: '#ff8fa3',
          transitionIn: 'flipInX',
          transitionOut: 'flipOutX',
          overlay: true,
          overlayClose: true,
        });
        return false;
      }
      this.myExamen.cat.lettreConfrere[0] = this.lettreConfrere
      this.updateExamen()
      return true;
    }
  }
  // check if object is empty or not 
  hasNonNullAttribute(obj) {
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
        return true;
      }
    }
    return false;
  }
  autocompleListFormatterPrenom = (data: any): SafeHtml => {
    if (data.nom != undefined && data.prenom != undefined) {
      var html = `<span>${data.nom} ${data.prenom} ${data.specialite}</span>`;
    }
    if (data.prenom == undefined) {
      var html = `<span>${data.nom} ${data.specialite}</span>`;
    }
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
  myCallback(event) {
    this.selectedMedecinn = undefined
    if (event._id) {
      this.selectedMedecinn = event
      if (!event.prenom) {
        this.nom = event.nom + ' ' + event.specialite
      }
      if (event.prenom) {
        this.nom = event.nom + ' ' + event.prenom + ' ' + event.specialite
      }
    }
  }
  onKey($event) {
    this.selectedMedecinn = undefined
  }
  // end data cat

  updateProgrammeRdv() {
    if (this.myExamen.nextAppointmentDuration === 'Après 15 jours') {
      this.myExamen.nextAppointment = moment(this.myExamen.start).add(15, 'days').format('YYYY-MM-DDTHH:mm')
    }
    if (this.myExamen.nextAppointmentDuration === 'Après 1 mois') {
      this.myExamen.nextAppointment = moment(this.myExamen.start).add(1, 'months').format('YYYY-MM-DDTHH:mm')
    }
    if (this.myExamen.nextAppointmentDuration === 'Après 6 mois') {
      this.myExamen.nextAppointment = moment(this.myExamen.start).add(6, 'months').format('YYYY-MM-DDTHH:mm')
    }
    if (this.myExamen.nextAppointmentDuration === 'Après 1 an') {
      this.myExamen.nextAppointment = moment(this.myExamen.start).add(1, 'years').format('YYYY-MM-DDTHH:mm')
    }
    this.objecttoSend.source = "prochaineRdv"
    this.objecttoSend.nextAppointment = this.myExamen.nextAppointment
    this.objecttoSend.nextAppointmentDuration = this.myExamen.nextAppointmentDuration
    this.objecttoSend.nextMotif = this.myExamen.nextMotif
    this.sharedDataService.updateData(this.objecttoSend);


  }
  updateProgrammeRdvDateManuel() {
    this.objecttoSend.source = "prochaineRdv"
    this.objecttoSend.nextAppointment = this.myExamen.nextAppointment
    this.objecttoSend.nextAppointmentDuration = this.myExamen.nextAppointmentDuration
    this.objecttoSend.nextMotif = this.myExamen.nextMotif
    this.sharedDataService.updateData(this.objecttoSend);
  }
  addInputMotif($event) {
    if (this.selectedMotifs.indexOf($event) < 0 && $event && $event.trim().length >= 3 && this.selectedMotifs.length < 20 && this.formMotif.valid) {
      this.selectedMotifs.push($event);
      this.myExamen.nextMotif = this.selectedMotifs
      this.updateProgrammeRdv();
      this.nextMotif = ""
    }
    if (this.motifList.indexOf($event) < 0 && $event && $event.trim().length >= 3 && this.motifList.length < 20 && this.formMotif.valid) {
      this.motifList.push($event)
      this.motifService
        .ajoutMotif({ nom: $event })
        .subscribe((response: any) => {
        });
      return true
    }


  }
  removeMotif(mot) {
    this.selectedMotifs.splice(this.selectedMotifs.indexOf(mot), 1);
    this.objecttoSend.source = "prochaineRdv"
    this.objecttoSend.nextAppointment = this.myExamen.nextAppointment
    this.objecttoSend.nextAppointmentDuration = this.myExamen.nextAppointmentDuration
    this.objecttoSend.nextMotif = this.selectedMotifs
    this.sharedDataService.updateData(this.objecttoSend);
    return true;
  }
  // imprimer Conclusion
  imprimerConclusion() {
    const today = new Date();

    // Get day, month, and year
    const day = String(today.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const year = today.getFullYear();
    const htmlWidth = $("#divToExport").width();
    const htmlHeight = $("#divToExport").height();

    const topLeftMargin = 15;

    let pdfWidth = htmlWidth + (topLeftMargin * 2);
    let pdfHeight = (pdfWidth * 1.5) + (topLeftMargin * 2);

    const canvasImageWidth = htmlWidth;
    const canvasImageHeight = htmlHeight;

    const totalPDFPages = Math.ceil(htmlHeight / pdfHeight);

    const data = document.getElementById('divToExport');
    html2canvas(data, { allowTaint: true }).then(canvas => {

      canvas.getContext('2d');
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      let pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);
      pdf.addImage(imgData, 'png', topLeftMargin, topLeftMargin, canvasImageWidth, canvasImageHeight);

      for (let i = 1; i < totalPDFPages; i++) {
        pdf.addPage([pdfWidth, pdfHeight], 'p');
        pdf.addImage(imgData, 'png', topLeftMargin, -(pdfHeight * i) + topLeftMargin, canvasImageWidth, canvasImageHeight);
      }
      let namefile = "Conclusion-" + day + month + year;
      if (this.myExamen && this.myExamen.patient) {
        namefile = 'Conclusion-' + this.myExamen.patient.nom + '-' + this.myExamen.patient.prenom + '-' + day + month + year + '.pdf'
      }
      pdf.save(namefile);
    });
  }

  imprimerCat(idFile) {
    if (!idFile || idFile == undefined || idFile == null) {
      this.iziToast.show({
        message: "Il faut enregistrer avant d'imprimer",
        messageColor: '#800f2f',
        titleColor: '#800f2f',
        progressBarColor: '#c9184a',
        position: 'topRight',
        timeout: 5000,
        backgroundColor: '#ff8fa3',
        transitionIn: 'flipInX',
        transitionOut: 'flipOutX',
        overlay: true,
        overlayClose: true,
      });
      return false;
    }
    window.open(`medecin/impression/${this.myExamen.id}/${idFile}`, '_blank')
  }

  redirectionNouvelleConclusion() {
    window.open(`medecin/impression/conclusion/examen/${this.myExamen.id}`, '_blank')
  }

  // todo--- Prescrire lentille
  //todo----- Sphére  
  changeODSph(value) {
    const parsedValue = parseFloat(value);


    if (isNaN(parsedValue)) {
      this.prescrireLentille.oielDroit.sphere = value;
      return;
    }

    if (parsedValue === 0) {
      this.prescrireLentille.oielDroit.sphere = "Plan";
    } else if (parsedValue > 0) {
      this.prescrireLentille.oielDroit.sphere = "+" + parsedValue;
    } else {
      this.prescrireLentille.oielDroit.sphere = parsedValue.toString();
    }
  }
  incrementODSph() {
    let sphereValue: number;

    if (this.prescrireLentille.oielDroit.sphere === "Plan") {
      sphereValue = 0;
    } else {
      sphereValue = parseFloat(this.prescrireLentille.oielDroit.sphere);
    }

    if (isNaN(sphereValue) || sphereValue === undefined) {
      sphereValue = 0;
    } else {
      sphereValue += 0.25;
    }

    if (sphereValue === 0) {
      this.prescrireLentille.oielDroit.sphere = "Plan";
    } else if (sphereValue > 0) {
      this.prescrireLentille.oielDroit.sphere = "+" + sphereValue;
    } else {
      this.prescrireLentille.oielDroit.sphere = sphereValue.toString();
    }
  }
  decrementODSph() {
    let sphereValue: number;

    // Treat "Plan" as 0 for decrementing
    if (this.prescrireLentille.oielDroit.sphere === "Plan") {
      sphereValue = 0;
    } else {
      sphereValue = parseFloat(this.prescrireLentille.oielDroit.sphere);
    }

    if (isNaN(sphereValue) || sphereValue === undefined) {
      sphereValue = 0;
    } else {
      sphereValue -= 0.25;
    }

    if (sphereValue === 0) {
      this.prescrireLentille.oielDroit.sphere = "Plan";
    } else if (sphereValue > 0) {
      this.prescrireLentille.oielDroit.sphere = "+" + sphereValue;
    } else {
      this.prescrireLentille.oielDroit.sphere = sphereValue.toString();
    }
  }

  changeOGSph(value) {
    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      this.prescrireLentille.oielGauche.sphere = value;
      return;
    }

    if (parsedValue === 0) {
      this.prescrireLentille.oielGauche.sphere = "Plan";
    } else if (parsedValue > 0) {
      this.prescrireLentille.oielGauche.sphere = "+" + parsedValue;
    } else {
      this.prescrireLentille.oielGauche.sphere = parsedValue.toString();
    }
  }
  incrementOGSph() {
    let sphereValue: number;

    if (this.prescrireLentille.oielGauche.sphere === "Plan") {
      sphereValue = 0;
    } else {
      sphereValue = parseFloat(this.prescrireLentille.oielGauche.sphere);
    }

    if (isNaN(sphereValue) || sphereValue === undefined) {
      sphereValue = 0;
    } else {
      sphereValue += 0.25;
    }

    if (sphereValue === 0) {
      this.prescrireLentille.oielGauche.sphere = "Plan";
    } else if (sphereValue > 0) {
      this.prescrireLentille.oielGauche.sphere = "+" + sphereValue;
    } else {
      this.prescrireLentille.oielGauche.sphere = sphereValue.toString();
    }
  }
  decrementOGSph() {
    let sphereValue: number;

    // Treat "Plan" as 0 for decrementing
    if (this.prescrireLentille.oielGauche.sphere === "Plan") {
      sphereValue = 0;
    } else {
      sphereValue = parseFloat(this.prescrireLentille.oielGauche.sphere);
    }

    if (isNaN(sphereValue) || sphereValue === undefined) {
      sphereValue = 0;
    } else {
      sphereValue -= 0.25;
    }

    if (sphereValue === 0) {
      this.prescrireLentille.oielGauche.sphere = "Plan";
    } else if (sphereValue > 0) {
      this.prescrireLentille.oielGauche.sphere = "+" + sphereValue;
    } else {
      this.prescrireLentille.oielGauche.sphere = sphereValue.toString();
    }
  }
  //todo----- end of Sphére
  //todo-----  Cylindre  
  changeODCy(value) {
    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      this.prescrireLentille.oielDroit.cylindre = value;
      return;
    }

    if (parsedValue === 0) {
      this.prescrireLentille.oielDroit.cylindre = "Sph";
    } else if (parsedValue > 0) {
      this.prescrireLentille.oielDroit.cylindre = "+" + parsedValue;
    } else {
      this.prescrireLentille.oielDroit.cylindre = parsedValue.toString();
    }
  }
  incrementODCy() {
    let cylindreValue: number;

    if (this.prescrireLentille.oielDroit.cylindre === "Sph") {
      cylindreValue = 0;
    } else {
      cylindreValue = parseFloat(this.prescrireLentille.oielDroit.cylindre);
    }

    if (isNaN(cylindreValue) || cylindreValue === undefined) {
      cylindreValue = 0;
    } else {
      cylindreValue += 0.25;
    }

    if (cylindreValue === 0) {
      this.prescrireLentille.oielDroit.cylindre = "Sph";
    } else if (cylindreValue > 0) {
      this.prescrireLentille.oielDroit.cylindre = "+" + cylindreValue;
    } else {
      this.prescrireLentille.oielDroit.cylindre = cylindreValue.toString();
    }
  }
  decrementODCy() {
    let cylindreValue: number;

    // Treat "Sph" as 0 for decrementing
    if (this.prescrireLentille.oielDroit.cylindre === "Sph") {
      cylindreValue = 0;
    } else {
      cylindreValue = parseFloat(this.prescrireLentille.oielDroit.cylindre);
    }

    if (isNaN(cylindreValue) || cylindreValue === undefined) {
      cylindreValue = 0;
    } else {
      cylindreValue -= 0.25;
    }

    if (cylindreValue === 0) {
      this.prescrireLentille.oielDroit.cylindre = "Sph";
    } else if (cylindreValue > 0) {
      this.prescrireLentille.oielDroit.cylindre = "+" + cylindreValue;
    } else {
      this.prescrireLentille.oielDroit.cylindre = cylindreValue.toString();
    }
  }

  changeOGCy(value) {
    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) {
      this.prescrireLentille.oielGauche.cylindre = value;
      return;
    }

    if (parsedValue === 0) {
      this.prescrireLentille.oielGauche.cylindre = "Sph";
    } else if (parsedValue > 0) {
      this.prescrireLentille.oielGauche.cylindre = "+" + parsedValue;
    } else {
      this.prescrireLentille.oielGauche.cylindre = parsedValue.toString();
    }
  }
  incrementOGCy() {
    let cylindreValue: number;

    if (this.prescrireLentille.oielGauche.cylindre === "Sph") {
      cylindreValue = 0;
    } else {
      cylindreValue = parseFloat(this.prescrireLentille.oielGauche.cylindre);
    }

    if (isNaN(cylindreValue) || cylindreValue === undefined) {
      cylindreValue = 0;
    } else {
      cylindreValue += 0.25;
    }

    if (cylindreValue === 0) {
      this.prescrireLentille.oielGauche.cylindre = "Sph";
    } else if (cylindreValue > 0) {
      this.prescrireLentille.oielGauche.cylindre = "+" + cylindreValue;
    } else {
      this.prescrireLentille.oielGauche.cylindre = cylindreValue.toString();
    }
  }
  decrementOGCy() {
    let cylindreValue: number;

    // Treat "Sph" as 0 for decrementing
    if (this.prescrireLentille.oielGauche.cylindre === "Sph") {
      cylindreValue = 0;
    } else {
      cylindreValue = parseFloat(this.prescrireLentille.oielGauche.cylindre);
    }

    if (isNaN(cylindreValue) || cylindreValue === undefined) {
      cylindreValue = 0;
    } else {
      cylindreValue -= 0.25;
    }

    if (cylindreValue === 0) {
      this.prescrireLentille.oielGauche.cylindre = "Sph";
    } else if (cylindreValue > 0) {
      this.prescrireLentille.oielGauche.cylindre = "+" + cylindreValue;
    } else {
      this.prescrireLentille.oielGauche.cylindre = cylindreValue.toString();
    }
  }
  //todo----- end of Cylindre  
  //todo-----  Axe  
  changeODAxe(value) {
    this.prescrireLentille.oielDroit.axe = parseFloat(value);
    if (!isNaN(value)) {
      if (this.prescrireLentille.oielDroit.axe < 0) {
        this.prescrireLentille.oielDroit.axe = 0;
      } else {
        this.prescrireLentille.oielDroit.axe
      }
    } else {
      this.prescrireLentille.oielDroit.axe = value;
    }
  }

  incrementODAxe() {
    this.prescrireLentille.oielDroit.axe = parseFloat(this.prescrireLentille.oielDroit.axe);
    if (isNaN(this.prescrireLentille.oielDroit.axe) || this.prescrireLentille.oielDroit.axe === undefined) {
      this.prescrireLentille.oielDroit.axe = 0;
    } else if (this.prescrireLentille.oielDroit.axe >= 0) {
      this.prescrireLentille.oielDroit.axe += 5;
    }
  }

  decrementODAxe() {
    this.prescrireLentille.oielDroit.axe = parseFloat(this.prescrireLentille.oielDroit.axe);
    if (isNaN(this.prescrireLentille.oielDroit.axe) || this.prescrireLentille.oielDroit.axe === undefined) {
      this.prescrireLentille.oielDroit.axe = 0;
    } else if (this.prescrireLentille.oielDroit.axe <= 1) {
      this.prescrireLentille.oielDroit.axe = 0;
    } else {
      this.prescrireLentille.oielDroit.axe -= 5;
      if (this.prescrireLentille.oielDroit.axe < 0) {
        this.prescrireLentille.oielDroit.axe = 0;
      }
    }
  }
  changeOGAxe(value) {
    this.prescrireLentille.oielGauche.axe = parseFloat(value);
    if (!isNaN(value)) {
      if (this.prescrireLentille.oielGauche.axe < 0) {
        this.prescrireLentille.oielGauche.axe = 0;
      } else {
        this.prescrireLentille.oielGauche.axe
      }
    } else {
      this.prescrireLentille.oielGauche.axe = value;
    }
  }

  incrementOGAxe() {
    this.prescrireLentille.oielGauche.axe = parseFloat(this.prescrireLentille.oielGauche.axe);
    if (isNaN(this.prescrireLentille.oielGauche.axe) || this.prescrireLentille.oielGauche.axe === undefined) {
      this.prescrireLentille.oielGauche.axe = 0;
    } else if (this.prescrireLentille.oielGauche.axe >= 0) {
      this.prescrireLentille.oielGauche.axe += 5;
    }
  }

  decrementOGAxe() {
    this.prescrireLentille.oielGauche.axe = parseFloat(this.prescrireLentille.oielGauche.axe);
    if (isNaN(this.prescrireLentille.oielGauche.axe) || this.prescrireLentille.oielGauche.axe === undefined) {
      this.prescrireLentille.oielGauche.axe = 0;
    } else if (this.prescrireLentille.oielGauche.axe <= 1) {
      this.prescrireLentille.oielGauche.axe = 0;
    } else {
      this.prescrireLentille.oielGauche.axe -= 5;
      if (this.prescrireLentille.oielGauche.axe < 0) {
        this.prescrireLentille.oielGauche.axe = 0;
      }
    }
  }

  //todo----- end of  Axe  
  // todo---- end of Acuite visuel de
  checksigne(event) {
    if (event <= 0) {
      event = 0
      this.certificatMedical.nombre = 0
    }
  }
  reinitialiseCertificatTypes(value) {
    if (this.certificatMedical.types == value) {
      this.certificatMedical.nombre = null
      this.certificatMedical.compterDe = null
      this.certificatMedical.dateCertificat = null
    }
  }
}
