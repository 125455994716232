import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { API_CONFIG } from '../../../../config/API_CONFIG';
import { _getURL } from '../../../../config/API_CONFIG';
@Injectable()
export class EventService {
  private apiUrl = _getURL(API_CONFIG.event)
  constructor(private http: HttpClient) {
  }

  // !old file functions
  getAllEventsLibresByMedecinNew(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + '/medecin/eventslibres/module/recherche/' + id, { headers: headers })
  }

  // get all events libre of medecin BY DATE
  getAllEventsLibresByMedecinByDate(id, date) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + '/medecin/eventslibres/' + id + '/' + date, { headers: headers })
  }
  getAllEventsByMedecinByDateBetweenTwoDates(id, startDate, endDate) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + '/medecin/events/all/' + id + '/' + startDate + '/' + endDate, { headers: headers })
  }
  getSingleEvent(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + '/' + id, { headers: headers })

  }
  // !new file functions
  getAllEvents() {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl, { headers: headers })
  }
  getAllEventsByMedecin(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + '/medecin/' + id, { headers: headers })
  }
  // get all events libre of medecin
  getAllEventsLibresByMedecin(id) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + '/medecin/eventslibres/' + id, { headers: headers })
  }
  deleteEvent(eventID) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.delete(this.apiUrl + '/' + eventID, { headers: headers })
  }
  registerEvent(newEvent) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl, (newEvent), { headers: headers })
  }
  registerEventProposition(rdvObject) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl + '/send/mail', (rdvObject), { headers: headers })
  }
  editEvent(event) {
    const headers = new HttpHeaders({
      'Authorization': JSON.parse(localStorage.getItem('id_token')),
      'Content-Type': 'application/json'
    });
    return this.http.put(this.apiUrl + '/' + event._id, (event), { headers: headers })
  }

}
